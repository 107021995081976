import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
} from "react-router-dom";
import "./personaldetail.css";

function Personaldetails() {
  let history = useHistory();
  let login = localStorage.getItem("Retalierinfo");
  console.log(login);
  console.log(history);
  // setTimeout(() => {
  //   if (login) {
  //     history.push("/profile");
  //   } else {
  //     history.push("/login");
  //   }
  // }, [2000]);

  return (
    <main>
      <div className="personaldetail">
        <div className="container">

          <div className="personalContent">
            <div className="personaldiv">
                <div className="personaldivleft">
                    <img
                    className="logo-loginpage"
                    src="assets/images/Icon feather-user.png"
                    alt=""
                    />
                </div>
                <div className="personaldivright">
                    <h3>Bhaskar jain</h3>
                </div>
            </div>
            <div className="personaldiv">
                <div className="personaldivleft">
                    <img
                    className="logo-loginpage"
                    src="assets/images/eye-lens.png"
                    alt=""
                    />
                </div>
                <div className="personaldivright">
                    <h5>Product Name</h5>
                    <p>Iconnnect®</p>
                </div>
            </div>

            <div className="personaldiv">
                <div className="personaldivleft">
                    <img
                    className="logo-loginpage"
                    src="assets/images/location.png"
                    alt=""
                    />
                </div>
                <div className="personaldivright">
                    <h5>Location</h5>
                    <p>Mumbai</p>
                </div>
            </div>

            <div className="personaldiv">
                <div className="personaldivleft">
                    <img
                    className="logo-loginpage"
                    src="assets/images/Icon feather-shopping-cart.png"
                    alt=""
                    />
                </div>
                <div className="personaldivright">
                    <h5>Store Name</h5>
                    <p>GKB_Optical_Malik_Road</p>
                </div>
            </div>

            <div className="personaldiv">
                <div className="personaldivleft">
                    <img
                    className="logo-loginpage"
                    src="assets/images/calendar.png"
                    alt=""
                    />
                </div>
                <div className="personaldivright">
                    <h5>Free Trail Requested Date</h5>
                    <p>25 May 2021</p>
                </div>
            </div>

            <div className="personaldiv">
                <div className="personaldivleft">
                    <img
                    className="logo-loginpage"
                    src="assets/images/Icon feather-eye.png"
                    alt=""
                    />
                </div>
                <div className="personaldivright">
                    <h5>Eye Test</h5>
                    <p>Yes</p>
                </div>
            </div>

            <div className="callscript">
                <select>
                    <option>When you get the Lead</option>
                    <option>When you get the Lead</option>
                    <option>When you get the Lead</option>
                    <option>When you get the Lead</option>
                    <option>When you get the Lead</option>
                </select>
                <h3>When You Get The Lead</h3>
                <p>Good morning Sir/Ma’am </p>
                <p>I am calling from GKB optical on behalf of Bausch & Lomb.
                This is regarding the Bausch and Lomb contact lens trial appointment that you had requested 
                with us at GKB optical. Wanted to confirm the same.</p>
                <p>If the consumer says Yes – Thankyou. Wishing you a wonderful experience with Bausch & Lomb 
                contact lenses! We will contact you once the trial reaches the store.</p>
                <p>YES - Confirm “trial visit confirmed” and schedule a call back for that time</p>
                <p>NO- Tap “not interested” option in app</p>
            </div>

            <div className="History ">
                <h3>Not Found</h3>
            </div>

          </div>
        </div>
      </div>
        <div className="callnowdiv">
            <p><img src="assets/images/call-icon.png"/> Call Now</p>
        </div>
      <div className="footerBg"></div>
    </main>
  );
}

export default Personaldetails;
