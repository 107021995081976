import React, { useState, useEffect } from "react";
import Login from "./component/login/Login";
import Welcome from "./component/welcome/Welcome";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PendingLeads from "./component/pending-leads/Pending-Leads";
import Leads from "./component/leads/Lead";
import Dashboard from "./component/dashbord/Dashboard";
import Analytic from "./component/analytics/Analytic";
import Notification from "./component/notification/Notification";
import ProductDetails from "./component/productdetails/ProductDetails";
// import ProfileEdit from './component/profile/ProfileEdit';
import UserProfile from "./component/profile/Profile";
import Leadtype from "./component/leads/Lead-Type";
import Pageone from "./component/pageone";
import Personaldetails from "./component/personaldetails";
import Callblow from "./component/callblow";

const App = () => {
  // let history = useHistory();
  const [leadtype, setLeadType] = useState("");
  const [campaign, setCampaign] = useState([]);
  const [tab, setTab] = useState("");
  let retailerinfo = localStorage.getItem("Retalierinfo");
  // useEffect(() => {
  // console.log(window.location);
  //   if (window.location.pathname !== "/login") {
  //     if (retailerinfo) {
  //       // history.push("/profile");
  //       window.location.href = "/profile";

  //       console.log("profile");
  //     } else {
  //       // history.push("/login");
  //       window.location.href = "/login";

  //       console.log("login");
  //     }
  //   }
  // }, []);

  // we can use this for notification
  // https://www.npmjs.com/package/react-notifications

  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Welcome />
        </Route>
        <Route exact path="/pageone">
          <Pageone />
        </Route>
        <Route exact path="/personaldetails">
          <Personaldetails />
        </Route>
        <Route exact path="/callblow">
          <Callblow />
        </Route>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/pendingleads">
          <PendingLeads tab={tab} leadtype={leadtype} />
        </Route>
        <Route path="/leads">
          <Leads />
        </Route>
        <Route path="/profile">
          <Dashboard
            setTab={setTab}
            setCampaign={setCampaign}
            setLeadType={setLeadType}
            campaign={campaign}
          />
        </Route>
        <Route path="/analytic">
          <Analytic />
        </Route>
        <Route path="/notification">
          <Notification />
        </Route>
        <Route path="/productdetails">
          <ProductDetails campaign={campaign} />
        </Route>
        <Route path="/userprofile">
          <UserProfile />
        </Route>
        <Route path="/leads-type">
          <Leadtype />
        </Route>
      </Switch>
    </Router>
  );
};

export default App;
