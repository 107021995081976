import React from "react";
// import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
// import baseurl from "../../app.json";

function Header(props) {
  // console.log(window.location.href.split("/")[3]=="notification"||"userprofile")
  // "/productdetails"
  if (props.history.location.pathname !== "/productdetails") {
    localStorage.removeItem("productinfo");
    // localStorage.removeItem("section-name");
  }
  if (props.history.location.pathname !== "/pendingleads") {
    // localStorage.removeItem("productinfo");
    localStorage.removeItem("section-name");
  }

  return (
    <header className="innerheader">
      <div className="container">
        <div className="row justify-content-md-center">
          <div className="col nopad">
            <div className="leftheader">
              {" "}
              <span
                onClick={() => {
                  localStorage.removeItem("productinfo");
                  localStorage.removeItem("section-name");
                  props.history.goBack();
                }}
                className="backwrap"
              >
                <img src="/assets/images/backarrow.png" alt="" />{" "}
              </span>
            </div>
          </div>
          <div className="col-md-auto">
            {/* {baseurl.product.map((item,index)=>{
              if(props.headerheading == item.product_name){

                return
              }
            })} */}
            
            <h2 className="heading1">{props.headerheading} {props.showqustion?<img src="/assets/images/Icon ionic-ios-information-circle-outline.png" data-toggle="modal" data-target="#exampleModal"/>:""}</h2>
          </div>
          <div className="col nopad">
            {window.location.href.split("/")[3] == "notification" ||
            window.location.href.split("/")[3] == "userprofile" ? (
              ""
            ) : (
              <div className="notificationpanel">
                {/* <Link to="/notification">
                  <img src="/assets/images/notification_icon.png" alt="" />
                </Link> */}
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
