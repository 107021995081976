import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import "./login.css";
import baseurl from "../../app.json";
import axios from "axios";
import { useHistory } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import styled, { css } from "styled-components";

const DarkBackground = styled.div`
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */

  ${(props) =>
    props.disappear &&
    css`
      display: block; /* show */
    `}
`;

function Login() {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [phone, setPhone] = useState("");
  const [show, setShow] = useState(false);
  const [loaded, setLoaded] = useState(true);
  const [timer, setTimer] = useState(0);
  const [numlen, setnumlen] = useState(false);
  let history = useHistory();

  let baseurls = baseurl.baseurl;
  // console.log(baseurl.baseurl)

  const onSubmit = (data) => {
    setShow(true);
    console.log(data)

    if (!data.otp) {
      // setLoaded(!loaded);
      setPhone(data.mobileNumber);
      // console.log(data);
      try {
        axios({
          method: "post",
          url: `${baseurls}/retailer/genrateotp`,
          data: data,
        })
          .then((res) => {
            // console.log(res.data)
            setTimer(60);
          })
          .catch(function (error) {
            if (error.message) {
              alert(error.message);
            }
          });
      } catch (error) {
        alert(error);
      }
    } else {
      setLoaded(!loaded);
      data.mobileNumber = phone;
      // console.log(data);
      try {
        axios({
          method: "post",
          url: `${baseurls}/retailer/validateotp`,
          data: data,
        })
          .then((res) => {
            console.log(res.data)
            if (res.data) {
              axios({
                method: "get",
                url: `https://epex.fa.em4.oraclecloud.com/crmRestApi/resources/11.13.18.05/accounts?q=OrganizationDEO_AccountPhoneNumber_c=${data.mobileNumber}`,
                headers: {
                  Authorization: baseurl.Authorization,
                },
              })
                .then((res) => {
                  console.log(res.data.items)
                  if (res.data.items.length == 0) {
                    setLoaded(true);
                    alert("Retailer doesn't exsist");
                  } else {
                    localStorage.setItem(
                      "Retalierinfo",
                      JSON.stringify(res.data)
                    );
                    setLoaded(true);
                    history.push("/profile");
                  }
                })
                .catch(function (error) {
                  if (error.message) {
                    setLoaded(true);
                    alert(error.message);
                  }
                });
            } else {
              setLoaded(true);
              alert("Invalid otp!");
            }
          })
          .catch(function (error) {
            if (error.message) {
              setLoaded(true);
              alert("Invalid otp!");
            }
          });
      } catch (error) {
        alert(error);
      }
    }
  };

  const resendOTP = () => {
    // setLoaded(true);
    let data = {};
    data.mobileNumber = phone;
    // console.log(data)
    try {
      axios({
        method: "post",
        url: `${baseurls}/retailer/genrateotp`,
        data: data,
      })
        .then((res) => {
          // console.log(res.data)
          // alert(res.data.message);
          setTimer(60);
        })
        .catch(function (error) {
          if (error.message) {
            alert(error.message);
          }
        });
    } catch (error) {
      alert(error);
    }
  };
  // useEffect(() => {
  //   // visible true -> false
  //   if (!loaded) {
  //     //setTimeout(() => setLoaded(true), 250); // 0.25초 뒤에 해제
  //     //debugger;
  //     setTimeout(() => setLoaded(true), 10000); // 10초 뒤에
  //   }

  //   //setLoaded(loaded);
  // }, [loaded]);

  useEffect(() => {
    setTimeout(() => {
      if (timer > 0) {
        setTimer(timer - 1);
      }
    }, 1000);
  }, [timer]);

  return (
    <>
      <main>
        <DarkBackground disappear={!loaded}>
          {/* <Loader
          loaded={false}
          lines={13}
          length={20}
          width={10}
          radius={30}
          corners={1}
          rotate={0}
          direction={1}
          color="#a5d8ff"
          speed={1}
          trail={60}
          shadow={false}
          hwaccel={false}
          className="spinner"
          zIndex={2e9}
          top="50%"
          left="50%"
          scale={1.0}
          loadedClassName="loadedContent"
        />*/}
          <LoadingOverlay
            active={true}
            // spinner={<BounceLoader />}
            spinner={true}
            text="Logging In"
          >
            {/* <p>Some content or children or something.</p> */}
          </LoadingOverlay>
        </DarkBackground>
        <div className="topBg"></div>

        <div className="welcome">
          <div className="container">
            <div className="logo">
              <img
                className="logo-loginpage"
                src="/assets/images/grow-logo-new.png"
                alt=""
              />
            </div>

            <div className="loginContainer">
              <h2>Welcome</h2>
              <p>Enter mobile number to login</p>

              <form className="loginForm" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group">
                  {/* <input type="tel" placeholder="Mobile number" {...register("Mobile number", {required: true, minLength: 6, maxLength: 12})} /> */}
                  <input
                    type="number"
                    name="mobileNumber"
                    disabled={show ? true : false}
                    {...register("mobileNumber", {
                      required: true,
                      minLength: 10,
                      maxLength: 10,
                    })}
                    className="form-control arrowremove"
                    placeholder="Enter here"
                  />
                  {console.log(errors)}
                  {errors.mobileNumber && (
                    <>
                      {errors.mobileNumber.type === "required" && (
                        <p className="error">
                          &nbsp;Mobile Number Is Required&nbsp;
                        </p>
                      )}
                      {errors.mobileNumber.type == "maxLength" && (
                        <p className="error">
                          &nbsp;Mobile Number Is Invalid&nbsp;
                        </p>
                      )}
                      {errors.mobileNumber.type == "minLength" && (
                        <p className="error">
                          &nbsp;Mobile Number Is Invalid&nbsp;
                        </p>
                      )}
                    </>

                  )}
                </div>

                {show ? (
                  <>
                    <div className="form-group">
                      <input
                        type="text"
                        name="otp"
                        className="form-control"
                        {...register("otp", { required: true })}
                        placeholder="Enter OTP"
                      />
                      {errors.otp && (
                        <p className="error">&nbsp;OTP is Required&nbsp;</p>
                      )}
                    </div>
                    <label className="tc_ft">
                      <p className="tc_text">
                        I agree with{" "}
                        <a
                          href="http://www.bausch.in/en-in/reference/privacy-policy/"
                          target="_blank"
                        >
                          {" "}
                          Terms & Conditions{" "}
                        </a>{" "}
                        {/* | View{" "}
                      <a
                        href="http://www.bausch.in/en-in/reference/privacy-policy/"
                        target="_blank"
                      >
                        {" "}
                        Terms & Conditions{" "}
                      </a> */}
                      </p>
                      {show ? (
                        <input
                          name="check"
                          type="checkbox"
                          {...register("check", { required: true })}
                        />
                      ) : (
                        <input name="check" type="checkbox" />
                      )}
                      {errors.check && (
                        <p className="error">
                          &nbsp;Please Accept Terms & Conditions&nbsp;
                        </p>
                      )}
                      <span className="checkmark"></span>
                    </label>
                  </>
                ) : (
                  ""
                )}


                <div className="sentbutton">
                  {show ? (
                    <button
                      onClick={timer == 0 ? resendOTP : ""}
                      className="btn_2"
                      type="button"
                    >
                      {timer == 0
                        ? "RESEND OTP"
                        : `00:${timer < 10 ? "0" + timer : timer}`}
                    </button>
                  ) : (
                    ""
                  )}
                  <button
                    type="submit"
                    // onClick={() => {
                    //   if (show) {
                    //     setLoaded(!loaded);
                    //   } else {
                    //   }
                    // }}
                    className="btn_1"
                  >
                    {show ? `Login` : `Send OTP`}
                  </button>
                </div>
              </form>
              <div className="space90"></div>
            </div>
          </div>
        </div>

        <div className="footerBg"></div>

        {/* second component */}
      </main>
    </>
  );
}

export default Login;
