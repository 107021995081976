import React from "react";

function TabsMenu(props) {
  console.log(props);
  return (
    <>
      {props.firstmenu == "Free Trials" ? (
        // <h1>Free Trials</h1>
        ""
      ) : (
        <ul className="nav nav-tabs nav-justified" role="tablist">
          <div className="slider"></div>

          <li key="tmdunique1" className={`nav-item `}>
            <a
              className={`nav-link  ${props.tab == 0 ? "active" : ""}`}
              id="home-tab"
              data-toggle="tab"
              onClick={() => {
                props.setTab(0);
                // props.setSearch({ search: "", tab: "freetrail" });
              }}
              role="tab"
              aria-controls="home"
            >
              {" "}
              {props.firstmenu}{" "}
              {props.newleads?props.tab == 0?
          <span className="followupnumber1">{props.newleads?props.newleads.length:""}</span>
              :"":""}
            </a>
          </li>

          <li key="tmdunique2" className={`nav-item `}>
            <a
              className={`nav-link  ${props.tab == 1 ? "active" : ""}`}
              id="profile-tab"
              data-toggle="tab"
              onClick={() => {
                props.setTab(1);
                // props.setSearch({ search: "", tab: "sales" });
              }}
              role="tab"
              aria-controls="profile"
            >
              {" "}
              {props.secondmenu}{" "}
              {props.followup?props.tab == 1 ?
              <span className="followupnumber1">{props.followup?props.followup.length:""}</span>
              :"":""}
            </a>
          </li>
          {props.thirdmenu ? (
            <li key="tmdunique3" className={`nav-item `}>
              <a
                className={`nav-link  ${props.tab == 2 ? "active" : ""}`}
                id="storevisit-tab"
                data-toggle="tab"
                onClick={() => {
                  props.setTab(2);
                  // props.setSearch({ search: "", tab: "sales" });
                }}
                role="tab"
                aria-controls="profile"
              >
                {" "}
                {props.thirdmenu}{" "}
                {props.completed?props.tab == 2?
                <span className="followupnumber1">{props.completed?props.completed.length:""}</span>
              :"":""}
                </a>
            </li>
          ) : (
            ""
          )}
        </ul>
      )}
    </>
  );
}

export default TabsMenu;
