import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import baseurl from "../../app.json";
// import DatePicker from "react-datepicker";
import { DateRangePicker } from "react-date-range";
// import "react-datepicker/dist/react-datepicker.css";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import "./filter.css";
import { addDays, subDays } from "date-fns";
import moment from "moment";

function Filter(props) {
  let history = useHistory();
  const [modal, setModal] = useState(false);
  const [secondmodal, setSecondModal] = useState(false);
  // const [startDate, setStartDate] = useState(new Date());
  const [state, setState] = useState([
    {
      startDate: subDays(new Date(), 0),
      endDate: addDays(new Date(), 0),
      key: "selection",
    },
  ]);

  const handleClose = () => {
    setModal(false);
  };

  const handleOnChange = (ranges) => {
    const { selection } = ranges;
    // onChange(selection);
    setState([selection]);
    // console.log(ranges.selection.startDate !== ranges.selection.endDate);
    // console.log(ranges.selection.startDate !== ranges.selection.endDate);
    if (ranges.selection.startDate === ranges.selection.endDate) {
      handleClose();
    } else {
      // if (props.product == undefined) {
      let data = [];
      let newdata = [];
      let storevisit = [];
      var startDate = moment(ranges.selection.startDate).format("DD,MM,YYYY");
      console.log(
        props.product !== undefined
          ? props.product
          : props.saleslead.page == "sales"
          ? props.saleslead.followup
          : props.traillead.followup
      );
      console.log("filter");
      let endDate = moment(ranges.selection.endDate).format("DD,MM,YYYY");
      // page
      (props.product !== undefined
        ? props.product
        : props.saleslead.page == "sales"
        ? props.saleslead.followup
        : props.traillead.followup
      ).forEach((item, index) => {
        let date = moment(item.CreationDate).format("DD,MM,YYYY");

        var d1 = startDate.split(",");
        var d2 = endDate.split(",");
        var c = date.split(",");

        console.log({d1})
  console.log({d2})
  console.log({c })
        if (c >= d1 && c <= d2) {
          data.push(item);
        }
      });

      (props.product !== undefined
        ? props.product
        : props.saleslead.page == "sales"
        ? props.saleslead.storevisit
        : props.traillead.storevisit
      ).forEach((item, index) => {
        let date = moment(item.CreationDate).format("DD,MM,YYYY");

        var d1 = startDate.split(",");
        var d2 = endDate.split(",");
        var c = date.split(",");

        console.log({d1})
  console.log({d2})
  console.log({c })
        if (c >= d1 && c <= d2) {
          storevisit.push(item);
        }
      });

      (props.product !== undefined
        ? props.product
        : props.saleslead.page == "sales"
        ? props.saleslead.newleads
        : props.traillead.newleads
      ).forEach((item, index) => {
        let date = moment(item.CreationDate).format("DD,MM,YYYY");

        var d1 = startDate.split(",");
        var d2 = endDate.split(",");
        var c = date.split(",");

        var from = new Date(d1[2], parseInt(d1[1]) - 1, d1[0]); // -1 because months are from 0 to 11
        var to = new Date(d2[2], parseInt(d2[1]) - 1, d2[0]);
        var check = new Date(c[2], parseInt(c[1]) - 1, c[0]);

        if (check >= from && check <= to) {
          newdata.push(item);
        }
      });
      // props.traillead.followup
      // props.traillead.newleads
      if (props.product !== undefined) {
        props.setSearchLead({
          data: newdata,
          page: "search",
        });
        handleCloseSecond();
      } else {
        props.setSearchLead({
          followup: data,
          newleads: newdata,
          storevisit: storevisit,
          page: "search",
        });
        handleCloseSecond();
      }
    }
    // }
  };

  // function handleSelect(date) {
  //   console.log(date);
  // }
  const handleShow = (e) => {
    if (e.target.value === "Product") {
      // setModal(false);
      setModal(true);
    } else if (e.target.value === "Date") {
      // setSecondModal(false);
      setSecondModal(true);
    } else {
      if (props.tab == 0) {
        props.setRender(false);
        props.setSearchLead({});
      } else {
        props.setSearchLead({});
      }
    }
  };
  const handleCloseSecond = () => {
    setSecondModal(false);
  };
  const productFilter = (data) => {
    localStorage.setItem("searchproduct", JSON.stringify(data));
    props.setFilterResult(data);
    setSecondModal(false);
    setModal(false);
    // history.push("/leads");
  };
  // const onDateChange = (date) => {
  //   const formattedDate = moment(date).format("DD-MMM-YYYY");
  //   console.log(formattedDate);
  //   setStartDate(date);
  //   props.setFilterResult(formattedDate);
  //   setSecondModal(false);
  //   setModal(false);
  // };

  return (
    <div className="filteroption">
      {/* <a className="viewall" onClick={() => {}}>
        View All{" "}
      </a> */}
      <select
        className="form-control"
        value=""
        onChange={handleShow}
        id="filterbox"
      >
        <option>Filter By</option>
        {props.product ? "" : <option>Product</option>}
        <option>Date</option>
        <option>View All</option>
      </select>
      <Modal
        show={modal}
        onHide={() => {
          handleClose();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Select Product</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-body">
            <div className="SelectProduct_container">
              <ul className="list_product1">
                {baseurl.product.map((item, index) => {
                  return (
                    <li key={"filter" + { index }}>
                      <p> {item.product_label} </p>
                      <div className="form__group">
                        <div className="form__radio-group">
                          <input
                            type="radio"
                            id={"sc_now" + { index }}
                            name={"products" + { index }}
                            value="Not Reachable"
                            className="form__radio-input"
                          />
                          <label
                            className="form__label-radio"
                            for={"sc_now" + { index }}
                          >
                            <span
                              className="form__radio-button"
                              onClick={() => {
                                productFilter(item);
                              }}
                            ></span>
                          </label>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </Modal.Body>
        {/* <Modal.Footer></Modal.Footer> */}
      </Modal>
      <Modal
        dialogClassName="my-modal"
        show={secondmodal}
        onHide={() => {
          handleCloseSecond();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Select Date</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="second-modal-body">
            {/* <DatePicker
              selected={startDate}
              onChange={(date) => {
                onDateChange(date);
              }}
            />
            <Calendar
              date={new Date()}
              onChange={() => {
                handleSelect();
              }}
            /> */}
            <DateRangePicker
              onChange={handleOnChange}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={1}
              ranges={state}
              direction="horizontal"
            />
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </div>
  );
}

export default Filter;
