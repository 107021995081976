import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import baseurl from "../../app.json";
import axios from "axios";
import Header from "../header/Header";
// import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import ProductLeads from "../all-leads/ProductLeads";

function Leads() {
  // const [productname, setProductName] = useState("");
  // const [customer, setCustomer] = useState("");
  // const [Tab, setTab] = useState([]);
  // const [leads, setLeads] = useState([]);
  // const [search, setSearch] = useState([]);
  const [product, setProduct] = useState("");

  const [searchleads, setSearchLead] = useState([]);
  const [campaigndetails, setCampaignDetails] = useState([]);
  // const [month, setMonth] = useState([
  //   { id: "01", month: "Jan" },
  //   { id: "02", month: "Feb" },
  //   { id: "03", month: "Mar" },
  //   { id: "04", month: "Apr" },
  //   { id: "05", month: "May" },
  //   { id: "06", month: "Jun" },
  //   { id: "07", month: "Jul" },
  //   { id: "08", month: "Aug" },
  //   { id: "09", month: "Sep" },
  //   { id: "10", month: "Oct" },
  //   { id: "11", month: "Nov" },
  //   { id: "12", month: "Dec" },
  // ]);
  let history = useHistory();
  useEffect(() => {
    let retailer = JSON.parse(localStorage.getItem("Retalierinfo"));
    let productinfo = JSON.parse(localStorage.getItem("product"));
    setCampaignDetails(productinfo);
    // console.log(props);

    try {
      axios({
        method: "get",
        url: `https://epex.fa.em4.oraclecloud.com/crmRestApi/resources/latest/Order_c/?q=AccountID_c=${retailer.items[0].PartyNumber};ProductNames_c=${productinfo.product_name};`,
        headers: {
          Authorization: baseurl.Authorization,
        },
      })
        .then((res) => {
          let followup = [];
          res.data.items.forEach((item, index) => {
            if (item.RetailerLeadStatus_c.toLowerCase() === "in progress") {
              if (item.Disposition1_c !== "Store Visit Confirmed") {
                followup.push(item);
              }
            }
          });
          setProduct({ items: followup });
          // setSearchLead({ items: followup });
        })
        .catch(function (error) {
          if (error.message) {
            alert(error.message);
          }
        });
    } catch (error) {
      alert(error);
    }
  }, []);

  return (
    <>
      <main>
        <Header
          headerheading={campaigndetails.product_label}
          history={history}
        />
        {product.items === undefined ? (
          ""
        ) : (
          <div className="container">
            <div className="row">
              <div key="uniquekeyoflead" className="m-10 leadcontainer">
                <ProductLeads
                  // setSearch={setSearch}
                  searchleads={searchleads}
                  setSearchLead={setSearchLead}
                  product={product}
                />
              </div>
            </div>
          </div>
        )}
      </main>
    </>
  );
}

export default Leads;
