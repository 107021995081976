import React from "react";
import baseurl from "../../app.json";
import { useHistory } from "react-router-dom";
import Filter from "./Filter";
import moment from "moment";
import axios from "axios";
import "./filter.css";

function LeadsNew(props) {
  let section = localStorage.getItem("section-name");
  let datedifference = (lastdate) => {
    var now = moment(today).utc().format("YYYY/MM/DD HH:mm:ss");
    var then = moment(lastdate).utc().format("YYYY/MM/DD HH:mm:ss");

    console.log(
      moment
        .utc(
          moment(now, "DD/MM/YYYY HH:mm:ss").diff(
            moment(then, "DD/MM/YYYY HH:mm:ss")
          )
        )
        .format("HH:mm:ss")
    );

    var today = new Date();
    const lastactionDate = moment(lastdate).utc().format("YYYY/MM/DD");
    const todayDate = moment(today).utc().format("YYYY/MM/DD");

    var a = moment(todayDate.split("/"));
    var b = moment(lastactionDate.split("/"));
    console.log(moment.duration(a.diff(b)).asHours());
    if (a.diff(b, "days") == 0) {
      var startTime = moment(
        moment(lastdate).format("HH:mm:ss a"),
        "HH:mm:ss a"
      );
      var endTime = moment(moment(today).format("HH:mm:ss a"), "HH:mm:ss a");
      var duration = moment.duration(endTime.diff(startTime));
      var hours = moment
        .utc(
          moment(now, "DD/MM/YYYY HH:mm:ss").diff(
            moment(then, "DD/MM/YYYY HH:mm:ss")
          )
        )
        .format("HH:mm:ss");
      console.log(hours.split(":")[0]);
      return [0, hours.split(":")[0]];
      return [0, hours];
    } else {
      return [a.diff(b, "days"), 0];
    }
  };
  console.log(props);
  let history = useHistory();
  return (
    <div
      className="tab-pane fade show active"
      id="newlead"
      role="tabpanel"
      aria-labelledby="newlead-tab"
    >
      <div className="followuplist m-10">
        <div className="filternewmaindiv">
          <h2 className="heading2">
            {props.page
              ? props.page == "freetrail"
                ? "Free Trial"
                : "Sales"
              : section == "freetrail"
                ? "Free Trial"
                : section == "sales"
                  ? "Sales"
                  : "Free Trial"}{" "}
            New Leads{" "}
            <span className="followupnumber">
              {props.searchleads.page === "search"
                ? props.searchleads.newleads.length
                : props.traillead.page == "freetrail"
                  ? props.traillead.newleads.length
                  : props.traillead.newleads.length}
            </span>
          </h2>

          <Filter
            tab={props.tab}
            traillead={props.traillead}
            saleslead={props.saleslead}
            setSearchLead={props.setSearchLead}
            setFilterResult={props.setFilterResult}
          />

          {props.searchleads.page === "search"
            ? props.searchleads.newleads.map((item, index) => {
              let a = datedifference(item.LastUpdateDate);
              return (
                <div className="followDetailBox">
                  <a
                    onClick={() => {
                      localStorage.setItem("productinfo", JSON.stringify(item));
                      let product = baseurl.product.filter((items, index) => {
                        if (items.product_name == item.ProductNames_c) {
                          return true;
                        }
                      });
                      console.log({ product })
                      console.log({ product })
                      console.log({ products: item.ProductNames_c })
                      localStorage.setItem(
                        "product",
                        JSON.stringify(product[0])
                      );
                      // localStorage.setItem(
                      //   "product", 
                      //   JSON.stringify({
                      //     product_name: item.ProductNames_c,
                      //     RecordNumber: item.RecordNumber,
                      //     lead_type: item.LeadType_c,
                      //     // product_details: "",
                      //     // date: item.LastUpdateDate
                      //   })
                      // );
                      history.push(`/productdetails`);
                    }}
                    className="item"
                  >
                    <div className="detail">
                      <h2>{item.ContactName_c}</h2>
                      <p>
                        {baseurl.product.map((items, index) => {
                          if (items.product_name == item.ProductNames_c) {
                            return items.product_label;
                          }
                        })}{" "}
                        |{" "}
                        {item.LeadType_c.toLowerCase() == "trial"
                          ? "Free Trial Lead"
                          : "Sales Lead"}
                      </p>
                      <p className="newleaddatetime">
                        {" "}
                        {moment(item.LastUpdateDate).format(
                          "DD MMM YYYY | h:mm a"
                        )}
                        {/* | {item.LastUpdateDate} */}
                      </p>
                    </div>

                    <div className="right">
                      <div
                        className={`dayspending ${0 <= a[0] && a[0] < 1 ? "greendark" : ""
                          }${1 <= a[0] && a[0] < 3 ? "orange-color" : ""}${3 <= a[0] ? "red" : ""
                          }`}
                      >
                        {" "}
                        {a[0] !== 0 && a[0] !== 1 ? (
                          <>
                            {isNaN(a[0]) ? '-' : a[0]} <span>Days Pending</span>
                          </>
                        ) : (
                          <>
                            {Math.abs(a[1])} <span>Hours Pending</span>
                          </>
                        )}
                      </div>
                    </div>
                  </a>
                </div>
              );
            })
            : props.traillead.page == "freetrail"
              ? props.traillead.newleads.map((item, index) => {
                let a = datedifference(item.LastUpdateDate);
                return (
                  <>
                    {/* {getNumber(item)} */}
                    <div key={index} className="followDetailBox">
                      <span
                        onClick={() => {
                          localStorage.setItem(
                            "productinfo",
                            JSON.stringify(item)
                          );
                          let product = baseurl.product.filter((items, index) => {
                            if (items.product_name == item.ProductNames_c) {
                              return true;
                            }
                          });
                          console.log({ product })
                          console.log({ product })
                          console.log({ products: item.ProductNames_c })
                          localStorage.setItem(
                            "product",
                            JSON.stringify(product[0])
                          );
                          // localStorage.setItem(
                          //   "product",
                          //   JSON.stringify({
                          //     product_name: item.ProductNames_c,
                          //     RecordNumber: item.RecordNumber,
                          //     lead_type: item.LeadType_c,
                          //     // product_details: "",
                          //     // date: item.LastUpdateDate
                          //   })
                          // );
                          // history.push(`/productdetails`);
                        }}
                        className="item"
                      >
                        <div className="detail">
                          <h2>{item.ContactName_c}</h2>
                          <p>
                            {baseurl.product.map((items, index) => {
                              if (items.product_name == item.ProductNames_c) {
                                return items.product_label;
                              }
                            })}{" "}
                            |{" "}
                            {item.LeadType_c.toLowerCase() == "trial"
                              ? "Free Trial Lead"
                              : "Sales Lead"}
                          </p>
                          <p className="newleaddatetime">
                            {" "}
                            {moment(item.LastUpdateDate).format(
                              "DD MMM YYYY | h:mm a"
                            )}
                            {/* | {item.AppointmentTime_c} */}
                          </p>
                        </div>

                        <div className="right">
                          <div
                            className={`dayspending ${0 <= a[0] && a[0] < 1 ? "greendark" : ""
                              }${1 <= a[0] && a[0] < 3 ? "orange-color" : ""}${3 <= a[0] ? "red" : ""
                              }`}
                          >
                            {" "}
                            {a[0] !== 0 && a[0] !== 1 ? (
                              <>
                                {isNaN(a[0]) ? '-' : a[0]} <span>Days Pending</span>
                              </>
                            ) : (
                              <>
                                {Math.abs(a[1])} <span>Hours Pending</span>
                              </>
                            )}
                          </div>
                        </div>
                      </span>
                    </div>
                  </>
                );
              })
              : props.traillead.newleads.map((item, index) => {
                let a = datedifference(item.LastUpdateDate);
                return (
                  <>
                    {/* {console.log(getNumber(item))} */}
                    <div key={`divtrail${index}`} className="followDetailBox">
                      <a
                        onClick={() => {
                          localStorage.setItem(
                            "productinfo",
                            JSON.stringify(item)
                          );
                          let product = baseurl.product.filter((items, index) => {
                            if (items.product_name == item.ProductNames_c) {
                              return true;
                            }
                          });
                          console.log({ product })
                          console.log({ products: item.ProductNames_c })
                          localStorage.setItem(
                            "product",
                            JSON.stringify(product[0])
                          );
                          // localStorage.setItem(
                          //   "product",
                          //   JSON.stringify({
                          //     product_name: item.ProductNames_c,
                          //     RecordNumber: item.RecordNumber,
                          //     lead_type: item.LeadType_c,
                          //     // product_details: "",
                          //     // date: item.LastUpdateDate
                          //   })
                          // );
                          history.push(`/productdetails`);
                        }}
                        className="item"
                      >
                        <div className="detail">
                          <h2>{item.ContactName_c}</h2>
                          <p>
                            {baseurl.product.map((items, index) => {
                              if (items.product_name == item.ProductNames_c) {
                                return items.product_label;
                              }
                            })}{" "}
                            |{" "}
                            {item.LeadType_c.toLowerCase() == "trial"
                              ? "Free Trial Lead"
                              : "Sales Lead"}
                          </p>
                          <p className="newleaddatetime">
                            {" "}
                            {moment(item.LastUpdateDate).format(
                              "DD MMM YYYY | h:mm a"
                            )}
                            {/* | {item.AppointmentTime_c} */}
                          </p>
                        </div>

                        <div className="right">
                          <div
                            className={`dayspending ${0 <= a[0] && a[0] < 1 ? "greendark" : ""
                              }${1 <= a[0] && a[0] < 3 ? "orange-color" : ""}${3 <= a[0] ? "red" : ""
                              }`}
                          >
                            {" "}
                            {a[0] !== 0 && a[0] !== 1 ? (
                              <>
                                {isNaN(a[0]) ? '-' : a[0]} <span>Days Pending</span>
                              </>
                            ) : (
                              <>
                                {Math.abs(a[1])} <span>Hours Pending</span>
                              </>
                            )}
                          </div>
                        </div>
                      </a>
                    </div>
                  </>
                );
              })}
        </div>
      </div>
    </div>
  );
}

export default LeadsNew;
