import React, { useState, useEffect } from "react";
import Header from "../header/Header";
import { useHistory } from "react-router-dom";
import axios from "axios";
import baseurl from "../../app.json";
import { Modal, Button } from "react-bootstrap";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import FollowUps from "./Followups";
import { useForm } from "react-hook-form";
import { DatePicker, RangeDatePicker } from "@y0c/react-datepicker";
import "@y0c/react-datepicker/assets/styles/calendar.scss";
import "dayjs/locale/ko";
import "dayjs/locale/en";
import moment from "moment";
import TimePicker from 'react-time-picker';
import TabsMenu from "../tabs-menu/TabsMenu";
// import Loader from "react-loader";

import LoadingOverlay from "react-loading-overlay";
//import BounceLoader from "react-spinners/BounceLoader";

import "../login/login.css";

import styled, { css } from "styled-components";

const DarkBackground = styled.div`
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */

  ${(props) =>
    props.disappear &&
    css`
      display: block; /* show */
    `}
`;

function ProductDetails(props) {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [product, setProduct] = useState({});
  const [tab, setTab] = useState(0);
  const [campaign, setCampaign] = useState({ items: [] });
  const [dishistory, setDisHistory] = useState([]);
  const [showcall, setShowCall] = useState(true);
  const [campaigndetails, setCampaignDetails] = useState([]);
  const [show, setShow] = useState("Store Visit Confirmed");
  const [buttondisable, setbuttondisable] = useState(false);
  const [schedule, setSchedule] = useState(moment(new Date).format("DD MMM YYYY"));
  const [scheduleres, setScheduleRes] = useState("");
  const [time, setTime] = useState("");
  const [zone, setZone] = useState("");
  const [agent, setAgent] = useState("");
  const [value, onChange] = useState('10:00');
  const [clientdetails, setClientDetails] = useState("");
  const [message, setMessage] = useState(false);

  const [message2, setMessage2] = useState(false);

  const [loaded, setLoaded] = useState(true);

  const [client, setClient] = useState("");
  const [storevisithr, setstorevisithr] = useState("01");
  const [storevisitmin, setstorevisitmin] = useState("00");
  const [storevisitzone, setstorevisitzone] = useState("AM");
  const [remarks, setRemarks] = useState("");
  const [errordisposition, setErrorDisposition] = useState("");
  const [errordetaildispo, setErrorDetailDispo] = useState("");
  const [errorremarks, setErrorRemarks] = useState("");
  const [modal, setModal] = useState(false);
  const [month, setMonth] = useState([
    { id: "01", month: "Jan" },
    { id: "02", month: "Feb" },
    { id: "03", month: "Mar" },
    { id: "04", month: "Apr" },
    { id: "05", month: "May" },
    { id: "06", month: "Jun" },
    { id: "07", month: "Jul" },
    { id: "08", month: "Aug" },
    { id: "09", month: "Sep" },
    { id: "10", month: "Oct" },
    { id: "11", month: "Nov" },
    { id: "12", month: "Dec" },
  ]);

  let retailerinformation = JSON.parse(localStorage.getItem("Retalierinfo"));
  let history = useHistory();

  const handleClose = () => {
    setModal(false);
  };
  // const yesterday = moment().subtract(1, "day");
  // const disablePastDt = (current) => {
  //   return current.isAfter(yesterday);
  // };

  // let productname=window.location.pathname.split("/")[2].split("%20").join(" ")
  let retailer = {};
  let productinfo = JSON.parse(localStorage.getItem("productinfo"));
  let caminfo = JSON.parse(localStorage.getItem("product"));
  retailer.Website_campaign_id_c = props.campaign.RecordNumber
    ? props.campaign.RecordNumber
    : caminfo.RecordNumber;

  retailer.PartyNumber = JSON.parse(
    localStorage.getItem("Retalierinfo")
  ).items[0].PartyNumber;
  // console.log(productname.split("-")[1])

  let retailerrss = JSON.parse(localStorage.getItem("Retalierinfo")).items[0]
    .OrganizationDEO_AccountPhoneNumber_c;

  const onChanges =
    (title) =>
      (...args) => {
        setScheduleRes("blank");
        setSchedule(args[1]);
      };
  const onVisitDateChange =
    (title) =>
      (...args) => {
        setScheduleRes("blank");
        setSchedule(args[1]);
      };
  useEffect(() => {
    retailerrss = JSON.parse(localStorage.getItem("Retalierinfo")).items[0];
    // let retailer = JSON.parse(localStorage.getItem("Retalierinfo")).items[0]
    setCampaign(JSON.parse(localStorage.getItem("campaign")));
    if (props.campaign === []) {
      setCampaignDetails(props.campaign);
    } else {
      setCampaignDetails(caminfo);
    }

    if (productinfo) {
      console.log(productinfo)
      setTab(1);
      // setProduct(productinfo);
    }

    setProduct(productinfo);


    if (product.Disposition1_c == "" || product.Disposition1_c == null) {
      setMessage(true);
      setMessage2(false);
      console.log("show callscript");
    } else if (product.Disposition1_c == "Store Visit Confirmed") {
      setMessage2(true);
      setMessage(false);
      console.log("show callscript");
    } else {
      setMessage(false);
      setMessage2(false);
    }


    try {
      axios({
        method: "get",
        url: `https://epex.fa.em4.oraclecloud.com/crmRestApi/resources/latest/Order_c/?q=AccountID_c=${retailer.PartyNumber
          };ProductNames_c=${props.campaign.product_name || caminfo.product_name
          };`,
        headers: {
          Authorization: baseurl.Authorization,
        },
      })
        .then((res) => {
          let followup = [];
          res.data.items.forEach((item, index) => {
            if (item.Disposition1_c !== null) {
              if (
                item.Disposition1_c.toLowerCase() !== "store visit confirmed"
              ) {
                if (item.RetailerLeadStatus_c.toLowerCase() === "in progress") {
                  followup.push(item);
                }
              }
            }
          });
          setCampaign({ items: followup });
          localStorage.setItem("campaign", JSON.stringify({ items: followup }));
        })
        .catch(function (error) {
          if (error.message) {
            alert(error.message);
          }
        });

      if (productinfo) {
        console.log(
          `https://epex.fa.em4.oraclecloud.com/crmRestApi/resources/11.13.18.05/contacts/${productinfo.ContactParty_c}?onlyData=true&fields=PartyId,PartyNumber,CreationDate,LastUpdateDate,PersonDEO_VisionCorrectionType_c,PersonDEO_MobileNumber_c`
        );
        axios({
          method: "get",
          url: `https://epex.fa.em4.oraclecloud.com/crmRestApi/resources/11.13.18.05/contacts/${productinfo.ContactParty_c}?onlyData=true&fields=PartyId,PartyNumber,CreationDate,LastUpdateDate,PersonDEO_VisionCorrectionType_c,PersonDEO_MobileNumber_c`,
          headers: {
            Authorization: baseurl.Authorization,
          },
        })
          .then((res) => {
            console.log(res.data);
            setClientDetails(res.data);
          })
          .catch(function (error) {
            if (error.message) {
              alert(error.message);
            }
          });

        // console.log(
        //   `https://epex.fa.em4.oraclecloud.com:443/crmRestApi/resources/11.13.18.05/Order_c/${productinfo.Id}/child/OrderLinesCollection_c`
        // );

        axios({
          method: "get",
          url: `https://epex.fa.em4.oraclecloud.com:443/crmRestApi/resources/latest/Order_c/${productinfo.Id}/child/OrderLineItemCollection_c`,
          headers: {
            Authorization: baseurl.Authorization,
          },
        })
          .then((res) => {
            setDisHistory(res.data.items.reverse());
            if (res.data.items[0]) {
              if (
                res.data.items[0].Disposition_c.toLowerCase() === "completed"
              ) {
                setShowCall(false);
              } else {
                setShowCall(true);
              }
            }
            // setShowCall()
            // let followup = [];
            // res.data.items.forEach((item, index) => {
            //   if (item.RetailerLeadStatus_c === "in-process") {
            //     followup.push(item);
            //   }
            // });
            // setCampaign({ items: followup });
            // localStorage.setItem("campaign", JSON.stringify({ items: followup }));
            // console.log({ items: followup });
          })
          .catch(function (error) {
            if (error.message) {
              alert(error, error.message);
            }
          });
      }
    } catch (error) {
      alert(error, error.message);
    }
  }, []);

  // useEffect(() => {
  //   // visible true -> false
  //   if (!loaded) {
  //     //setTimeout(() => setLoaded(true), 250); // 0.25초 뒤에 해제
  //     //debugger;
  //     // setTimeout(() => setLoaded(true), 10000); // 10초 뒤에
  //   }

  //   //setLoaded(loaded);
  // }, [loaded]);


  let datedifference = (lastdate) => {
    var today = new Date();
    const lastactionDate = moment(lastdate).format("YYYY/MM/DD");
    const todayDate = moment(today).format("YYYY/MM/DD");

    var a = moment(todayDate.split("/"));
    var b = moment(lastactionDate.split("/"));
    return a.diff(b, "days");
  };

  const callNow = async () => {
    // setModal(true);

    setLoaded(!loaded);
    let calldata = {
      destination_number: clientdetails.PersonDEO_MobileNumber_c,
      agent_number: retailerrss,
    };
    console.log(calldata);
    try {
      axios({
        method: "post",
        url: `https://bnl.weaddo.live/api/call-now`,
        data: calldata,
      })
        .then((res) => {
          console.log({ res })
          setLoaded(true);
          setModal(true);
          // if (res.data.success) {
          //   setLoaded(true);
          //   setModal(true);
          // } else {
          //   alert("Call couldn't connected. kindly try again.");
          //   setLoaded(true);
          // }
        })
        .catch(function (error) {
          if (error.message) {
            alert(error.message);
          }
        });
    } catch (error) {
      alert(error);
    }
  };


  const sendSms = () => {
    let notifyDetails = {
      destination_number: clientdetails.PersonDEO_MobileNumber_c,
      name: productinfo.ContactName_c,
      consumer_response: show
    }
    console.log(notifyDetails, "NotifyDetails")
    axios({
      method: "post",
      url: 'https://bnl.weaddo.live/api/send-sms',
      // url: 'https://bnl.weaddo.live/api/send-sms',
      data: notifyDetails,
    }).then((res) => console.log(res, "aagyaData")).catch((err) => console.log(err))
  }

  function saveData() {
    var now = new Date();

    console.log({ schedule: moment(schedule).format() })
    console.log({ now: moment(now).format() })
    console.log(moment(schedule).format("DD MMM YYYY") <= moment(now).format("DD MMM YYYY"))
    if (moment(schedule).format("DD MMM YYYY") < moment(now).format("DD MMM YYYY")) {
      alert("Past Date Cannot Be Entered")
    } else {

      if (!show) {
        setErrorDisposition("Please select disposition!");
      } else {
        setErrorDisposition("");
      }

      let times = show == "Call Back" ? (time ? time : 10) : "";
      let zones = show == "Call Back" ? (zone ? zone : "AM") : "";
      let data
      if (show == "Store Visit Confirmed") {
        data = {
          RecordName: productinfo.RecordName,
          Disposition_c: show,
          Status_c: "In-Progress",
          Remarks_c: remarks,
          Response_c:
            schedule === "blank" ? "" : schedule + ":" + storevisithr + ":" + storevisitmin + ":" + storevisitzone,
        };
        console.log({ schedule })
      } else {
        data = {
          RecordName: productinfo.RecordName,
          Disposition_c: show,
          Status_c: "In-Progress",
          Remarks_c: remarks,
          Response_c:
            schedule === "blank" ? "" : schedule + "-" + times + "-" + zones,
        };
      }


      console.log({ data })
      console.log({ show })
      console.log({ schedule })
      if (show) {
        console.log(
          "data........,,,,,,,,,,,,..................<<<<<<<<<<<<>>>>>>>>>>>>>>>>>>"
        );

        try {
          axios({
            method: "post",
            url: `https://epex.fa.em4.oraclecloud.com:443/crmRestApi/resources/latest/Order_c/${productinfo.Id}/child/OrderLineItemCollection_c`,
            data: data,
            headers: {
              Authorization: baseurl.Authorization,
            },
          })
            .then((res) => {

              let dispodata = {
                Disposition2_c: moment()._d,
                Disposition1_c: show,
                RetailerLeadStatus_c: "In Progress",
              };
              axios({
                method: "patch",
                url: `https://epex.fa.em4.oraclecloud.com/crmRestApi/resources/latest/Order_c/${productinfo.Id}`,
                data: dispodata,
                headers: {
                  Authorization: baseurl.Authorization,
                },
              })
                .then((res) => {

                  console.log(res.data);
                  localStorage.setItem("productinfo", JSON.stringify(res.data));
                  setbuttondisable(true);
                  window.location.reload();
                })
                .catch(function (error) {
                  if (error.message) {
                    alert(error.message);
                  }
                });
            })
            .catch(function (error) {
              if (error.message) {
                alert(error, error.message);
              }
            });
        } catch (error) {
          alert(error, error.message);
        }
      }
    }
  }

  // const onSubmit = (data) => {
  //   setShow(data.size);

  //   function taskDate(date) {

  // }
  // function formatDate(date) {
  //   var d = new Date(date),
  //     month = "" + (d.getMonth() + 1),
  //     day = "" + d.getDate(),
  //     year = d.getFullYear();

  //   if (month.length < 2) month = "0" + month;
  //   if (day.length < 2) day = "0" + day;

  //   return [year, month, day].join("-");
  // }

  // var datemilli = Date.parse(new Date());
  // console.log(formatDate(new Date()));
  // console.log(data.size);
  // console.log(productinfo.Id);

  // let dispositiondata = {
  //   OrderStatusUpdatedDate_c: formatDate(new Date()),
  //   OrderStatus_c: data.size,
  //   Disposition1_c: formatDate(new Date()),
  // };
  // console.log(dispositiondata);
  // try {
  //   axios({
  //     method: "patch",
  //     url: `https://epex.fa.em4.oraclecloud.com/crmRestApi/resources/latest/Order_c/${productinfo.Id}`,
  //     data: dispositiondata,
  //     headers: {
  //       Authorization: baseurl.Authorization,
  //     },
  //   })
  //     .then((res) => {
  //       console.log(res.data);
  //       alert("disposition updated");
  //     })
  //     .catch(function (error) {
  //       if (error.message) {
  //         alert(error.message);
  //       }
  //     });
  // } catch (error) {
  //   alert(error);
  // }
  // };
  // console.log(tab)
  return (
    <>
      <main>
        <Header
          headerheading={campaigndetails.product_label}
          history={history}
        />

        <div className="maincontainer">
          <DarkBackground disappear={!loaded}>
            {/* // <Loader
              // loaded={false}
              // lines={13}
              // length={20}
              // width={10}
              // radius={30}
              // corners={1}
              // rotate={0}
              // direction={1}
              // color="#a5d8ff"
              // speed={1}
              // trail={60}
              // shadow={false}
              // hwaccel={false}
              // className="spinner"
              // zIndex={9000}
              // top="50%"
              // left="50%"
              // scale={1.0}
              // loadedClassName="loadedContent"
            // />  */}
            <LoadingOverlay
              active={true}
              // spinner={<BounceLoader />}
              spinner={true}
              text="Initiating Call..."
            >
              {/* <p>Some content or children or something.</p> */}
            </LoadingOverlay>
          </DarkBackground>
          <div className="maincontainer">
            <div className="container">
              <div className="row">
                <div className="tabContainer" id="tile-1">
                  {/* <TabsMenu
                  tab={tab}
                  setTab={setTab}
                  firstmenu={"New Leads"}
                  secondmenu={"Follow Ups"}
                  thirdmenu={"Completed Leads"}
                /> */}
                  <div className="container">
                    <div className="row">
                      <div className="productdetail" id="tile-2">
                        {/* <!-- Nav tabs --> */}
                        <ul className="nav nav-tabs nav-justified" role="tablist">
                          <div
                            className={
                              productinfo
                                ? tab === 0
                                  ? `slider1`
                                  : `slider1`
                                : `slider1`
                            }
                          ></div>
                          <li className="nav-item" key="pdunique12">
                            <a
                              className={`nav-link ${productinfo ? "active" : ""}`}
                              id="home-tab"
                              data-toggle="tab"
                              onClick={() => {
                                setTab(1);
                              }}
                              role="tab"
                              aria-controls="pd"
                              aria-selected="true"
                            >
                              {" "}
                              Personal Details
                            </a>
                          </li>
                          {/* <Link to={`/leads`}> */}
                          <li key="pdunique3" className="nav-item">
                            <a
                              className={`nav-link ${!productinfo ? "active" : ""}`}
                              id="fu-tab"
                              data-toggle="tab"
                              onClick={() => {
                                setTab(0);
                                // history.push("/leads");
                              }}
                              role="tab"
                              aria-controls="fu"
                              aria-selected="false"
                            >
                              {" "}
                              Call Script{" "}
                              {/* <span className="followupnumber">
                        {campaign
                          ? campaign.items.length
                            ? campaign.items.length
                            : 0
                          : 0}
                      </span> */}
                            </a>
                          </li>
                          {/* </Link> */}
                          <li key="pdunique4" className="nav-item">
                            <a
                              className="nav-link"
                              id="alllead-tab"
                              data-toggle="tab"
                              onClick={() => {
                                // history.push("/leads-type");
                                setTab(2);
                              }}
                              role="tab"
                              aria-controls="alllead"
                              aria-selected="false"
                            >
                              {" "}
                              History
                            </a>
                          </li>
                        </ul>

                        {/* <!-- Tab panes --> */}
                        <div className="tab-content">
                          {tab === 0 ? (
                            <>
                              <div className="container_detail personalContent">
                                {/* <div className="callscriptfirstdiv">

        <button
          className="callscript_lead dropdown-toggle"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          type="button"
          onClick={() => {
            setMessage(false);
            setMessage2(false);
          }}
        >
          {message
            ? "When You Get The Lead"
            : message2
            ? "When The Trial Reaches The Store"
            : "Call Script"}
        </button>
        </div> */}
                                {/* <div className="dropdown-menu " aria-labelledby="dropdownMenuButton">
          <a
            className="dropdown-item"
            onClick={() => {
              if (message) {
                setMessage(false);
              } else {
                setMessage(true);
                setMessage2(false);
              }
            }}
          >
            When You Get The Lead
          </a>
          <a
            style={{ borderBottom: "0px solid #cdcdcd" }}
            className="dropdown-item"
            onClick={() => {
              if (message2) {
                setMessage2(false);
              } else {
                setMessage(false);
                setMessage2(true);
              }
            }}
          >
            When The Trial Reaches The Store
          </a>
        </div> */}
                                <select onChange={
                                  (e) => {
                                    console.log(e.target.value)
                                    if (e.target.value == "When The Trial Reaches The Store") {
                                      if (message2) {
                                        setMessage2(false);
                                      } else {
                                        setMessage(false);
                                        setMessage2(true);
                                      }
                                    } else if (e.target.value == "When You Get The Lead") {
                                      if (message) {
                                        setMessage(false);
                                      } else {
                                        setMessage(true);
                                        setMessage2(false);
                                      }
                                    } else {

                                      setMessage(false);
                                      setMessage2(false);
                                    }
                                  }
                                } className="form-control">
                                  <option value="Call Script">{message
                                    ? "When You Get The Lead"
                                    : message2
                                      ? "When The Trial Reaches The Store"
                                      : "Call Script"}</option>
                                  {message ? <option value="When The Trial Reaches The Store">When The Trial Reaches The Store</option> : ""}
                                  {message2 ? <option value="When You Get The Lead">When You Get The Lead</option> : ""}
                                </select>
                                <br />

                                {message ? (
                                  <div className="modal-content ">
                                    <div className="modal-header">
                                      <h4 className="m-auto">When You Get The Lead</h4>
                                    </div>

                                    <div className="modal-body">
                                      <h5>Good morning Sir/Ma’am</h5>
                                      <p>
                                        I am calling from{" "}
                                        <b>
                                          {retailerinformation.items[0].OrganizationDEO_StoreName_c}
                                        </b>{" "}
                                        on behalf of Bausch & Lomb. This is regarding the Bausch and
                                        Lomb contact lens trial appointment that you had requested with
                                        us at{" "}
                                        <b>
                                          {retailerinformation.items[0].OrganizationDEO_StoreName_c}
                                        </b>
                                        . Wanted to confirm the same.
                                        <br />
                                        <br />
                                        If the consumer says Yes – Thankyou. Wishing you a wonderful
                                        experience with Bausch & Lomb contact lenses! We will contact
                                        you once the trial reaches the store.
                                        <br />
                                        <br />
                                        If the consumer says No – Sure Ma’am/sir. Can I book an
                                        appointment or slot for you have a free eye test
                                        <br />
                                        <br />
                                        YES - Confirm “trial visit confirmed” and schedule a call back
                                        for that time
                                        <br />
                                        <br />
                                        NO- Tap “not interested” option in app
                                      </p>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                                {message2 ? (
                                  <div className="modal-content mt30 ">
                                    <div className="modal-header">
                                      <h4 className="m-auto">When The Trial Reaches The Store</h4>
                                    </div>

                                    <div className="modal-body">
                                      <p>
                                        Good morning Sir/Ma’am
                                        <br />
                                        <br />I am calling from{" "}
                                        <b>
                                          {retailerinformation.items[0].OrganizationDEO_StoreName_c}
                                        </b>{" "}
                                        on behalf of Bausch & Lomb. This is regarding the Bausch and
                                        Lomb contact lens trial appointment that you had requested a
                                        with us at{" "}
                                        <b>
                                          {retailerinformation.items[0].OrganizationDEO_StoreName_c}
                                        </b>{" "}
                                        . We have received your lenses, can you confirm what time would
                                        you like to visit our store.
                                        <br />
                                        <br />
                                        If the consumer says Yes – Thankyou. Wishing you a wonderful
                                        experience with Bausch & Lomb contact lenses! (Tap “store visit
                                        confirmed” in the app)
                                      </p>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                              {/* <div
                      className="tab-pane fade show active"
                      id="pd"
                      role="tabpanel"
                      aria-labelledby="pd-tab"
                    >
                      <div className="productdescription">
                        <h2>{campaigndetails.product_label}</h2>

                        <div className="heading3"> About The Product </div>

                        <p>
                          {campaigndetails.product_details}

                        </p>
                      </div>
                    </div> */}
                            </>
                          ) : (
                            ""
                          )}
                          {console.log({ dishistory: dishistory })}
                          {console.log({ tab: tab })}
                          {tab === 1 ? (
                            <div id="fu" role="tabpanel" aria-labelledby="fu-tab">
                              <FollowUps
                                showcall={showcall}
                                productinfo={productinfo}
                                clientdetails={clientdetails}
                                dishistory={dishistory}
                                show={show}
                                product={product}
                              />
                            </div>
                          ) : (
                            ""
                          )}

                          {tab == 2 ?

                            dishistory.length > 0 ? (
                              <div className="actionstatus_detail ">
                                <h2>
                                  Last Action Taken Status{" "}
                                  <span className="followupnumber">{dishistory.length}</span>
                                </h2>

                                <div id="wrappercallstatus">
                                  <div className="" id="style-4">
                                    <div className="force-overflow">
                                      {dishistory.map((items, index) => {
                                        console.log({ items });
                                        return (
                                          <div key={`folloupss${index}`} className="LastActionStatus">
                                            <a href="#" className="item">
                                              <div className="detail">
                                                <ul className="listviews flush transparent simple-listview  ">
                                                  <li key="uniqueasd">
                                                    <div className="headingLeft">Date:</div>
                                                    <div className="headingRight">
                                                      {datedifference(items.LastUpdateDate)} Days Ago
                                                      |{" "}
                                                      {moment(items.LastUpdateDate).format(
                                                        "DD MMM YYYY"
                                                      )}
                                                      {/* <sup>®</sup> */}
                                                    </div>
                                                  </li>
                                                  <li key="uniquefgd">
                                                    <div className="headingLeft">Call Status:</div>
                                                    <div className="headingRight">
                                                      {items.Disposition_c}
                                                    </div>
                                                  </li>
                                                  {items.Response_c ? items.Response_c !== "::" ? (
                                                    <li key="uniquefgdsdcdas">
                                                      <div className="headingLeft">Response:</div>
                                                      <div className="headingRight">
                                                        {items.Disposition_c == "Store Visit Confirmed" ?
                                                          <>
                                                            {moment(items.Response_c.split(":")[0]).format(
                                                              "DD MMM YYYY"
                                                            ) + "-" +
                                                              items.Response_c.split(":")[1] + ":" +
                                                              items.Response_c.split(":")[2] + ":" +
                                                              items.Response_c.split(":")[3]}
                                                          </>
                                                          : items.Disposition_c == "Call Back" ?
                                                            <>
                                                              {
                                                                // moment(items.Response_c.split(":")[0]).format(
                                                                //                                         "DD MMM YYYY"
                                                                //                                       )+
                                                                //                                       "-"+
                                                                // console.log({Response_c:items.Response_c.split("-")})
                                                                moment(items.Response_c.split("-")[0]).format(
                                                                  "DD MMM YYYY"
                                                                ) + "-" + items.Response_c.split("-")[1] + ":" + items.Response_c.split("-")[2]
                                                              }
                                                            </>
                                                            : items.Disposition_c == "Request a store Change" ?
                                                              <>
                                                                {items.Response_c.split(":")[0]}
                                                              </>
                                                              :
                                                              <></>
                                                        }
                                                        {/* {items.Response_c.indexOf("/") !== -1 ? (
                                           items.Disposition_c == "Store Visit Confirmed" ?
                                           <>
                                           {console.log({items : items.Response_c})}
                                           {items.Response_c.split(":")[0]+
                                             (items.Disposition_c == "Call Back"
                                               ? " - "
                                               : "") +
                                             items.Response_c.split(":")[1] +
                                             items.Response_c.split(":")[2]}
                                         </>
                                           :
                                    <>
                                    {console.log({moment:items.Response_c})}
                                      {moment(items.Response_c.split(":")[0]).format(
                                        "DD MMM YYYY"
                                      ) +
                                        (items.Disposition_c == "Call Back"
                                          ? " - "
                                          : "") +
                                        items.Response_c.split(":")[4] +
                                        items.Response_c.split(":")[5]}
                                    </>
                                  ) : // (items.Response_c.split(":")[1])
                                  items.Disposition_c ==
                                    "Request a store Change" ? (
                                    items.Response_c.split(":")[0]
                                  ) : (
                                    items.Response_c.split(":")[0] == ""?
                                    items.Response_c.split(":")[1]+items.Response_c.split(":")[2]
                                    :
                                    <>
                                    {console.log({moment:items.Response_c})}
                                    {moment(items.Response_c).format(
                                      "DD MMM YYYY"
                                    )}
                                    </>
                                  )} */}
                                                      </div>
                                                    </li>
                                                  ) :

                                                    <li key="uniquefgdsdcdas">
                                                      {/* <div className="headingLeft">Response:</div>
                                <div className="headingRight">
                                  {items.Response_c && items.Response_c.indexOf("/") == -1 ? (
                                           items.Disposition_c == "Store Visit Confirmed" ?
                                           <>
                                           {console.log({itemsss : items.Response_c})}
                                           {items.Response_c.split(":")[0]+
                                            //  (items.Disposition_c == "Call Back"
                                            //    ? " - "
                                            //    : "") 
                                            //    +
                                             items.Response_c.split(":")[1] +
                                             items.Response_c.split(":")[2]}
                                         </>
                                           :
                                    <>
                                    {console.log({moment:items.Response_c})}
                                      {moment(items.Response_c.split(":")[0]).format(
                                        "DD MMM YYYY"
                                      ) +
                                        (items.Disposition_c == "Call Back"
                                          ? " - "
                                          : "") +
                                        items.Response_c.split(":")[4] +
                                        items.Response_c.split(":")[5]}
                                    </>
                                  ) : // (items.Response_c.split(":")[1])
                                  items.Disposition_c ==
                                    "Request a store Change" ? (
                                    items.Response_c.split(":")[0]
                                  ) : (
                                    items.Response_c.split(":")[0] == ""?
                                    items.Response_c.split(":")[1]+items.Response_c.split(":")[2]
                                    :
                                    <>
                                    {console.log({moment:items.Response_c})}
                                    {moment(items.Response_c).format(
                                      "DD MMM YYYY"
                                    )}
                                    </>
                                  )}
                                </div> */}
                                                    </li>
                                                    : (
                                                      ""
                                                    )}
                                                  {items.Remarks_c ? (
                                                    <li key="uniquefgdsdffc">
                                                      <div className="headingLeft">Remarks:</div>
                                                      <div className="headingRight">
                                                        {items.Remarks_c}
                                                      </div>
                                                    </li>
                                                  ) : (
                                                    ""
                                                  )}
                                                </ul>
                                                {/* <p className="mb-2">
                            {" "}
                            {datedifference(items.LastUpdateDate)} Days Ago | |{" "}
                            {moment(items.LastUpdateDate).format("DD MMM YYYY")}
                          </p>

                          <h6 className="font-weight-bold">
                            {items.Disposition_c}:
                          </h6>
                          <p className="mb-2">
                            {moment(items.Response_c).isValid()
                              ? moment(items.Response_c).format("DD MMM YYYY")
                              : items.Response_c}
                          </p>

                          <h6 className="font-weight-bold">Remarks :</h6>
                          <p className="mb-2">{items.Remarks_c}</p> */}
                                              </div>
                                              <div className="right">
                                                {" "}
                                                <img src="assets/images/call.png" alt="" />{" "}
                                              </div>
                                            </a>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (

                              <div className="History personalContent">
                                <h3>Not Found</h3>
                              </div>
                            )
                            : ""}
                        </div>
                      </div>
                      {/* <div>
                <h5>Client</h5>
                <input
                  name="client"
                  onChange={(e) => {
                    setClient(e.target.value);
                  }}
                />
              </div>
              <br />
              <br />
              <div>
                <h5>Agent</h5>
                <input
                  name="agent"
                  onChange={(e) => {
                    setAgent(e.target.value);
                  }}
                />
              </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      {console.log(productinfo, "jkhuhuwe")}
      {console.log(showcall)}
      {productinfo ? (
        showcall ? (
          <span
            onClick={() => {
              callNow();
            }}
            className="callButton"
            data-toggle="modal"
            data-target="#myModal_dispositon"
          ><i class="fa fa-phone callmainicon" aria-hidden="true"></i>
            Call Now
          </span>
        ) : (
          ""
        )
      ) : (
        <a
          className="callButton"
          onClick={() => {
            setTab(1);
            history.push("/leads");
          }}
        >
          Start Calling
        </a>
      )}


      {/* <!-- The Modal product --> */}
      <Modal
        id="dispositionwrap"
        show={modal}
        onHide={() => {
          handleClose();
        }}
      >
        <Modal.Header>
          <Modal.Title>
            Please Enter Consumer Response
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="selctdiv">
            <select className="form-control" onChange={(e) => {
              setShow(e.target.value);
            }}>
              <option value="Store Visit Confirmed">Store Visit Confirmed</option>
              <option value="Call Back">Call Back</option>
              <option value="Request a store Change">Request a store Changes</option>
              <option value="Not Reachable">Not Reachable</option>
              <option value="Wrong Number">Wrong Number</option>
              <option value="Not Interested">Not interested</option>
            </select>
          </div>
          {/*  <div className="modal " id="">
            <div className="modal-dialog">
              <div className="modal-content"> */}
          {/* <!-- Modal Header --> */}

          {/* <!-- Modal body --> */}
          <div className="callblowContent">

            {show ? (
              <div className="disposition">
                <>
                  <h2>Consumer Response</h2>
                </>
                {/* <!-- Call Now start -->///*/}

                <div className="callNow">
                  <div className="form__group">
                    <div className="form__radio-group">
                      {/* <input
                        type="radio"
                        name="size"
                        id="dispo11"
                        className="form__radio-input"
                        checked={true}
                      /> */}
                      <label
                        className="form__label-radio"
                        htmlFor="dispo11"
                      // className="form__radio-label"
                      >
                        {/* <span className="form__radio-button"></span> */}
                        {" " + show}
                      </label>
                    </div>
                  </div>

                  {show === "Call Back" ? (
                    <div className="callnow_container">
                      <h2>Schedule Callback</h2>
                      <ul className="listcallback ">
                        {/* <li key="unique4">
                            Now
                            <div className="form__group">
                              <div className="form__radio-group">
                                <input
                                  type="radio"
                                  name="size11"
                                  value="Now"
                                  id="sc_noaw1"
                                  onClick={(e) => {
                                    setSchedule(e.target.value);
                                  }}
                                  checked={schedule == "Now" ? true : false}
                                  className="form__radio-input"
                                />
                                <label
                                  className="form__label-radio"
                                  for="sc_noaw1"
                                  // className="form__radio-label"
                                >
                                  <span className="form__radio-button"></span>
                                </label>
                              </div>
                            </div>
                          </li> */}

                        <li key="unique5">
                          After 10 Minutes
                          <div className="form__group">
                            <div className="form__radio-group">
                              <input
                                type="radio"
                                name="size22"
                                value="After 10 Minutes"
                                onClick={(e) => {
                                  setScheduleRes(e.target.value);
                                  let startdate = moment()._d;
                                  setSchedule(
                                    moment(startdate, "DD-MM-YYYY").add(
                                      10,
                                      "minutes"
                                    )._d
                                  );
                                }}
                                checked={
                                  scheduleres == "After 10 Minutes"
                                    ? true
                                    : false
                                }
                                id="sc_mint22"
                                className="form__radio-input"
                              />
                              <label
                                className="form__label-radio"
                                htmlFor="sc_mint22"
                              // className="form__radio-label"
                              >
                                <span className="form__radio-button"></span>
                              </label>
                            </div>
                          </div>
                        </li>
                        <li key="unique6">
                          After 1 hour
                          <div className="form__group">
                            <div className="form__radio-group">
                              <input
                                type="radio"
                                name="size3"
                                value="After 1 hour"
                                onClick={(e) => {
                                  setScheduleRes(e.target.value);
                                  let startdate = moment()._d;
                                  setSchedule(
                                    moment(startdate, "DD-MM-YYYY").add(
                                      1,
                                      "hours"
                                    )._d
                                  );
                                }}
                                checked={
                                  scheduleres == "After 1 hour" ? true : false
                                }
                                id="sc_hour3"
                                className="form__radio-input"
                              />
                              <label
                                className="form__label-radio"
                                htmlFor="sc_hour3"
                              // className="form__radio-label"
                              >
                                <span className="form__radio-button"></span>
                              </label>
                            </div>
                          </div>
                        </li>
                        <li key="unique7">
                          Tommorrow
                          <div className="form__group">
                            <div className="form__radio-group">
                              <input
                                type="radio"
                                name="size4"
                                value="Tommorrow"
                                onClick={(e) => {
                                  setScheduleRes(e.target.value);
                                  let startdate = moment()._d;
                                  setSchedule(
                                    moment(startdate, "DD-MM-YYYY").add(
                                      1,
                                      "days"
                                    )._d
                                  );
                                }}
                                checked={
                                  scheduleres == "Tommorrow" ? true : false
                                }
                                id="sc_tommorrow4"
                                className="form__radio-input"
                              />
                              <label
                                className="form__label-radio"
                                htmlFor="sc_tommorrow4"
                              // className="form__radio-label"
                              >
                                <span className="form__radio-button"></span>
                              </label>
                            </div>
                          </div>
                        </li>
                        <li key="unique8">
                          Custom
                          <div className="form__group">
                            <div className="form__radio-group calsv">
                              {/* <DatePicker
                                onChange={onChanges("DatePicker")}
                              /> */}
                            </div>

                            <select
                              class="form-select callbacktime"
                              onChange={(e) => {
                                setTime(e.target.value);
                              }}
                              aria-label="Default select example"
                            >
                              {/* <option selected></option> */}
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                              <option value="7">7</option>
                              <option value="8">8</option>
                              <option value="9">9</option>
                              <option selected value="10">
                                10
                              </option>
                              <option value="11">11</option>
                              <option value="12">12</option>
                            </select>
                            <select
                              class="form-select callbacktime"
                              onChange={(e) => {
                                setZone(e.target.value);
                              }}
                              aria-label="Default select examples"
                            >
                              {/* <option selected>AM</option> */}
                              <option selected value="AM">
                                AM
                              </option>
                              <option value="PM">PM</option>
                            </select>
                          </div>
                        </li>
                      </ul>
                    </div>
                  ) : show === "Request a store Change" ? (
                    <div className="callnow_container">
                      <h2>Store Change</h2>
                      <div className="pincodewrap">
                        <div className="form-group">
                          <p>
                            Pincode<sup className="red">*</sup>
                          </p>
                          <input
                            type="text"
                            name="pincode"
                            className="form-control"
                            onChange={(e) => {
                              setSchedule(e.target.value);
                            }}
                            placeholder=""
                          />
                        </div>
                      </div>
                    </div>
                  ) : show === "Not Interested" ? (
                    ""
                  ) : show === "Store Visit Confirmed" ? (

                    // <div className="callnow_container">
                    //   <h2>Visit Date</h2>
                    //   <div className="pincodewrap">
                    //     <DatePicker
                    //       // showToday
                    //       onChange={onVisitDateChange("DatePicker")}
                    //     />
                    //   </div>
                    // </div>
                    <div>
                      <p>Visit Date & Time</p>

                      <DatePicker
                        dateFormat="DD MMM YYYY"
                        placeholder={schedule}
                        onChange={onVisitDateChange("DatePicker")}
                      />
                      {console.log("Present")}
                      {/* <TimePicker onChange={onChange} value={value} /> */}

                      <select class="form-select callbacktime timedivnew" onChange={(e) => { setstorevisithr(e.target.value) }} aria-label="Default select example">
                        <option value="01">1</option>
                        <option value="02">2</option>
                        <option value="03">3</option>
                        <option value="04">4</option>
                        <option value="05">5</option>
                        <option value="06">6</option>
                        <option value="07">7</option>
                        <option value="08">8</option>
                        <option value="09">9</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                      </select>
                      <select class="form-select callbacktime timedivnew" onChange={(e) => { setstorevisitmin(e.target.value) }} aria-label="Default select example">
                        <option value="00">00</option>
                        <option value="15">15</option>
                        <option value="30">30</option>
                        <option value="45">45</option>
                      </select>
                      <select class="form-select callbacktime timedivnew" onChange={(e) => { setstorevisitzone(e.target.value) }} aria-label="Default select examples">
                        <option value="AM">AM</option>
                        <option value="PM">PM</option>
                      </select>
                      {/* <input className="form-control" placeholder="Enter Time"/> */}
                      <div className="error">{errordetaildispo}</div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div>
                    <div className="remark_container">
                      <p>Remark</p>
                      <textarea
                        placeholder="Type Here"
                        onChange={(e) => {
                          setRemarks(e.target.value);
                        }}
                        className="form-control form-control-lg mb-3"
                        rows="3"
                      // placeholder="Call when trial reaches store"
                      ></textarea>
                    </div>
                    <button
                      className={buttondisable ? `btn6` : `btn5`}
                      onClick={() => {
                        saveData();
                        sendSms();
                      }}
                      type="submit"
                      disabled={buttondisable ? true : false}
                    >
                      Save
                    </button>
                    {/* <p>remark</p>
            <textarea className="form-control" placeholder="Type Here"/> */}
                  </div>
                  {/* <div className="attachments_container">
                      <h2>Attachments</h2>
                      <div className="file btn4">
                        Add Attachment
                        <input type="file" name="file" />{" "}
                      </div>
                      <div className="clearfix"></div>
                    </div> */}


                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          {/* </div>
            </div>
          </div> */}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ProductDetails;
