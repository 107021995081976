import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import baseurl from "../../app.json";
import axios from "axios";
import Header from "../header/Header";
import TabsMenu from "../tabs-menu/TabsMenu";
import LeadsFollowUps from "../all-leads/LeadsFollowUps";
import LeadsNew from "../all-leads/LeadsNew";
import StoreVisit from "../all-leads/StoreVisit";
// import Filter from ".././all-leads/Filter";

function PendingLeads(props) {
  let history = useHistory();
  let tabname = localStorage.getItem("section-name");
  // const [retailerinfo, setRetailerInfo] = useState(
  //   localStorage.getItem("Retalierinfo")
  // );
  const [filterresult, setFilterResult] = useState([]);
  // const[product,setProduct]=useState([])
  const [traillead, setTrailLead] = useState({
    followup: [],
    newleads: [],
    storevisit: [],
    page: "",
  });
  const [saleslead, setSalesleads] = useState({
    followup: [],
    newleads: [],
    storevisit: [],
    page: "",
  });
  const [searchleads, setSearchLead] = useState({
    followup: [],
    newleads: [],
    storevisit: [],
    page: "",
  });
  const [Tab, setTab] = useState(0);
  const [month, setMonth] = useState([
    { id: "01", month: "Jan" },
    { id: "02", month: "Feb" },
    { id: "03", month: "Mar" },
    { id: "04", month: "Apr" },
    { id: "05", month: "May" },
    { id: "06", month: "Jun" },
    { id: "07", month: "Jul" },
    { id: "08", month: "Aug" },
    { id: "09", month: "Sep" },
    { id: "10", month: "Oct" },
    { id: "11", month: "Nov" },
    { id: "12", month: "Dec" },
  ]);
  let page;
  useEffect(() => {
    let retailer = JSON.parse(localStorage.getItem("Retalierinfo"));
    page = props.leadtype;
    // setRetailerInfo(retailer);
    if (props.tab == "trial") {
      setTab(0);
    } else {
      setTab(props.tab);
    }
    // setFilterResult([]);
    console.log(props);
    try {
      axios({
        method: "get",
        url: ` https://epex.fa.em4.oraclecloud.com/crmRestApi/resources/latest/Order_c/?q=AccountID_c=${retailer.items[0].PartyNumber};LeadType_c=sales;&limit=100000`,
        headers: {
          Authorization: baseurl.Authorization,
        },
      })
        .then((res) => {
          let follow = [];
          let lead = [];

          let sortedlist = res.data.items.sort(function(a,b){
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return new Date(b.CreationDate) - new Date(a.CreationDate);
          }); 

          sortedlist.map((item, index) => {
            if (
              item.RetailerLeadStatus_c.toLowerCase() === null ||
              item.RetailerLeadStatus_c.toLowerCase() === "new"
            ) {
              lead.push(item);
            } else if (
              item.RetailerLeadStatus_c.toLowerCase() === "completed" ||
              item.RetailerLeadStatus_c.toLowerCase() === "canceled"
            ) {
            } else {
              follow.push(item);
            }
          });
          //  console.log(res.data)
          setSalesleads({ followup: follow, newleads: lead, page: page });
        })
        .catch(function (error) {
          if (error.message) {
            alert(error.message);
          }
        });
    } catch (error) {
      alert(error);
    }
    try {
      axios({
        method: "get",
        url: ` https://epex.fa.em4.oraclecloud.com/crmRestApi/resources/latest/Order_c/?q=AccountID_c=${retailer.items[0].PartyNumber};LeadType_c=trial;&limit=100000`,
        headers: {
          Authorization: baseurl.Authorization,
        },
      })
        .then(async (res) => {
          //  console.log(res.data)
          let follow = [];
          let lead = [];
          let storevisit = [];

          let sortedarry = res.data.items.sort(function(a,b){
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return new Date(b.CreationDate) - new Date(a.CreationDate);
          }); 

          for (var indx in sortedarry) {
            var item = sortedarry[indx];
            // await axios({
            //   method: "get",
            //   url: `https://epex.fa.em4.oraclecloud.com/crmRestApi/resources/11.13.18.05/contacts/${item.ContactParty_c}?onlyData=true&fields=PartyId,PartyNumber,CreationDate,LastUpdateDate,PersonDEO_VisionCorrectionType_c,PersonDEO_MobileNumber_c`,
            //   headers: {
            //     Authorization: baseurl.Authorization,
            //   },
            // })
            //   .then((res) => {
            //     console.log(res.data.PersonDEO_MobileNumber_c);
            //     if (res.data.PersonDEO_MobileNumber_c) {
            if (
              item.RetailerLeadStatus_c.toLowerCase() === null ||
              item.RetailerLeadStatus_c.toLowerCase() === "new"
            ) {
              lead.push(item);
            } else if (
              item.RetailerLeadStatus_c.toLowerCase() === "completed" ||
              item.RetailerLeadStatus_c.toLowerCase() === "canceled" ||
              item.Disposition1_c.toLowerCase() == "store visit confirmed"
            ) {
            } else {
              follow.push(item);
            }

            if (!item.Disposition1_c == false) {
              console.log(item.Disposition1_c);
              if (
                item.Disposition1_c.toLowerCase() == "store visit confirmed"
              ) {
                console.log(item.Disposition1_c);
                storevisit.push(item);
              }
            }
            // }
            // setClientDetails(res.data);
            // })
            // .catch(function (error) {
            //   if (error.message) {
            //     alert(error.message);
            //   }
            // });
          }
          // console.log(res.data)
          setTrailLead({
            followup: follow,
            newleads: lead,
            storevisit: storevisit,
            page: page,
          });
        })
        .catch(function (error) {
          if (error.message) {
            alert(error.message);
          }
        });
    } catch (error) {
      alert(error);
    }
    if (filterresult.length === undefined) {
      if (typeof filterresult === "string") {
        // console.log(typeof props.filterresult);
      } else if (typeof filterresult === "object") {
        console.log(
          `https://epex.fa.em4.oraclecloud.com/crmRestApi/resources/latest/Order_c/?q=AccountID_c=${retailer.items[0].PartyNumber};ProductNames_c=${filterresult.product_name};LeadType_c=${props.tab}`
        );
        try {
          axios({
            method: "get",
            url: `https://epex.fa.em4.oraclecloud.com/crmRestApi/resources/latest/Order_c/?q=AccountID_c=${retailer.items[0].PartyNumber};ProductNames_c=${filterresult.product_name};LeadType_c=trial`,
            headers: {
              Authorization: baseurl.Authorization,
            },
          })
            .then((res) => {
              let follow = [];
              let lead = [];
              let storevisit = [];
              res.data.items.map((item, index) => {
                if (
                  item.RetailerLeadStatus_c.toLowerCase() === null ||
                  item.RetailerLeadStatus_c.toLowerCase() === "new"
                ) {
                  lead.push(item);
                } else if (
                  item.RetailerLeadStatus_c.toLowerCase() === "completed" ||
                  item.RetailerLeadStatus_c.toLowerCase() === "canceled" ||
                  item.Disposition1_c.toLowerCase() == "store visit confirmed"
                ) {
                } else {
                  follow.push(item);
                }

                if (!item.Disposition1_c == false) {
                  console.log(item.Disposition1_c);
                  if (
                    item.Disposition1_c.toLowerCase() == "store visit confirmed"
                  ) {
                    console.log(item.Disposition1_c);
                    storevisit.push(item);
                  }
                }
              });
              console.log(res.data);
              console.log({
                followup: follow,
                newleads: lead,
                storevisit: storevisit,
                page: "search",
              });
              setSearchLead({
                followup: follow,
                newleads: lead,
                storevisit: storevisit,
                page: "search",
              });
            })
            .catch(function (error) {
              if (error.message) {
                alert(error.message);
              }
            });
        } catch (error) {
          alert(error);
        }
      }
    }
  }, [filterresult]);

  return (
    <>
      <main>
        <Header headerheading={"Total Pending Actions"} history={history} />

        <div className="maincontainer">
          <div className="container">
            <div className="row">
              <div className="tabContainer" id="tile-1">
                {/* <!-- Nav tabs --> */}
                <TabsMenu
                  tab={Tab}
                  setTab={setTab}
                  firstmenu={"Follow Ups"}
                  secondmenu={"New Leads"}
                  thirdmenu={"Store Visits"}
                />

                {/* <!-- Tab panes --> */}
                <div className="tab-content">
                  {/* <Filter /> */}
                  {Tab == 0 ? (
                    <LeadsFollowUps
                      tab={Tab}
                      page={props.leadtype == "" ? tabname : props.leadtype}
                      setSearchLead={setSearchLead}
                      searchleads={searchleads}
                      filterresult={filterresult}
                      setFilterResult={setFilterResult}
                      traillead={traillead}
                      saleslead={saleslead}
                    />
                  ) : (
                    ""
                  )}

                  {Tab == 1 ? (
                    <LeadsNew
                      tab={Tab}
                      page={props.leadtype == "" ? tabname : props.leadtype}
                      setSearchLead={setSearchLead}
                      searchleads={searchleads}
                      filterresult={filterresult}
                      setFilterResult={setFilterResult}
                      traillead={traillead}
                      saleslead={saleslead}
                    />
                  ) : (
                    ""
                  )}
                  {Tab == 2 ? (
                    <StoreVisit
                      tab={Tab}
                      page={props.leadtype == "" ? tabname : props.leadtype}
                      setSearchLead={setSearchLead}
                      searchleads={searchleads}
                      filterresult={filterresult}
                      setFilterResult={setFilterResult}
                      traillead={traillead}
                      saleslead={saleslead}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
export default PendingLeads;
