import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
} from "react-router-dom";
import "./callblow.css";

function Callblow() {
  let history = useHistory();
  let login = localStorage.getItem("Retalierinfo");
  console.log(login);
  console.log(history);
  // setTimeout(() => {
  //   if (login) {
  //     history.push("/profile");
  //   } else {
  //     history.push("/login");
  //   }
  // }, [2000]);

  return (
    <main>
      <div className="callblowdiv">
        <div className="container">
            <div className="callblowone">
            <h3>Please Enter Disposition Of Call Below</h3>
            <select className="form-control">
                <option>Store Visit confirmed</option>
                <option>Store Visit confirmed</option>
                <option>Store Visit confirmed</option>
                <option>Store Visit confirmed</option>
                <option>Store Visit confirmed</option>
            </select>
            </div>
          <div className="callblowContent">
            <h4>Disposition</h4>
            <h5>Store Visit Confirmed</h5>
            <p>Visit Date & Time</p>
            <input className="form-control" placeholder="Enter Date"/>
            <input className="form-control" placeholder="Enter Time"/>

            <p>Visit Date & Time</p>
            <textarea className="form-control" placeholder="Type Here"/>
            <a href="#">Save & Next Call</a>
          </div>
        </div>
      </div>
        <div className="callnowdiv">
            <p><img src="assets/images/call-icon.png"/> Call Now</p>
        </div>
      <div className="footerBg"></div>
    </main>
  );
}

export default Callblow;
