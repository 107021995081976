import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import baseurl from "../../app.json";
import axios from "axios";
import Header from "../header/Header";
import { Modal, Button } from "react-bootstrap";
import TabsMenu from "../tabs-menu/TabsMenu";
import { Calendar, DateRangePicker } from "react-date-range";
// import "react-datepicker/dist/react-datepicker.css";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import "../all-leads/filter.css";
import { addDays, subDays } from "date-fns";
import moment from "moment";

function Leadtype() {
  const [newleads, setNewLeads] = useState([]);
  const [completed, setCompleted] = useState([]);
  const [search, setSearch] = useState({});
  const [tab, setTab] = useState(0);
  let history = useHistory();
  const [campaigndetails, setCampaignDetails] = useState([]);
  const [month, setMonth] = useState([
    { id: "01", month: "Jan" },
    { id: "02", month: "Feb" },
    { id: "03", month: "Mar" },
    { id: "04", month: "Apr" },
    { id: "05", month: "May" },
    { id: "06", month: "Jun" },
    { id: "07", month: "Jul" },
    { id: "08", month: "Aug" },
    { id: "09", month: "Sep" },
    { id: "10", month: "Oct" },
    { id: "11", month: "Nov" },
    { id: "12", month: "Dec" },
  ]);
  const [secondmodal, setSecondModal] = useState(false);
  const [state, setState] = useState([
    {
      startDate: subDays(new Date(), 0),
      endDate: addDays(new Date(), 0),
      key: "selection",
    },
  ]);

  let datedifference = (lastdate) => {
    var today = new Date();
    const lastactionDate = moment(lastdate).utc().format("YYYY/MM/DD");
    const todayDate = moment(today).utc().format("YYYY/MM/DD");
    var now = moment(today).utc().format("YYYY/MM/DD HH:mm:ss");
    var then = moment(lastdate).utc().format("YYYY/MM/DD HH:mm:ss");
    var a = moment(todayDate.split("/"));
    var b = moment(lastactionDate.split("/"));
    console.log(a.diff(b, "days"));
    if (a.diff(b, "days") == 0) {
      var startTime = moment(
        moment(lastdate).format("HH:mm:ss a"),
        "HH:mm:ss a"
      );
      var endTime = moment(moment(today).format("HH:mm:ss a"), "HH:mm:ss a");
      console.log(startTime);
      console.log(endTime);
      var duration = moment.duration(endTime.diff(startTime));
      var hours = moment
        .utc(
          moment(now, "DD/MM/YYYY HH:mm:ss").diff(
            moment(then, "DD/MM/YYYY HH:mm:ss")
          )
        )
        .format("HH:mm:ss");
      console.log(hours.split(":")[0]);
      return [0, hours.split(":")[0]];
    } else {
      return [a.diff(b, "days"), 0];
    }
  };

  useEffect(() => {
    let retailer = JSON.parse(localStorage.getItem("Retalierinfo"));
    // let ProductName_Id_c = window.location.pathname.split("/")[3]
    // let productname = window.location.pathname.split("/")[2].split("%").join(" ")
    // setProductName(productname)
    let productinfo = JSON.parse(localStorage.getItem("product"));
    setCampaignDetails(productinfo);

    try {
      axios({
        method: "get",
        url: `https://epex.fa.em4.oraclecloud.com/crmRestApi/resources/latest/Order_c/?q=AccountID_c=${retailer.items[0].PartyNumber};ProductNames_c= ${productinfo.product_name};`,
        headers: {
          Authorization: baseurl.Authorization,
        },
      })
        .then(async (res) => {
          let newleadcount = [];
          let completeleadcount = [];
          for (var indx in res.data.items) {
            var item = res.data.items[indx];
            // await axios({
            //   method: "get",
            //   url: `https://epex.fa.em4.oraclecloud.com/crmRestApi/resources/11.13.18.05/contacts/${item.ContactParty_c}?onlyData=true&fields=PartyId,PartyNumber,CreationDate,LastUpdateDate,PersonDEO_VisionCorrectionType_c,PersonDEO_MobileNumber_c`,
            //   headers: {
            //     Authorization: baseurl.Authorization,
            //   },
            // })
            //   .then((res) => {
            //     console.log(res.data);
            //     console.log(item);
            //     if (res.data.PersonDEO_MobileNumber_c) {
            if (
              item.RetailerLeadStatus_c.toLowerCase() == "new" ||
              item.RetailerLeadStatus_c == null
            ) {
              newleadcount.push(item);
            } else if (item.RetailerLeadStatus_c.toLowerCase() == "completed") {
              // setLeads(leads+1)
              completeleadcount.push(item);
            } else {
            }
            //   }
            // })
            // .catch(function (error) {
            //   if (error.message) {
            //     alert(error.message);
            //   }
            // });
          }
          setNewLeads(newleadcount);
          setCompleted(completeleadcount);
        })
        .catch(function (error) {
          if (error.message) {
            alert(error.message);
          }
        });
    } catch (error) {
      alert(error);
    }
  }, []);

  const handleCloseSecond = () => {
    setSecondModal(false);
  };

  const handleShows = (e) => {
    if (e.target.value == "View All") {
      setSearch({});
    } else {
      setSecondModal(true);
    }
  };

  const handleOnChanges = (ranges) => {
    let data = [];
    let datacompleted = [];
    const { selection } = ranges;
    // onChange(selection);
    setState([selection]);
    if (ranges.selection.startDate === ranges.selection.endDate) {
      // setSecondModal(false);
    } else {
      var startDate = moment(ranges.selection.startDate).format("DD,MM,YYYY");

      let endDate = moment(ranges.selection.endDate).format("DD,MM,YYYY");

      newleads.forEach((item, index) => {
        let date = moment(item.CreationDate).format("DD,MM,YYYY");

        var d1 = startDate.split(",");
        var d2 = endDate.split(",");
        var c = date.split(",");

        if (c >= d1 && c <= d2) {
          data.push(item);
        }
      });
      completed.forEach((item, index) => {
        let date = moment(item.CreationDate).format("DD,MM,YYYY");

        var d1 = startDate.split(",");
        var d2 = endDate.split(",");
        var c = date.split(",");

        if (c >= d1 && c <= d2) {
          datacompleted.push(item);
        }
      });
      setSearch({
        new: data,
        completed: datacompleted,
      });
      handleCloseSecond();
    }
  };

  return (
    <>
      <main>
        <Header
          headerheading={campaigndetails.product_label}
          history={history}
        />

        <div className="maincontainer">
          <div className="container">
            <div className="row">
              <div className="tabContainer" id="tile-1">
                {/* <!-- Nav tabs --> */}
                <TabsMenu
                  setTab={setTab}
                  tab={tab}
                  firstmenu={"New Leads"}
                  secondmenu={"Completed Leads"}
                />

                {/* <!-- Tab panes --> */}
                <div className="tab-content">
                  {tab === 0 ? (
                    <div
                      className="tab-pane fade show active"
                      id="newlead"
                      role="tabpanel"
                      aria-labelledby="newlead-tab"
                    >
                      <div className="followuplist">
                        <h2 className="heading2">
                          New Leads{" "}
                          <span className="followupnumber">
                            {/* {console.log(newleads)} */}
                            {search.new ? search.new.length : newleads.length}
                          </span>
                        </h2>
                        <div className="filteroption">
                          <select
                            className="form-control"
                            onChange={handleShows}
                            id="filterbox"
                          >
                            <option>Filter By</option>
                            {/* <option>Product</option> */}
                            <option>Date</option>
                            <option>View All</option>
                          </select>
                        </div>
                        {(search.new ? search.new : newleads).length <= 0 ? (
                          <p className="blank-error">
                            No leads available in this category.
                          </p>
                        ) : (
                          ""
                        )}
                        {/* <p>
                          {search.new ? search.new.length <= 0 : "No Leads"}
                        </p> */}
                        {(search.new ? search.new : newleads).map(
                          (item, index) => {
                            let a = datedifference(item.CreationDate);
                            let date =
                              item.CreationDate.split("T")[0].split("-");
                            let day = date[2];
                            let months;
                            month.map((ite, ind) => {
                              if (ite.id == date[1]) {
                                months = ite.month;
                              }
                            });
                            let year = date[0];

                            var today = new Date();
                            var dd = String(today.getDate()).padStart(2, "0");

                            return (
                              <div
                                key={`leadtype${index}`}
                                className="followDetailBox"
                              >
                                <a
                                  onClick={() => {
                                    localStorage.setItem(
                                      "productinfo",
                                      JSON.stringify(item)
                                    );
                                    let product = baseurl.product.filter(
                                      (items, index) => {
                                        if (
                                          items.product_name ==
                                          item.ProductNames_c
                                        ) {
                                          return true;
                                        }
                                      }
                                    );
                                    localStorage.setItem(
                                      "product",
                                      JSON.stringify(product[0])
                                    );
                                    history.push(`/productdetails`);
                                  }}
                                  className="item"
                                >
                                  <div className="detail">
                                    <h2>{item.ContactName_c}</h2>
                                    <p>
                                      {baseurl.product.map((items, index) => {
                                        if (
                                          items.product_name ==
                                          item.ProductNames_c
                                        ) {
                                          return items.product_label;
                                        }
                                      })}{" "}
                                      |{" "}
                                      {item.LeadType_c.toLowerCase() == "trial"
                                        ? "Free Trial Lead"
                                        : "Sales Lead"}
                                    </p>
                                    <p className="newleaddatetime">
                                      {" "}
                                      {moment(item.CreationDate).format(
                                        "DD MMM YYYY | h:mm a"
                                      )}
                                      {/* |{" "}
                                    {item.AppointmentTime_c} */}
                                    </p>
                                  </div>

                                  <div className="right">
                                    <div
                                      className={`dayspending ${0 <= a[0] && a[0] < 1 ? "greendark" : ""
                                        }${1 <= a[0] && a[0] < 3
                                          ? "orange-color"
                                          : ""
                                        }${3 <= a[0] ? "red" : ""}`}
                                    >
                                      {" "}
                                      {a[0] !== 0 ? (
                                        <>
                                          {isNaN(a[0]) ? '-' : a[0]} <span>Days Pending</span>
                                        </>
                                      ) : (
                                        <>
                                          {Math.abs(a[1])}{" "}
                                          <span>Hours Pending</span>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </a>
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {tab === 1 ? (
                    <div className="tab-content">
                      <div
                        className="tab-pane fade show active"
                        id="home"
                        role="tabpanel"
                        aria-labelledby="home-tab"
                      >
                        <h2 className="heading2">
                          Completed Leads{" "}
                          <span className="followupnumber">
                            {search.completed
                              ? search.completed.length
                              : completed.length}
                          </span>
                        </h2>
                        <div className="filteroption">
                          <select
                            className="form-control"
                            value=""
                            onChange={handleShows}
                            id="filterbox"
                          >
                            <option>Filter By</option>
                            {/* <option>Product</option> */}
                            <option>Date</option>
                            <option>View All</option>
                          </select>
                        </div>
                        {(search.completed ? search.completed : completed)
                          .length <= 0 ? (
                          <p className="blank-error">
                            No leads available in this category.
                          </p>
                        ) : (
                          ""
                        )}
                        {console.log({ completed: completed })}
                        {(search.completed ? search.completed : completed).map(
                          (item, index) => {
                            let a = datedifference(item.Disposition2_c);
                            let date =
                              item.Disposition2_c.split("T")[0].split("-");
                            let day = date[2];
                            let months;
                            month.map((ite, ind) => {
                              if (ite.id == date[1]) {
                                months = ite.month;
                              }
                            });
                            let year = date[0];

                            var today = new Date();
                            var dd = String(today.getDate()).padStart(2, "0");

                            return (
                              <>
                                <div
                                  key={`leadtype${index}`}
                                  class="followDetailBox"
                                >
                                  <a
                                    onClick={() => {
                                      localStorage.setItem(
                                        "productinfo",
                                        JSON.stringify(item)
                                      );
                                      let product = baseurl.product.filter(
                                        (items, index) => {
                                          if (
                                            items.product_name ==
                                            item.ProductNames_c
                                          ) {
                                            return true;
                                          }
                                        }
                                      );
                                      localStorage.setItem(
                                        "product",
                                        JSON.stringify(product[0])
                                      );
                                      history.push(`/productdetails`);
                                    }}
                                    class="item"
                                  >
                                    <div class="detail">
                                      <h2>Lead_{index + 1}</h2>
                                      <div class="statusDetail">
                                        {" "}
                                        <span class="badge-primary badgestatus"></span>{" "}
                                        Status {item.Disposition1_c}{" "}
                                      </div>
                                      <p>
                                        {" "}
                                        {baseurl.product.map((items, index) => {
                                          if (
                                            items.product_name ==
                                            item.ProductNames_c
                                          ) {
                                            return items.product_label;
                                          }
                                        })}{" "}
                                        |{" "}
                                        {item.LeadType_c.toLowerCase() ==
                                          "trial"
                                          ? "Free Trial Lead"
                                          : "Sales Lead"}
                                      </p>
                                    </div>
                                  </a>
                                </div>
                              </>
                            );
                          }
                        )}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          dialogClassName="my-modal"
          show={secondmodal}
          onHide={() => {
            handleCloseSecond();
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Select Date</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="second-modal-body">
              {/* <DatePicker
              selected={startDate}
              onChange={(date) => {
                onDateChange(date);
              }}
            />
            <Calendar
              date={new Date()}
              onChange={() => {
                handleSelect();
              }}
            /> */}
              <DateRangePicker
                onChange={handleOnChanges}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={1}
                ranges={state}
                direction="horizontal"
              />
            </div>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </main>
      <a
        className="callButton"
        onClick={() => {
          setTab(1);
          history.push("/leads");
        }}
      ><i class="fa fa-phone callmainicon" aria-hidden="true"></i>
        Start Calling
      </a>
    </>
  );
}
export default Leadtype;
