import React, { useEffect, useState } from "react";
import Header from "../header/Header";
import { useHistory } from "react-router-dom";
import axios from "axios";
import baseurl from "../../app.json";

function Analytic() {
  const [allleads, setAllLeads] = useState({});
  const [freetrails, setFreetrail] = useState([]);
  const [sales, setSales] = useState([]);
  // const [completed, setCompleted] = useState([]);
  // const [newleads, setNew] = useState([]);
  let history = useHistory();

  useEffect(() => {
    try {
      let retailer = JSON.parse(localStorage.getItem("Retalierinfo"));

      axios({
        method: "get",
        url: `https://epex.fa.em4.oraclecloud.com/crmRestApi/resources/latest/Order_c/?q=AccountID_c=${retailer.items[0].PartyNumber}`,
        headers: {
          Authorization: baseurl.Authorization,
        },
      })
        .then((res) => {
          console.log(res.data);
          // setAllLeads(res.data)
          let freetrail = [];
          let sale = [];
          let completed = [];
          let unattend = [];

          // setProduct(res.data)
          res.data.items.map((item, index) => {
            if (item.LeadType_c.toLowerCase() == "trial") {
              freetrail.push(item);
            } else if (
              item.LeadType_c.toLowerCase() == "sales" ||
              item.LeadType_c.toLowerCase() == "sales" ||
              item.LeadType_c.toLowerCase() == "sales"
            ) {
              sale.push(item);
            } else {
            }

            if (item.RetailerLeadStatus_c.toLowerCase() == "completed") {
              completed.push(item);
            } else if (item.RetailerLeadStatus_c.toLowerCase() == "new") {
              unattend.push(item);
            } else {
            }
          });
          setAllLeads({
            totalleads: res.data.items,
            completedleads: completed,
            unattendleads: unattend,
          });
          setSales(sale);
          setFreetrail(freetrail);

          axios({
            method: "get",
            url: `https://epex.fa.em4.oraclecloud.com/crmRestApi/resources/latest/Order_c/?q=AccountID_c=${retailer.items[0].PartyNumber};OrderStatus_c=New`,
            headers: {
              Authorization: baseurl.Authorization,
            },
          })
            .then((res) => {
              console.log(res.data.items.length);
              // setNew(res.data);
            })
            .catch(function (error) {
              if (error.message) {
                alert(error.message);
              }
            });
          axios({
            method: "get",
            url: `https://epex.fa.em4.oraclecloud.com/crmRestApi/resources/latest/Order_c/?q=AccountID_c=${retailer.items[0].PartyNumber};OrderStatus_c=Completed`,
            headers: {
              Authorization: baseurl.Authorization,
            },
          })
            .then((res) => {
              console.log(res.data.items.length);
              // setCompleted(res.data);
            })
            .catch(function (error) {
              if (error.message) {
                alert(error.message);
              }
            });
        })
        .catch(function (error) {
          if (error.message) {
            alert(error.message);
          }
        });
    } catch (error) {
      alert(error);
    }
  }, []);

  // console.log(allleads.totalleads!==undefined?allleads.unattendleads.length/allleads.totalleads.length*100:0)
  // console.log(allleads)
  // console.log(allleads.totalleads)
  // console.log(allleads == {}?allleads.unattendleads.length/allleads.totalleads.length*100:0)
  return (
    <main>
      <Header headerheading={"My Analytic"} history={history} />

      <div className="maincontainer">
        <div className="container">
          <div className="row">
            <div className="totalleadassign">
              <h2>Total Leads Assigned</h2>

              <div className="card card-style">
                <div className="RewardLeft red">
                  <h3>{freetrails.length}</h3>
                  <p>Free Trial Leads</p>
                </div>

                <div className="divisonline"></div>

                <div className="RewardLeft green">
                  <h3>{sales.length}</h3>
                  <p>Sale Leads</p>
                </div>
              </div>
            </div>

            <div className="overallPerformance">
              <div className="card card-style">
                <h2>Overall Performance </h2>

                <div className="col-sm-6 pull-left nopad">
                  <div
                    className="progress"
                    data-percentage={
                      allleads.totalleads !== undefined
                        ? (allleads.unattendleads.length /
                            allleads.totalleads.length) *
                          100
                        : 0
                    }
                  >
                    <span className="progress-left">
                      <span className="progress-bar progress-colorred"></span>
                    </span>
                    <span className="progress-right">
                      <span className="progress-bar progress-colorred"></span>
                    </span>
                    <div className="progress-value">
                      <div className="red">
                        {allleads.totalleads !== undefined
                          ? (allleads.unattendleads.length /
                              allleads.totalleads.length) *
                            100
                          : 0}
                        %<br />
                        <span>Unattended</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6 pull-left nopad">
                  <div
                    className="progress"
                    data-percentage={
                      allleads.totalleads !== undefined
                        ? (allleads.completedleads.length /
                            allleads.totalleads.length) *
                          100
                        : 0
                    }
                  >
                    <span className="progress-left">
                      <span className="progress-bar progress-colorgreen"></span>
                    </span>
                    <span className="progress-right">
                      <span className="progress-bar progress-colorgreen"></span>
                    </span>
                    <div className="progress-value">
                      <div className="green">
                        {allleads.totalleads !== undefined
                          ? (allleads.completedleads.length /
                              allleads.totalleads.length) *
                            100
                          : 0}
                        %<br />
                        <span>Completed</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="LeadsCompleted">
              <div className="followDetailBox">
                <a href="#" className="item">
                  <div className="detail">
                    <h2>Leads Completed</h2>
                    <p>lorem ipsum dolor sit amet, consectetur</p>
                  </div>
                  <div className="right">
                    <div className="dayspending greendark">
                      {" "}
                      {allleads.totalleads !== undefined
                        ? allleads.completedleads.length
                        : 0}
                      <br />
                      <span>Leads</span>
                    </div>
                  </div>
                </a>
              </div>

              <div className="followDetailBox">
                <a href="#" className="item">
                  <div className="detail">
                    <h2>Unattended</h2>
                    <p>lorem ipsum dolor sit amet, consectetur</p>
                  </div>
                  <div className="right">
                    <div className="dayspending red">
                      {" "}
                      {allleads.totalleads !== undefined
                        ? allleads.unattendleads.length
                        : 0}
                      <br />
                      <span>Unattended Calls</span>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Analytic;
