import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
} from "react-router-dom";
import "./welcome.css";

function Welcome() {
  let history = useHistory();
  let login = localStorage.getItem("Retalierinfo");
  console.log(login);
  console.log(history);
  setTimeout(() => {
    if (login) {
      history.push("/profile");
    } else {
      history.push("/login");
    }
  }, [2000]);

  return (
    <main>
      <div className="topBg"></div>
      <div className="welcome">
        <div className="container">
          <div className="logo">
            <img
              className="logo-loginpage"
              src="assets/images/grow-logo-new.png"
              alt=""
            />
          </div>
          <div className="welcomeContent">
            <h2>Hi Welcome</h2>
            <p> To Bausch + Lomb Retailer Application </p>
            <img src="assets/images/welcome-img.png"/>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Welcome;
