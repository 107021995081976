import React, { useEffect, useState } from "react";
import Header from "../header/Header";
import { useHistory } from "react-router-dom";
// import { useForm } from "react-hook-form";
import axios from "axios";
import baseurl from "../../app.json";

function UserProfile() {
  let history = useHistory();
  const [errors, setErrors] = useState("");
  const [errorsaddress, setErrorsAddresss] = useState("");
  const [errorspincode, setErrorsPincode] = useState("");
  // const [errors, setErrors] = useState("");
  const [errorsuid, setErrorsuid] = useState("");
  const [errorscbu, setErrorscbu] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [retailerinfo, setRetailerInfo] = useState([]);
  const [storename, setStoreName] = useState("");
  const [storeaddress, setStoreAddress] = useState("");
  const [storeaddress2, setStoreAddress2] = useState("");
  const [storepin, setStorePin] = useState("");
  const [storeuid, setStoreUID] = useState("");
  const [centralbusinessunit, setCentralBusinessUnit] = useState("");
  // const [image, setImage] = useState("Click to Upload");
  // const [imagebuffer, setImageBuffer] = useState("");
  const [datasubmit, setDataSubmit] = useState("");
  const [name, setName] = useState("");

  // const { register, handleSubmit, watch, formState: { errors } } = useForm();

  useEffect(() => {
    let retailerinfornation = JSON.parse(localStorage.getItem("Retalierinfo"))
      .items[0];
    setRetailerInfo(retailerinfornation);
    setName(retailerinfornation.PartyUniqueName[0]);
    console.log(retailerinfornation);
    setStoreName(retailerinfornation.OrganizationDEO_StoreName_c);
    setStoreAddress(retailerinfornation.AddressLine1);
    setStoreAddress2(retailerinfornation.AddressLine2);
    setStorePin(retailerinfornation.PostalCode);
    setStoreUID(retailerinfornation.OrganizationDEO_StoreGroupUID_c);
    setCentralBusinessUnit(retailerinfornation.OrganizationDEO_CBU_c);
  }, []);

  function onSubmit(e) {
    e.preventDefault();
    console.log(storename);
    console.log(storeaddress);
    console.log(storepin);
    // console.log()
    if (storename === "" || storename === null) {
      setErrors("storename");
      console.log("1");
    } else {
      setErrors("");
    }
    if (storeaddress === "" || storeaddress === null) {
      setErrorsAddresss("storeaddress");
      console.log("2");
    } else {
      setErrorsAddresss("");
    }
    if (storepin === "" || storepin === null) {
      setErrorsPincode("storepin");
      console.log("3");
    } else {
      setErrorsPincode("");
    }
    if (storepin && storeaddress && storename) {
      try {
        // let image = {
        //   DatatypeCode: "FILE",
        //   FileName: "Profile.jpeg",
        //   FileContents: imagebuffer,
        // };

        // axios({
        //   method: "post",
        //   url: `https://epex.fa.em4.oraclecloud.com:443/crmRestApi/resources/11.13.17.11/accounts/${retailerinfo.PartyNumber}/child/Attachment`,
        //   data: image,
        //   headers: {
        //     Authorization: baseurl.Authorization,
        //   },
        // })
        //   .then((res) => {
        //     console.log(res.data);
        //     setDataSubmit("Data is successfully updated !");
        //     // alert("image uploaded");
        //   })
        //   .catch(function (error) {
        //     if (error.message) {
        //       alert(error.message);
        //     }
        //   });
        let userdata = {
          OrganizationName: retailerinfo.PartyUniqueName,
          OrganizationDEO_AccountEmailId_c:
            retailerinfo.OrganizationDEO_AccountEmailId_c,
          OrganizationDEO_AccountPhoneNumber_c:
            retailerinfo.OrganizationDEO_AccountPhoneNumber_c,
          OrganizationDEO_BandLUniqueId_c:
            retailerinfo.OrganizationDEO_BandLUniqueId_c,
          OrganizationDEO_GEOMapping_c:
            retailerinfo.OrganizationDEO_GEOMapping_c,
          OrganizationDEO_StoreGroupUID_c: storeuid,
          OrganizationDEO_CBU_c: retailerinfo.OrganizationDEO_CBU_c,
          OrganizationDEO_StoreName_c: storename,
          // PostalCode: storepin,
          PostalCode: storepin,
          AddressLine1: storeaddress,
          AddressLine2: storeaddress2,
          City: "Delhi",
          Country: "IN",
        };
        console.log(userdata);
        axios({
          method: "patch",
          url: `https://epex.fa.em4.oraclecloud.com/crmRestApi/resources/11.13.18.05/accounts/${retailerinfo.PartyNumber}`,
          data: userdata,
          headers: {
            Authorization: baseurl.Authorization,
          },
        })
          .then((res) => {
            let item = { items: [res.data] };
            localStorage.setItem("Retalierinfo", JSON.stringify(item));
            setDataSubmit("");
            window.location.reload();
            // alert("data updated");
          })
          .catch(function (error) {
            if (error.message) {
              alert(error.message);
            }
          });
      } catch (error) {
        alert(error);
      }
    }
  }

  // function getBase64(file) {
  //   return new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(file);
  //     reader.onload = () => resolve(reader.result);
  //     reader.onerror = (error) => reject(error);
  //   });
  // }

  function submitApproval(e) {
    e.preventDefault();

    if (disabled) {
      setDisabled(false);
    } else {
      if (storeuid === "") {
        setErrorsuid("storeuid");
        console.log("4");
      } else {
        setErrorsuid("");
      }

      if (centralbusinessunit === "") {
        setErrorscbu("centralbusinessunit");
        console.log("5");
      } else {
        // alert("time to aprove");
        // setDisabled(true);
        setErrorsuid("");
      }
    }
  }
  console.log(retailerinfo);
  // function file(e) {
  //   console.log(retailerinfo.PartyNumber);
  //   var file = e.target.files[0];
  //   getBase64(file).then((data) => {
  //     setImageBuffer(data.split(",")[1]);
  //     setImage("Change image");
  //   });
  // }
  return (
    <main>
      <Header headerheading={"My Profile"} history={history} />

      <div className="maincontainer">
        <div className="container">
          <div className="row">
            <div className="profilewrap">
              <div className="profilename">
                <h3>{name !== "" ? name : ""}</h3>
              </div>

              <form className="">
                {/* <div className="form-group">
                  <p>Profile Image of the store </p>
                  <div className="custom-file">
                    <input
                      className="custom-file-input"
                      id="files customFile"
                      onChange={(e) => {
                        file(e);
                      }}
                      type="file"
                    />
                    <label className="custom-file-label" for="customFile">
                      {image}
                    </label>
                  </div>
                </div> */}

                <div className="form-group">
                  <p>Store Name : <span>{storename}</span></p>
                  {/* <input
                    type="text"
                    name="storename"
                    defaultValue={storename}
                    onChange={(e) => {
                      setStoreName(e.target.value);
                    }}
                    className="form-control"
                    placeholder="Store Name"
                  /> */}
                  {/* {errors === "storename" && (
                    <p className="error">&nbsp;Store Name is required&nbsp;</p>
                  )} */}
                </div>

                <div className="form-group">
                  <p>Store Address 1 : <span>{storeaddress}</span></p>
                  {/* <input
                    type="text"
                    name="storeaddress"
                    defaultValue={storeaddress}
                    onChange={(e) => {
                      setStoreAddress(e.target.value);
                    }}
                    className="form-control"
                    placeholder="Store Address"
                  />
                  {errorsaddress === "storeaddress" && (
                    <p className="error">
                      &nbsp;Store Address is required&nbsp;
                    </p>
                  )} */}
                  <p>Store Address 2 : <span>{storeaddress2}</span></p>
                  {/* <input
                    type="text"
                    name="storeaddress"
                    defaultValue={storeaddress2}
                    onChange={(e) => {
                      setStoreAddress2(e.target.value);
                    }}
                    className="form-control"
                    placeholder="Store Address"
                  />
                  {errorsaddress === "storeaddress" && (
                    <p className="error">
                      &nbsp;Store Address is required&nbsp;
                    </p>
                  )} */}
                  {console.log(retailerinfo.OrganizationDEO_GEOMapping_c)}
                  {console.log(
                    retailerinfo.OrganizationDEO_GEOMapping_c
                      ? retailerinfo.OrganizationDEO_GEOMapping_c.split(",")
                      : "blank"
                  )}
                  
                </div>

                <div className="form-group">
                  <p>Store Pincode : <span>{storepin}</span></p>
                  {/* <input
                    type="text"
                    name="storepincode"
                    className="form-control"
                    defaultValue={storepin}
                    onChange={(e) => {
                      setStorePin(e.target.value);
                    }}
                    placeholder="Store Pincode"
                  />
                  {errorspincode === "storepin" && (
                    <p className="error">
                      &nbsp;Store Pincode is required&nbsp;
                    </p>
                  )} */}
                </div>
                {datasubmit !== "" ? (
                  <p className="succes-line">{datasubmit}</p>
                ) : (
                  ""
                )}
                {/* <div className="viewall_3">
                  {" "}
                  <button onClick={onSubmit} className="btn_9">
                    Submit
                  </button>
                </div> */}

                {/* </form> */}
                <div className="clearfix"></div>
                {/* <hr /> */}

                <div className="form-group">
                  <p>Store UID : <span>{storeuid}</span></p>
                  {/* <input
                    type="text"
                    name="storeuid"
                    defaultValue={storeuid}
                    onChange={(e) => {
                      setStoreUID(e.target.value);
                    }}
                    disabled={disabled}
                    className="form-control"
                    placeholder="Store UID"
                  />
                  {errorsuid === "storeuid" && (
                    <p className="error">
                      &nbsp;Store User ID is required&nbsp;
                    </p>
                  )} */}
                </div>

                <div className="form-group">
                  <p>Central Business Unit  : <span>{centralbusinessunit}</span></p>
                  {/* <input
                    type="text"
                    name="storecentralbusinessunit"
                    className="form-control"
                    defaultValue={centralbusinessunit}
                    disabled={disabled}
                    onChange={(e) => {
                      setCentralBusinessUnit(e.target.value);
                    }}
                    placeholder="Central Business Unit"
                  />
                  {errorscbu === "centralbusinessunit" && (
                    <p className="error">
                      &nbsp;Central Business Unit is required&nbsp;
                    </p>
                  )} */}
                </div>

                {/* <div className="viewall_4">
                  {" "}
                  <button
                    onClick={(e) => {
                      submitApproval(e);
                    }}
                    className="btn10"
                  >
                    {disabled ? "Request To Edit" : "Submit For Approval"}
                  </button>
                </div> */}
                {retailerinfo.OrganizationDEO_GEOMapping_c ? (
                    <iframe
                      title="maplocation"
                      className="map"
                      frameborder="0"
                      src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyBxrgH5jgCW43lZayk9D_oEyh9XrqSp7Xk&&q=
                      ${
                        retailerinfo.OrganizationDEO_GEOMapping_c.split(",")[0]
                      },${
                        retailerinfo.OrganizationDEO_GEOMapping_c.split(",")[1]
                      }`}
                      allowfullscreen
                    ></iframe>
                  ) : (
                    ""
                  )}
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="footerBg"></div>
    </main>
  );
}

export default UserProfile;
