import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import baseurl from "../../app.json";
import Filter from "./Filter";
import axios from "axios";
import "./filter.css";
import moment from "moment";

function LeadsFollowUps(props) {
  let history = useHistory();
  let a = "string";
  let retailer = JSON.parse(localStorage.getItem("Retalierinfo"));
  let section = localStorage.getItem("section-name");
  // const [traillead, setTrailLead] = useState({
  //   followup: [],
  //   newleads: [],
  //   page: "",
  // });
  const [Render, setRender] = useState(false);
  let product;
  if (props.product) {
    product = props.product.items;
  }

  useEffect(() => {
    if (props.searchleads !== undefined) {
      if (props.searchleads.page) {
        if (props.searchleads.page === "search") {
          setRender(true);
        }
      }
    }
  }, [props]);
  // if (props.filterresult) {
  //   setRerender(true);
  // }
  // const handleClose = () => setModal(false);
  // const handleShow = (e) => {
  //   if (e.target.value === "Product") {
  //     setModal(true);
  //   }
  // };
  // useEffect(() => {
  // console.log(traillead);
  // if (typeof props.filterresult === "string") {
  //   // console.log("search");
  //   // console.log(typeof props.filterresult);
  // } else if (typeof props.filterresult === "object") {
  //   try {
  //     axios({
  //       method: "get",
  //       url: `https://epex.fa.em4.oraclecloud.com/crmRestApi/resources/latest/Order_c/?q=AccountID_c=${retailer.items[0].PartyNumber};ProductNames_c=${props.filterresult.product_name}`,
  //       headers: {
  //         Authorization: baseurl.Authorization,
  //       },
  //     })
  //       .then((res) => {
  //         console.log(res.data);
  //         let follow = [];
  //         let lead = [];
  //         res.data.items.map((item, index) => {
  //           if (item.OrderStatus_c === null || item.OrderStatus_c === "NEW") {
  //             lead.push(item);
  //           } else if (
  //             item.OrderStatus_c === "Completed" ||
  //             item.OrderStatus_c === "CANCELED"
  //           ) {
  //           } else {
  //             follow.push(item);
  //           }
  //         });
  //         // console.log(res.data)
  //         setTrailLead({ followup: follow, newleads: lead, page: "search" });
  //       })
  //       .catch(function (error) {
  //         if (error.message) {
  //           alert(error.message);
  //         }
  //       });
  //   } catch (error) {
  //     alert(error);
  //   }
  // }
  // }, [rerender]);

  let datedifference = (lastdate) => {
    var today = new Date();
    const lastactionDate = moment(lastdate).utc().format("YYYY/MM/DD");
    const todayDate = moment(today).utc().format("YYYY/MM/DD");
    var now = moment(today).utc().format("YYYY/MM/DD HH:mm:ss");
    var then = moment(lastdate).utc().format("YYYY/MM/DD HH:mm:ss");
    var a = moment(todayDate.split("/"));
    var b = moment(lastactionDate.split("/"));
    console.log(a.diff(b, "days"));
    if (a.diff(b, "days") == 0) {
      var startTime = moment(
        moment(lastdate).format("HH:mm:ss a"),
        "HH:mm:ss a"
      );
      var endTime = moment(moment(today).format("HH:mm:ss a"), "HH:mm:ss a");
      console.log(startTime);
      console.log(endTime);
      var duration = moment.duration(endTime.diff(startTime));
      var hours = moment
        .utc(
          moment(now, "DD/MM/YYYY HH:mm:ss").diff(
            moment(then, "DD/MM/YYYY HH:mm:ss")
          )
        )
        .format("HH:mm:ss");
      console.log(hours.split(":")[0]);
      return [0, hours.split(":")[0]];
    } else {
      return [a.diff(b, "days"), 0];
    }
  };
  if (props.searchleads.length !== 0) {
  } else {
  }

  return (
    <>
      {/* <div
        className="tab-pane fade show active"
        id="FollowUps"
        role="tabpanel"
        aria-labelledby="FollowUps-tab"
      > */}
      <div className="followuplist m-10">
        <div className="filternewmaindiv">
          <h2 className="heading2">
            {props.page
              ? props.page == "freetrail"
                ? "Free Trial"
                : "Sales"
              : section == "freetrail"
                ? "Free Trial"
                : section == "sales"
                  ? "Sales"
                  : "Free Trial"}{" "}
            Follow Ups{" "}
            <span className="followupnumber">
              {Render
                ? props.searchleads.followup.length
                : product
                  ? product.length
                  : props.traillead.followup.length}
            </span>
          </h2>
          <Filter
            tab={props.tab}
            page={props.page}
            setRender={setRender}
            traillead={props.traillead}
            saleslead={props.saleslead}
            setSearchLead={props.setSearchLead}
            setFilterResult={props.setFilterResult}
            product={product}
          />

          {Render
            ? props.searchleads.followup.map((item, index) => {
              // console.log(item);
              // var today = new Date();
              // console.log(today);
              // const lastactionDate = moment(item.Disposition2_c).format(
              //   "YYYY/MM/DD"
              // );
              let a = datedifference(item.Disposition2_c);
              // const todayDate = moment(today).format("YYYY/MM/DD");
              // console.log(lastactionDate.split("/"));
              // console.log(todayDate.split("/"));

              // var a = moment(todayDate.split("/"));
              // var b = moment(lastactionDate.split("/"));
              // console.log(a.diff(b, "days"));
              return (
                <div className="followDetailBox">
                  <a
                    className="item"
                    onClick={() => {
                      localStorage.setItem("productinfo", JSON.stringify(item));
                      let product = baseurl.product.filter((items, index) => {
                        if (items.product_name == item.ProductNames_c) {
                          return true;
                        }
                      });
                      localStorage.setItem(
                        "product",
                        JSON.stringify(product[0])
                      );
                      history.push(`/productdetails`);
                    }}
                  >
                    <div className="detail">
                      <h2 className="margin-botton-leads">
                        {item.ContactName_c}
                      </h2>
                      <p>
                        {/* <span className="margin-botton-leads"> */}
                        Last Action Taken | {item.Disposition1_c}
                        {/* </span>{" "} */}
                        <br />
                        {baseurl.product.map((items, index) => {
                          if (items.product_name == item.ProductNames_c) {
                            return items.product_label;
                          }
                        })}{" "}
                        |{" "}
                        {item.LeadType_c.toLowerCase() == "trial"
                          ? "Free Trial Lead"
                          : "Sales Lead"}
                      </p>
                    </div>

                    <div className="right">
                      {console.log({ a })}
                      <div
                        className={`dayspending ${0 <= a[0] && a[0] < 1 ? "greendark" : ""
                          }${1 <= a[0] && a[0] < 3 ? "orange-color" : ""}${3 <= a[0] ? "red" : ""
                          }`}
                      >
                        {" "}
                        {a[0] !== 0 && a[0] !== 1 ? (
                          <>
                            {isNaN(a[0]) ? '-' : a[0]} <span>Days Pending</span>
                          </>
                        ) : (
                          <>
                            {Math.abs(a[1])} <span>Hours Pending</span>
                          </>
                        )}
                      </div>
                    </div>
                  </a>
                </div>
              );
            })
            : product && props.searchleads
              ? (props.searchleads.length !== 0 ? props.searchleads : product).map(
                (item, index) => {
                  let a = datedifference(item.Disposition2_c);

                  return (
                    <div className="followDetailBox">
                      <a
                        className="item"
                        onClick={() => {
                          localStorage.setItem(
                            "productinfo",
                            JSON.stringify(item)
                          );
                          let product = baseurl.product.filter((items, index) => {
                            if (items.product_name == item.ProductNames_c) {
                              return true;
                            }
                          });
                          localStorage.setItem(
                            "product",
                            JSON.stringify(product[0])
                          );
                          // localStorage.setItem(
                          //   "product",
                          //   JSON.stringify({
                          //     product_name: item.ProductNames_c,
                          //     RecordNumber: item.RecordNumber,
                          //     lead_type: item.LeadType_c,
                          //     // product_details: "",
                          //     // date: item.CreationDate
                          //   })
                          // );
                          history.push(`/productdetails`);
                        }}
                      >
                        <div className="detail">
                          <h2 className="margin-botton-leads">
                            {item.ContactName_c}
                          </h2>
                          <p>
                            {/* <span className="margin-botton-leads"> */}
                            Last Action Taken | {item.Disposition1_c}
                            {/* </span>{" "} */}
                            <br />
                            {baseurl.product.map((items, index) => {
                              if (items.product_name == item.ProductNames_c) {
                                return items.product_label;
                              }
                            })}{" "}
                            |{" "}
                            {item.LeadType_c.toLowerCase() == "trial"
                              ? "Free Trial Lead"
                              : "Sales Lead"}
                          </p>
                        </div>

                        <div className="right">
                          <div
                            className={`dayspending ${0 <= a[0] && a[0] < 1 ? "greendark" : ""
                              }${1 <= a[0] && a[0] < 3 ? "orange-color" : ""}${3 <= a[0] ? "red" : ""
                              }`}
                          >
                            {" "}
                            {a[0] !== 0 && a[0] !== 1 ? (
                              <>
                                {isNaN(a[0]) ? '-' : a[0]} <span>Days Pending</span>
                              </>
                            ) : (
                              <>
                                {Math.abs(a[1])} <span>Hours Pending</span>
                              </>
                            )}
                          </div>
                        </div>
                      </a>
                    </div>
                  );
                }
              )
              : props.traillead.page == "sales"
                ? props.traillead.followup.map((item, index) => {
                  let a = datedifference(item.Disposition2_c);
                  // console.log(item);
                  // var today = new Date();
                  // console.log(today);
                  // const lastactionDate = moment(item.Disposition2_c).format(
                  //   "YYYY/MM/DD"
                  // );
                  // const todayDate = moment(today).format("YYYY/MM/DD");
                  // console.log(lastactionDate.split("/"));
                  // console.log(todayDate.split("/"));

                  // var a = moment(todayDate.split("/"));
                  // var b = moment(lastactionDate.split("/"));
                  // console.log(a.diff(b, "days"));
                  return (
                    <div className="followDetailBox">
                      <a
                        onClick={() => {
                          localStorage.setItem("productinfo", JSON.stringify(item));
                          let product = baseurl.product.filter((items, index) => {
                            if (items.product_name == item.ProductNames_c) {
                              return true;
                            }
                          });
                          localStorage.setItem(
                            "product",
                            JSON.stringify(product[0])
                          );
                          // localStorage.setItem(
                          //   "product",
                          //   JSON.stringify({
                          //     product_name: item.ProductNames_c,
                          //     RecordNumber: item.RecordNumber,
                          //     lead_type: item.LeadType_c,
                          //     // product_details: "",
                          //     // date: item.CreationDate
                          //   })
                          // );
                          history.push(`/productdetails`);
                        }}
                        className="item"
                      >
                        <div className="detail">
                          <h2 className="margin-botton-leads">
                            {item.ContactName_c}
                          </h2>
                          <p>
                            {/* <span className="margin-botton-leads"> */}
                            Last Action Taken | {item.Disposition1_c}{" "}
                            {/* </span> */}
                            <br />
                            {baseurl.product.map((items, index) => {
                              if (items.product_name == item.ProductNames_c) {
                                return items.product_label;
                              }
                            })}{" "}
                            |{" "}
                            {item.LeadType_c.toLowerCase() == "trial"
                              ? "Free Trial Lead"
                              : "Sales Lead"}
                          </p>
                        </div>

                        <div className="right">
                          <div
                            className={`dayspending ${0 <= a[0] && a[0] < 1 ? "greendark" : ""
                              }${1 <= a[0] && a[0] < 3 ? "orange-color" : ""}${3 <= a[0] ? "red" : ""
                              }`}
                          >
                            {" "}
                            {a[0] !== 0 && a[0] !== 1 ? (
                              <>
                                {isNaN(a[0]) ? '-' : a[0]} <span>Days Pending</span>
                              </>
                            ) : (
                              <>
                                {Math.abs(a[1])} <span>Hours Pending</span>
                              </>
                            )}
                          </div>
                        </div>
                      </a>
                    </div>
                  );
                })
                : props.traillead.followup.map((item, index) => {
                  // console.log(item);
                  // var today = new Date();
                  // console.log(today);
                  // const lastactionDate = moment(item.Disposition2_c).format(
                  //   "YYYY/MM/DD"
                  let a = datedifference(item.Disposition2_c);
                  // );
                  // const todayDate = moment(today).format("YYYY/MM/DD");
                  // console.log(lastactionDate.split("/"));
                  // console.log(todayDate.split("/"));

                  // var a = moment(todayDate.split("/"));
                  // var b = moment(lastactionDate.split("/"));
                  // console.log(a.diff(b, "days"));
                  return (
                    <div className="followDetailBox">
                      <a
                        onClick={() => {
                          localStorage.setItem("productinfo", JSON.stringify(item));
                          let product = baseurl.product.filter((items, index) => {
                            if (items.product_name == item.ProductNames_c) {
                              return true;
                            }
                          });
                          localStorage.setItem(
                            "product",
                            JSON.stringify(product[0])
                          );
                          // localStorage.setItem(
                          //   "product",
                          //   JSON.stringify({
                          //     product_name: item.ProductNames_c,
                          //     RecordNumber: item.RecordNumber,
                          //     lead_type: item.LeadType_c,
                          //     // product_details: "",
                          //     // date: item.CreationDate
                          //   })
                          // );
                          history.push(`/productdetails`);
                        }}
                        className="item"
                      >
                        <div className="detail mb-10">
                          <h2 className="margin-botton-leads">
                            {item.ContactName_c}
                          </h2>
                          <p>
                            {/* <span className="margin-botton-leads"> */}
                            Last Action Taken | {item.Disposition1_c}
                            {/* </span>{" "} */}
                            <br />
                            {baseurl.product.map((items, index) => {
                              if (items.product_name == item.ProductNames_c) {
                                return items.product_label;
                              }
                            })}{" "}
                            |{" "}
                            {item.LeadType_c.toLowerCase() == "trial"
                              ? "Free Trial Lead"
                              : "Sales Lead"}
                          </p>
                        </div>

                        <div className="right">
                          <div
                            className={`dayspending ${0 <= a[0] && a[0] < 1 ? "greendark" : ""
                              }${1 <= a[0] && a[0] < 3 ? "orange-color" : ""}${3 <= a[0] ? "red" : ""
                              }`}
                          >
                            {" "}
                            {a[0] !== 0 && a[0] !== 1 ? (
                              <>
                                {isNaN(a[0]) ? '-' : a[0]} <span>Days Pending</span>
                              </>
                            ) : (
                              <>
                                {Math.abs(a[1])} <span>Hours Pending</span>
                              </>
                            )}
                          </div>
                        </div>
                      </a>
                    </div>
                  );
                })}
        </div>
      </div>
      {/* </div> */}
    </>
  );
}

export default LeadsFollowUps;
