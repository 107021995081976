import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
} from "react-router-dom";
import "./pageone.css";
import Header from "./header/Header";
import TabsMenu from "./tabs-menu/TabsMenu";
import axios from "axios";
import baseurl from "../app.json";
import { Modal, Button } from "react-bootstrap";
import { DateRangePicker } from "react-date-range";
import moment from "moment";
import { addDays, subDays } from "date-fns";

function Pageone() {
  const [Tab, setTab] = useState(0)
  const [secondmodal, setSecondModal] = useState(false);
  const [modal, setModal] = useState(false);
  const [saleslead, setSalesleads] = useState({
    followup: [],
    newleads: [],
    completed: [],
  });
  const [searchsaleslead, setSearchSalesleads] = useState({
    followup: [],
    newleads: [],
    completed: [],
  });

  const [state, setState] = useState([
    {
      startDate: subDays(new Date(), 0),
      endDate: addDays(new Date(), 0),
      key: "selection",
    },
  ]);

  let caminfo = JSON.parse(localStorage.getItem("product"));


  let retailer = JSON.parse(localStorage.getItem("Retalierinfo"));

  let history = useHistory();
  let login = localStorage.getItem("Retalierinfo");
  console.log(login);
  console.log(history);

  const handleShow = (e) => {
    console.log(e.target.value)
    if (e.target.value === "Date") {
      // setSecondModal(false);
      setSecondModal(true);
    }
    // else {
    //   if (props.tab == 0) {
    //     props.setRender(false);
    //     props.setSearchLead({});
    //   } else {
    //     props.setSearchLead({});
    //   }
    // }
  };


  const handleCloseSecond = () => {
    setSecondModal(false);
  };

  const handleClose = () => {
    setModal(false);
  };

  const handleOnChange = (ranges) => {
    const { selection } = ranges;
    setState([selection]);
    console.log({ selection })
    if (ranges.selection.startDate === ranges.selection.endDate) {
      handleClose();
    } else {
      var startDate = moment(ranges.selection.startDate).format("DD,MM,YYYY");
      let endDate = moment(ranges.selection.endDate).format("DD,MM,YYYY");
      let data = {
        newleads: [],
        followups: [],
        completed: [],
      }

      saleslead.followup.map((item, index) => {
        console.log({ saleslead: item })
        let date = moment(item.CreationDate).format("DD,MM,YYYY");

        var d1 = startDate.split(",");
        var d2 = endDate.split(",");
        var c = date.split(",");

        var from = new Date(d1[2], parseInt(d1[1]) - 1, d1[0]); // -1 because months are from 0 to 11
        var to = new Date(d2[2], parseInt(d2[1]) - 1, d2[0]);
        var check = new Date(c[2], parseInt(c[1]) - 1, c[0]);


        console.log({ from })
        console.log({ to })
        console.log({ check })

        if (check >= from && check <= to) {
          data.followups.push(item);
        }



        // if (c >= d1 && c <= d2) {
        //   data.followups.push(item);
        // }
      })

      saleslead.newleads.map((item, index) => {
        let date = moment(item.CreationDate).format("DD,MM,YYYY");

        var d1 = startDate.split(",");
        var d2 = endDate.split(",");
        var c = date.split(",");

        var from = new Date(d1[2], parseInt(d1[1]) - 1, d1[0]); // -1 because months are from 0 to 11
        var to = new Date(d2[2], parseInt(d2[1]) - 1, d2[0]);
        var check = new Date(c[2], parseInt(c[1]) - 1, c[0]);


        console.log({ from })
        console.log({ to })
        console.log({ check })

        if (check >= from && check <= to) {
          data.newleads.push(item);
        }
      })
      saleslead.completed.map((item, index) => {
        let date = moment(item.CreationDate).format("DD,MM,YYYY");

        var d1 = startDate.split(",");
        var d2 = endDate.split(",");
        var c = date.split(",");

        var from = new Date(d1[2], parseInt(d1[1]) - 1, d1[0]); // -1 because months are from 0 to 11
        var to = new Date(d2[2], parseInt(d2[1]) - 1, d2[0]);
        var check = new Date(c[2], parseInt(c[1]) - 1, c[0]);


        console.log({ from })
        console.log({ to })
        console.log({ check })

        if (check >= from && check <= to) {
          data.completed.push(item);
        }
      })

      console.log({ data })
      setSalesleads({
        followup: data.followups,
        newleads: data.newleads,
        completed: data.completed,
      })

      handleClose();
    }

    console.log({ salesleadssss: saleslead })
  }

  let datedifference = (lastdate) => {
    var today = new Date();
    const lastactionDate = moment(lastdate).utc().format("YYYY/MM/DD");
    const todayDate = moment(today).utc().format("YYYY/MM/DD");
    var now = moment(today).utc().format("YYYY/MM/DD HH:mm:ss");
    var then = moment(lastdate).utc().format("YYYY/MM/DD HH:mm:ss");
    var a = moment(todayDate.split("/"));
    var b = moment(lastactionDate.split("/"));
    console.log(a.diff(b, "days"));
    if (a.diff(b, "days") == 0) {
      var startTime = moment(
        moment(lastdate).format("HH:mm:ss a"),
        "HH:mm:ss a"
      );
      var endTime = moment(moment(today).format("HH:mm:ss a"), "HH:mm:ss a");
      console.log(startTime);
      console.log(endTime);
      var duration = moment.duration(endTime.diff(startTime));
      var hours = moment
        .utc(
          moment(now, "DD/MM/YYYY HH:mm:ss").diff(
            moment(then, "DD/MM/YYYY HH:mm:ss")
          )
        )
        .format("HH:mm:ss");
      console.log(hours.split(":")[0]);
      return [0, hours.split(":")[0]];
    } else {
      return [a.diff(b, "days"), 0];
    }
  };


  useEffect(() => {

    axios({
      method: "get",
      url: `https://www.bauschandlomb.in/retailer/product-info?account_id=${retailer.items[0].PartyNumber}&product=${caminfo.product_name}`,
    })
      .then((res) => {
        // res = JSON.parse(res)
        let follow = [];
        let lead = [];
        let completed = [];
        let dataItems = res.data.items.sort(function (a, b) {
          // Turn your strings into dates, and then subtract them
          // to get a value that is either negative, positive, or zero.
          return new Date(b.CreationDate) - new Date(a.CreationDate);
        });
        console.log({ dataItems })
        dataItems.map((item, index) => {
          if (
            item.RetailerLeadStatus_c.toLowerCase() === null ||
            item.RetailerLeadStatus_c.toLowerCase() === "new"
          ) {
            lead.push(item);
          } else if (
            item.RetailerLeadStatus_c.toLowerCase() === "completed" ||
            item.RetailerLeadStatus_c.toLowerCase() === "canceled"
          ) {
            completed.push(item)
          } else {
            follow.push(item);
          }
        });
        console.log('outside frist ma[')
        setSalesleads({ followup: follow, newleads: lead, completed });
      })
  }, [])
  // setTimeout(() => {
  //   if (login) {
  //     history.push("/profile");
  //   } else {
  //     history.push("/login");
  //   }
  // }, [2000]);

  return (
    <main>
      <Header headerheading={caminfo ? caminfo.product_label : ""} showqustion={true} history={history} />
      <div className="maincontainer">
        <div className="container">
          <div className="row">
            <div className="tabContainer" id="tile-1">
              <TabsMenu
                tab={Tab}
                setTab={setTab}
                firstmenu={"New Leads"}
                secondmenu={"Follow Ups"}
                thirdmenu={"Completed Leads"}
                followup={saleslead.followup}
                newleads={saleslead.newleads}
                completed={saleslead.completed}
              />
              <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                  <div class="modal-content">
                    <div class="modal-header">
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    {baseurl.product.map((item, index) => {
                      console.log({ item: item.product_label })
                      console.log({ caminfo: caminfo.product_label })
                      console.log(item.product_label == caminfo.product_label)
                      if (item.product_label == caminfo.product_label) {
                        return (
                          <div class="modal-body">
                            <h2>{item.product_name}</h2>
                            <p>About the product</p>
                            <p>{item.product_details}</p>
                          </div>
                        )
                      }
                    })}


                  </div>
                </div>
              </div>
              <div className="pageone">
                <div className="container">
                  <div className="newleaddiv">
                    <div className="newleaddivleft">
                      <h4 >{Tab == 0 ? "New Lead" : Tab == 1 ? "Follow Ups" : Tab == 2 ? "Completed Leads" : "New Lead"}<span>{Tab == 0 ? saleslead.newleads.length : Tab == 1 ? saleslead.followup.length : Tab == 2 ? saleslead.completed.length : 0}</span></h4>
                    </div>
                    <div className="newleaddivright">
                      <select
                        className="form-control"
                        id="filterbox"
                        onChange={handleShow}
                      >
                        <option>Filter By</option>
                        {/* <option>Product</option> */}
                        <option>Date</option>
                        <option>View All</option>
                      </select>
                    </div>
                  </div>
                  <div className="pageoneContent">

                    {console.log({ show: searchsaleslead.newleads.length !== 0 ? searchsaleslead.newleads : saleslead.newleads })}
                    {Tab == 0 ?
                      (searchsaleslead.newleads.length !== 0 ? searchsaleslead.newleads : saleslead.newleads).map((item, index) => {
                        console.log({ Disposition2_c: item.Disposition2_c })
                        let a = datedifference(item.CreationDate);
                        // let date =
                        //   item.Disposition2_c.split("T")[0].split("-");
                        return (
                          <div
                            key={`leadtype${index}`}
                            className="followDetailBox"
                          >
                            <a
                              onClick={() => {
                                localStorage.setItem(
                                  "productinfo",
                                  JSON.stringify(item)
                                );
                                let product = baseurl.product.filter(
                                  (items, index) => {
                                    if (
                                      items.product_name ==
                                      item.ProductNames_c
                                    ) {
                                      return true;
                                    }
                                  }
                                );
                                localStorage.setItem(
                                  "product",
                                  JSON.stringify(product[0])
                                );
                                history.push(`/productdetails`);
                              }}
                              className="item"
                            >
                              <div className="detail">
                                <h2>{item.ContactName_c}</h2>
                                <p>
                                  {baseurl.product.map((items, index) => {

                                    if (
                                      items.product_name ==
                                      item.ProductNames_c
                                    ) {
                                      return items.product_label;
                                    }
                                  })}{" "}
                                  |{" "}
                                  {item.LeadType_c.toLowerCase() == "trial"
                                    ? "Free Trial Lead"
                                    : "Sales Lead"}
                                </p>
                                <p className="newleaddatetime">
                                  {" "}
                                  {moment(item.CreationDate).format(
                                    "DD MMM YYYY | h:mm a"
                                  )}
                                  {/* |{" "}
                    {item.AppointmentTime_c} */}
                                </p>
                              </div>

                              <div className="right">
                                <div
                                  className={`dayspending ${0 <= a[0] && a[0] < 1 ? "greendark" : ""
                                    }${1 <= a[0] && a[0] < 3
                                      ? "orange-color"
                                      : ""
                                    }${3 <= a[0] ? "red" : ""}`}
                                >
                                  {" "}
                                  {a[0] !== 0 ? (
                                    <>
                                      {isNaN(a[0]) ? '-' : a[0]} <span>Days Pending</span>
                                    </>
                                  ) : (
                                    <>
                                      {Math.abs(a[1])}{" "}
                                      <span>Hours Pending</span>
                                    </>
                                  )}
                                </div>
                              </div>
                            </a>
                          </div>
                        )
                      })
                      : Tab == 1 ?
                        (searchsaleslead.followup.length !== 0 ? searchsaleslead.followup : saleslead.followup).map((item, index) => {
                          console.log({ Disposition2_c: item.Disposition2_c })
                          let a = datedifference(item.Disposition2_c);
                          // let date =
                          //   item.Disposition2_c.split("T")[0].split("-");
                          return (
                            <div
                              key={`leadtype${index}`}
                              className="followDetailBox"
                            >
                              <a
                                onClick={() => {
                                  localStorage.setItem(
                                    "productinfo",
                                    JSON.stringify(item)
                                  );
                                  let product = baseurl.product.filter(
                                    (items, index) => {
                                      if (
                                        items.product_name ==
                                        item.ProductNames_c
                                      ) {
                                        return true;
                                      }
                                    }
                                  );
                                  localStorage.setItem(
                                    "product",
                                    JSON.stringify(product[0])
                                  );
                                  history.push(`/productdetails`);
                                }}
                                className="item"
                              >
                                <div className="detail">
                                  <h2>{item.ContactName_c}</h2>
                                  <p>
                                    {baseurl.product.map((items, index) => {

                                      if (
                                        items.product_name ==
                                        item.ProductNames_c
                                      ) {
                                        return items.product_label;
                                      }
                                    })}{" "}
                                    |{" "}
                                    {item.LeadType_c.toLowerCase() == "trial"
                                      ? "Free Trial Lead"
                                      : "Sales Lead"}
                                  </p>
                                  <p className="newleaddatetime">
                                    {" "}
                                    {moment(item.CreationDate).format(
                                      "DD MMM YYYY | h:mm a"
                                    )}
                                    {/* |{" "}
                    {item.AppointmentTime_c} */}
                                  </p>
                                </div>

                                <div className="right">
                                  <div
                                    className={`dayspending ${0 <= a[0] && a[0] < 1 ? "greendark" : ""
                                      }${1 <= a[0] && a[0] < 3
                                        ? "orange-color"
                                        : ""
                                      }${3 <= a[0] ? "red" : ""}`}
                                  >
                                    {" "}
                                    {a[0] !== 0 ? (
                                      <>
                                        {isNaN(a[0]) ? '-' : a[0]} <span>Days Pending</span>
                                      </>
                                    ) : (
                                      <>
                                        {Math.abs(a[1])}{" "}
                                        <span>Hours Pending</span>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </a>
                            </div>
                          )
                        })

                        : Tab == 2 ?

                          (searchsaleslead.completed.length !== 0 ? searchsaleslead.completed : saleslead.completed).map((item, index) => {
                            console.log({ Disposition2_c: item.Disposition2_c })
                            let a = datedifference(item.Disposition2_c);
                            // let date =
                            //   item.Disposition2_c.split("T")[0].split("-");
                            return (
                              <div
                                key={`leadtype${index}`}
                                className="followDetailBox"
                              >
                                <a
                                  onClick={() => {
                                    localStorage.setItem(
                                      "productinfo",
                                      JSON.stringify(item)
                                    );
                                    let product = baseurl.product.filter(
                                      (items, index) => {
                                        if (
                                          items.product_name ==
                                          item.ProductNames_c
                                        ) {
                                          return true;
                                        }
                                      }
                                    );
                                    localStorage.setItem(
                                      "product",
                                      JSON.stringify(product[0])
                                    );
                                    history.push(`/productdetails`);
                                  }}
                                  className="item"
                                >
                                  <div className="detail">
                                    <h2>{item.ContactName_c}</h2>
                                    <p>
                                      {baseurl.product.map((items, index) => {

                                        if (
                                          items.product_name ==
                                          item.ProductNames_c
                                        ) {
                                          return items.product_label;
                                        }
                                      })}{" "}
                                      |{" "}
                                      {item.LeadType_c.toLowerCase() == "trial"
                                        ? "Free Trial Lead"
                                        : "Sales Lead"}
                                    </p>
                                    <p className="newleaddatetime">
                                      {" "}
                                      {moment(item.CreationDate).format(
                                        "DD MMM YYYY | h:mm a"
                                      )}
                                      {/* |{" "}
                    {item.AppointmentTime_c} */}
                                    </p>
                                  </div>

                                  <div className="right">
                                    <div
                                      className={`dayspending greendark`}
                                    >
                                      {" "}
                                      {a[0] !== 0 ? (
                                        <>
                                          0 <span>Days Pending</span>
                                        </>
                                      ) : (
                                        <>
                                          {Math.abs(a[1])}{" "}
                                          <span>Hours Pending</span>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </a>
                              </div>
                            )
                          })

                          :
                          saleslead.newleads.map((item, index) => {
                            console.log({ Disposition2_c: item.Disposition2_c })
                            let a = datedifference(item.Disposition2_c);
                            // let date =
                            //   item.Disposition2_c.split("T")[0].split("-");
                            return (
                              <div
                                key={`leadtype${index}`}
                                className="followDetailBox"
                              >
                                <a
                                  onClick={() => {
                                    localStorage.setItem(
                                      "productinfo",
                                      JSON.stringify(item)
                                    );
                                    let product = baseurl.product.filter(
                                      (items, index) => {
                                        if (
                                          items.product_name ==
                                          item.ProductNames_c
                                        ) {
                                          return true;
                                        }
                                      }
                                    );
                                    localStorage.setItem(
                                      "product",
                                      JSON.stringify(product[0])
                                    );
                                    history.push(`/productdetails`);
                                  }}
                                  className="item"
                                >
                                  <div className="detail">
                                    <h2>{item.ContactName_c}</h2>
                                    <p>
                                      {baseurl.product.map((items, index) => {

                                        if (
                                          items.product_name ==
                                          item.ProductNames_c
                                        ) {
                                          return items.product_label;
                                        }
                                      })}{" "}
                                      |{" "}
                                      {item.LeadType_c.toLowerCase() == "trial"
                                        ? "Free Trial Lead"
                                        : "Sales Lead"}
                                    </p>
                                    <p className="newleaddatetime">
                                      {" "}
                                      {moment(item.CreationDate).format(
                                        "DD MMM YYYY | h:mm a"
                                      )}
                                      {/* |{" "}
                    {item.AppointmentTime_c} */}
                                    </p>
                                  </div>

                                  <div className="right">
                                    <div
                                      className={`dayspending ${0 <= a[0] && a[0] < 1 ? "greendark" : ""
                                        }${1 <= a[0] && a[0] < 3
                                          ? "orange-color"
                                          : ""
                                        }${3 <= a[0] ? "red" : ""}`}
                                    >
                                      {" "}
                                      {a[0] !== 0 ? (
                                        <>
                                          {isNaN(a[0]) ? '-' : a[0]} <span>Days Pending</span>
                                        </>
                                      ) : (
                                        <>
                                          {Math.abs(a[1])}{" "}
                                          <span>Hours Pending</span>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </a>
                              </div>
                            )
                          })
                    }


                    {/* <div className="pageonediv">
                <div className="pageonedivleft">
                    <h3>Bhaskar Jain</h3>
                    <h5>BioTrue® One Day | Free Trail lead</h5>
                    <p>08 Jul 2021 | 3:04 pm</p>
                </div>
                <div className="pageonedivright">
                    <h2>136</h2>
                    <p>Days Pending</p>
                </div>
            </div>

            <div className="pageonediv">
                <div className="pageonedivleft">
                    <h3>Bhaskar Jain</h3>
                    <h5>BioTrue® One Day | Free Trail lead</h5>
                    <p>08 Jul 2021 | 3:04 pm</p>
                </div>
                <div className="pageonedivright">
                    <h2>136</h2>
                    <p>Days Pending</p>
                </div>
            </div>

            <div className="pageonediv">
                <div className="pageonedivleft">
                    <h3>Bhaskar Jain</h3>
                    <h5>BioTrue® One Day | Free Trail lead</h5>
                    <p>08 Jul 2021 | 3:04 pm</p>
                </div>
                <div className="pageonedivright">
                    <h2>136</h2>
                    <p>Days Pending</p>
                </div>
            </div>

            <div className="pageonediv">
                <div className="pageonedivleft">
                    <h3>Bhaskar Jain</h3>
                    <h5>BioTrue® One Day | Free Trail lead</h5>
                    <p>08 Jul 2021 | 3:04 pm</p>
                </div>
                <div className="pageonedivright">
                    <h2>136</h2>
                    <p>Days Pending</p>
                </div>
            </div>

            <div className="pageonediv">
                <div className="pageonedivleft">
                    <h3>Bhaskar Jain</h3>
                    <h5>BioTrue® One Day | Free Trail lead</h5>
                    <p>08 Jul 2021 | 3:04 pm</p>
                </div>
                <div className="pageonedivright">
                    <h2>136</h2>
                    <p>Days Pending</p>
                </div>
            </div> */}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*       
        <div className="callnowdiv">
            <p><img src="assets/images/call-icon.png"/> Call Now</p>
        </div> */}
      <div className="footerBg"></div>
      <Modal
        dialogClassName="my-modal"
        show={secondmodal}
        onHide={() => {
          handleCloseSecond();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Select Date</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="second-modal-body">
            {/* <DatePicker
              selected={startDate}
              onChange={(date) => {
                onDateChange(date);
              }}
            />
            <Calendar
              date={new Date()}
              onChange={() => {
                handleSelect();
              }}
            /> */}
            <DateRangePicker
              onChange={handleOnChange}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={1}
              ranges={state}
              direction="horizontal"
            />
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </main>
  );
}

export default Pageone;
