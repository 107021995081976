import React, { useState, useEffect } from "react";
import "./nav.css";
import { BrowserRouter as Router, Switch, Route, Link, useHistory } from "react-router-dom";

function Navbar(props) {
  const [retailerinfo, setRetailerInfo] = useState(
    localStorage.getItem("Retalierinfo")
  );

  let history = useHistory();
  useEffect(() => {
    let retailer = JSON.parse(localStorage.getItem("Retalierinfo"));
    setRetailerInfo(retailer);
  }, []);
  console.log(retailerinfo);
  return (
    <nav className="side-nav">
      <div className="upper-div">
        <div className="leftheader">
          <a
            onClick={() => {
              props.setNav(false);
            }}
            className="menu"
          >
            {" "}
            <div className="backnav">
              <i className="fal fa-long-arrow-left"></i>
            </div>{" "}
          </a>
        </div>
        <br />
        <div className="clearfix"></div>
        <div className="container">
          <div className="row">
            <div className="profilecontainer">
              <div className="col-3">
                <div className="round-div">
                  <h1>
                    {retailerinfo.items
                      ? retailerinfo.items[0].PartyUniqueName[0]
                      : ""}
                  </h1>
                </div>
              </div>

              <div className="col-9">
                <div className="Leftprofilename">
                  <p>
                    {retailerinfo.items
                      ? retailerinfo.items[0].PartyUniqueName
                      : ""}
                  </p>
                  <p>
                    {retailerinfo.items
                      ? retailerinfo.items[0]
                          .OrganizationDEO_AccountPhoneNumber_c
                      : ""}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ul className="list-group list-menu">
        {/* <ul className="list-group"> */}
        <Link to="profile">
          <li
            key="ndunique1"
            onClick={() => {
              props.setNav(false);
            }}
            className="sidebar-list list-group-item"
          >
            <div className="sidenav-icon">
              {" "}
              <i className="fal fa-home-lg-alt"></i>{" "}
            </div>
            Home
          </li>
          <hr className="sidebar-hr" />
        </Link>
        <Link to="userprofile">
          <li key="ndunique2" className="list-group-item sidebar-list">
            <div className="sidenav-icon">
              {" "}
              <i className="fal fa-user"></i>{" "}
            </div>
            My Profile
          </li>
          <hr className="sidebar-hr" />
        </Link>
          <li key="ndunique2" onClick={()=>{
            localStorage.removeItem("Retalierinfo")
            history.push("/login")
          }} className="list-group-item sidebar-list">
            <div className="sidenav-icon">
              {" "}
              <i className="fal fa-user"></i>{" "}
            </div>
            Logout
          </li>
          <hr className="sidebar-hr" />
        {/* </Link> */}
        {/* <Link to="analytic">
          <li key="ndunique3" className="list-group-item sidebar-list">
          <div className="sidenav-icon"><i className="fal fa-chart-bar"></i> </div> 
            My Analytics
          </li>
          <hr className="sidebar-hr" />
        </Link> */}
      </ul>
    </nav>
  );
}

export default Navbar;
