import React, { useEffect, useState } from "react";
import moment from "moment";
import baseurl from "../../app.json";
import { DateRangePicker } from "react-date-range";
import { DatePicker, RangeDatePicker } from "@y0c/react-datepicker";
import { Modal, Button } from "react-bootstrap";
import { addDays, subDays } from "date-fns";
import axios from "axios";
import "@y0c/react-datepicker/assets/styles/calendar.scss";
import "dayjs/locale/ko";
import "dayjs/locale/en";

function FollowUps(props) {
  const [product, setProduct] = useState({});
  const [message, setMessage] = useState(false);
  const [showdp, setShowdp] = useState(false);
  const [message2, setMessage2] = useState(false);
  const [storevisited, setStoreVisited] = useState("");
  const [storevisitedmodal, setStoreVisitedModal] = useState(false);
  const [buttondisable, setbuttondisable] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [state, setState] = useState([
    {
      startDate: subDays(new Date(), 0),
      endDate: addDays(new Date(), 0),
      key: "selection",
    },
  ]);
  const [month, setMonth] = useState([
    { id: "01", month: "Jan" },
    { id: "02", month: "Feb" },
    { id: "03", month: "Mar" },
    { id: "04", month: "Apr" },
    { id: "05", month: "May" },
    { id: "06", month: "Jun" },
    { id: "07", month: "Jul" },
    { id: "08", month: "Aug" },
    { id: "09", month: "Sep" },
    { id: "10", month: "Oct" },
    { id: "11", month: "Nov" },
    { id: "12", month: "Dec" },
  ]);
  let retailerinformation = JSON.parse(localStorage.getItem("Retalierinfo"));
  useEffect(() => {
    console.log({props:props.product});

if(props.product.RetailerLeadStatus_c == "Completed"){
  setCompleted(true)
}

    if (props.product.CreationDate !== []) {
      let date = props.product.CreationDate.split("T")[0].split("-");
      let day = date[2];
      let months;
      month.map(function (ite, ind) {
        if (ite.id == date[1]) {
          months = ite.month;
        }
      });
      let year = date[0];
      props.product.CreationDate = `${day} ${months} ${year}`;
    }
// alert(JSON.stringify(props.product))
    setProduct(props.product);
  }, []);
  const handleCloseSecond = () => {
    setShowdp(false);
  };

  const handleOnChange = (ranges) => {
    const { selection } = ranges;
    // onChange(selection);
    console.log([selection]);
  };

  const handleClose = () => {
    setStoreVisitedModal(false);
  };

  let datedifference = (lastdate) => {
    var today = new Date();
    const lastactionDate = moment(lastdate).format("YYYY/MM/DD");
    const todayDate = moment(today).format("YYYY/MM/DD");

    var a = moment(todayDate.split("/"));
    var b = moment(lastactionDate.split("/"));
    return a.diff(b, "days");
  };

  const onChanges =
    (title) =>
    (...args) => {
      console.log("blank");
      console.log(args[1]);
      setStoreVisited(args[1]);
      setCompleted(true);
    };

  function saveCompleted() {
    var now = new Date();

    if(moment(storevisited).format('DD-MM-YYYY') < moment(now).format('DD-MM-YYYY') ){
    console.log({storevisited:moment(storevisited).format('DD-MM-YYYY')})
    console.log({storevisited:storevisited})
    console.log({now:moment(now).format('DD-MM-YYYY')})
    console.log({now:now})
    alert("Past Date Cannot Be Entered")
    }else{

    let data = {
      RecordName: props.productinfo.RecordName,
      Disposition_c: "Completed",
      Status_c: "Completed",
    };
    try {
      axios({
        method: "post",
        url: `https://epex.fa.em4.oraclecloud.com:443/crmRestApi/resources/latest/Order_c/${props.productinfo.Id}/child/OrderLineItemCollection_c`,
        data: data,
        headers: {
          Authorization: baseurl.Authorization,
        },
      })
        .then((res) => {
          let dispodata = {
            Disposition2_c: moment(storevisited).format(),
            Disposition1_c: "Completed",
            StorePickUpConfirmation_c: "confirmed",
            StorePickUpConfirmationDateTime_c: moment(storevisited).format(),
            RetailerLeadStatus_c: "Completed",
          };

          axios({
            method: "patch",
            url: `https://epex.fa.em4.oraclecloud.com/crmRestApi/resources/latest/Order_c/${props.productinfo.Id}`,
            data: dispodata,
            headers: {
              Authorization: baseurl.Authorization,
            },
          })
            .then((res) => {
              // console.log(res.data);
              localStorage.setItem("productinfo", JSON.stringify(res.data));
              setbuttondisable(true);
              setStoreVisitedModal(true);
              // window.location.reload();
            })
            .catch(function (error) {
              if (error.message) {
                // alert("error",error.message);
              }
            });
        })
        .catch(function (error) {
          if (error.message) {
            // alert("error",error.message);
          }
        });
    } catch (error) {
      // alert("error",error);
    }
    }
  }

  console.log(product.Disposition1_c);
  console.log(
    product.Disposition1_c !== undefined
      ? product.Disposition1_c !== null
        ? product.Disposition1_c.toLowerCase() == "store visit confirmed"
          ? true
          : false
        : false
      : false
  );

  console.log(product.Disposition1_c == null);
  useEffect(() => {
    if (product.Disposition1_c == "" || product.Disposition1_c == null) {
      setMessage(true);
      setMessage2(false);
      console.log("show callscript");
    } else if (product.Disposition1_c == "Store Visit Confirmed") {
      setMessage2(true);
      setMessage(false);
      console.log("show callscript");
    } else {
      setMessage(false);
      setMessage2(false);
    }
  }, [product.Disposition1_c]);

  return (
    <>
    
    <main>
      <div className="personaldetail">
        <div className="container">

          <div className="personalContent">
            <div className="personaldiv personaluserdiv">
                <div className="personaldivleft">
                    <img
                    className="logo-loginpage"
                    src="assets/images/user-icon.png"
                    alt=""
                    />
                </div>
                <div className="personaldivright">
                    <h3>{product.ContactName_c}</h3>
                </div>
            </div>
            <div className="personaldiv">
                <div className="personaldivleft">
                    <img
                    className="logo-loginpage"
                    src="assets/images/eye-lens-icon.png"
                    alt=""
                    />
                </div>
                <div className="personaldivright">
                    <h5>Product Name</h5>
                    <p>{baseurl.product.map((item, index) => {
                    if (product.ProductNames_c == item.product_name) {
                      return item.product_label;
                    }
                  })}</p>
                </div>
            </div>

            <div className="personaldiv">
                <div className="personaldivleft">
                    <img
                    className="logo-loginpage"
                    src="assets/images/location-icon.png"
                    alt=""
                    />
                </div>
                <div className="personaldivright">
                    <h5>Location</h5>
                    <p>{product.City_c}</p>
                </div>
            </div>

            <div className="personaldiv">
                <div className="personaldivleft">
                    <img
                    className="logo-loginpage"
                    src="assets/images/shopping-iconnew.png"
                    alt=""
                    />
                </div>
                <div className="personaldivright">
                    <h5>Store Name</h5>
                    <p>{product.StoreId_c}</p>
                </div>
            </div>

            <div className="personaldiv">
                <div className="personaldivleft">
                    <img
                    className="logo-loginpage"
                    src="assets/images/calender-icon.png"
                    alt=""
                    />
                </div>
                <div className="personaldivright">
                    <h5>Free Trial Requested Date</h5>
                    <p>{product.CreationDate}</p>
                </div>
            </div>

            <div className="personaldiv">
                <div className="personaldivleft">
                    <img
                    className="logo-loginpage"
                    src="assets/images/icon-feather-eye-icon.png"
                    alt=""
                    />
                </div>
                <div className="personaldivright">
                    <h5>Eye Test</h5>
                    {product.OrderType_c === "store-eye-check" ? (
                   
                        
                            product.OrderType_c.toLowerCase() ===
                            "store-eye-check"
                              ?
                        <p>Yes</p>
                        :""
                     
              ) : (
                ""
              )}
                </div>
                
          
            </div>
            {console.log({RetailerLeadStatus_c:product.RetailerLeadStatus_c})}
{product.Disposition1_c == "Store Visit Confirmed" || product.RetailerLeadStatus_c == "Completed"?
            <div className="personaldiv">
                <div className="personaldivleft">
                  <div className="form__radio-group">
                    <input
                      type="radio"
                      name="size"
                      id="dispo1a1"
                      checked={completed ? true : false}
                      onClick={() => {
                        completed ? setCompleted(false) : setCompleted(true);
                      }}
                    />
                  </div>
                </div>
                <div className="personaldivright">
                    <h5>Store Visit Completed</h5>
                </div>
            </div>
                    :""}

            {/* <div className="callscript">
                <select>
                    <option>When you get the Lead</option>
                    <option>When you get the Lead</option>
                    <option>When you get the Lead</option>
                    <option>When you get the Lead</option>
                    <option>When you get the Lead</option>
                </select>
                <h3>When You Get The Lead</h3>
                <p>Good morning Sir/Ma’am </p>
                <p>I am calling from GKB optical on behalf of Bausch & Lomb.
                This is regarding the Bausch and Lomb contact lens trial appointment that you had requested 
                with us at GKB optical. Wanted to confirm the same.</p>
                <p>If the consumer says Yes – Thankyou. Wishing you a wonderful experience with Bausch & Lomb 
                contact lenses! We will contact you once the trial reaches the store.</p>
                <p>YES - Confirm “trial visit confirmed” and schedule a call back for that time</p>
                <p>NO- Tap “not interested” option in app</p>
            </div> */}


          </div>
        </div>
      </div>
{/* {console.log({completed})} */}
{/* // ?
// ""
// : */}
{/* <div className="callnowdiv">
            <p><img src="assets/images/call-icon.png"/> Call Now</p>
        </div> */}
{/* } */}
        
        
      <div className="footerBg"></div>
    </main>
      {/* <div className="PDetailBox">
        <div className="item">
          <div className="detail">
            <h2>{product.ContactName_c}</h2>

            <ul className="listview flush transparent simple-listview no-space mt-3">
              <li key="unique1">
                <div className="headingLeft">Product Name:</div>
                <div className="headingRight">
                  {baseurl.product.map((item, index) => {
                    if (product.ProductNames_c == item.product_name) {
                      return item.product_label;
                    }
                  })}
                </div>
              </li>
              <li key="unique2">
                <div className="headingLeft">Location:</div>
                <div className="headingRight">{product.City_c}</div>
              </li>
              <li key="unique7">
                <div className="headingLeft">Store Name:</div>
                <div className="headingRight">{product.StoreId_c}</div>
              </li>
              <li key="unique3">
                <div className="headingLeft">Free Trial Request Date:</div>
                <div className="headingRight">{product.CreationDate}</div>
              </li>
              {props.clientdetails.PersonDEO_VisionCorrectionType_c ? (
                <li key="unique9">
                  <div className="headingLeft">Vision Correction:</div>
                  <div className="headingRight">
                    {props.clientdetails.PersonDEO_VisionCorrectionType_c}
                  </div>
                </li>
              ) : (
                ""
              )}
              {product.OrderType_c === "store-eye-check" ? (
                <li>
                  <div className="headingLeft">Eye Test:</div>
                  <div className="headingRight">
                    <div className="form__group">
                      <div className="form__radio-group">
                        <input
                          type="radio"
                          name="sizes"
                          id="small1"
                          className="form__radio-input"
                          readOnly={true}
                          checked={
                            product.OrderType_c.toLowerCase() ===
                            "store-eye-check"
                              ? true
                              : false
                          }
                        />
                        <label
                          className="form__label-radio"
                          htmlFor="small1"
                        >
                          <span className="form__radio-button"></span> Yes
                        </label>
                      </div>
                    </div>
                  </div>
                </li>
              ) : (
                ""
              )}
            </ul>
          </div>
        </div>
      </div> */}
      
      {console.log(product.Disposition1_c)}
      {/* {console.log(
        product.Disposition1_c !== undefined || product.Disposition1_c !== null
          ? product.Disposition1_c.toLowerCase() == "store visit confirmed"
            ? true
            : false
          : false
      )} */}
      {(
        product.Disposition1_c !== undefined
          ? product.Disposition1_c !== null
            ? product.Disposition1_c.toLowerCase() == "store visit confirmed"
              ? true
              : false
            : false
          : false
      ) ? (
        <div className="">
          {/* <h2></h2> */}
        </div>
      ) : (
        ""
      )}
      {props.showcall ? (
        completed ? (
          <>
          <div className="picker1">
          <DatePicker
    dateFormat="DD MMM YYYY"
          // showToday
          // disabledDays={{ before: new Date() }}
          placeholder="Select Date"
          // isValidDate={disablePastDt}
          // minDate={moment().toDate()}
          // disablePast
          // disabled={schedule == "Date" ? true : false}
          onChange={onChanges("DatePicker")}
        />
          <button
            className={buttondisable ? `btn6` : `btn5`}
            onClick={() => {
              saveCompleted();
            }}
            type="submit"
            disabled={buttondisable ? true : false}
          >
            Submit
          </button>
          </div>
          </>
        ) : (
          ""
        )
      ) : (
        ""
      )}
      <Modal
        id=""
        show={storevisitedmodal}
        onHide={() => {
          handleClose();
        }}
      >
        <Modal.Header>
          <Modal.Title className="sv">Store Visit</Modal.Title>
        </Modal.Header>
        <Modal.Body id="completedbody">
          <h1 className="completedmodal">Completed</h1>
          {/* <br /> */}
          {/* <p>lead is completed </p> */}
          <button
            className="btnn_5"
            onClick={() => {
              setStoreVisitedModal(false);
              window.location.reload();
            }}
            type="submit"
          >
            Close
          </button>
        </Modal.Body>
      </Modal>
      <br />
      <br />
    </>
  );
}

export default FollowUps;
