import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Header from "../header/Header";
import baseurl from "../../app.json";
import axios from "axios";

function Notification() {
  const [freetrails, setFreetrail] = useState([]);
  const [sales, setSales] = useState([]);
  let history = useHistory();

  useEffect(() => {
    try {
      let retailer = JSON.parse(localStorage.getItem("Retalierinfo"));

      axios({
        method: "get",
        url: `https://epex.fa.em4.oraclecloud.com/crmRestApi/resources/latest/Order_c/?q=AccountID_c=${retailer.items[0].PartyNumber}`,
        headers: {
          Authorization: baseurl.Authorization,
        },
      })
        .then((res) => {
          let freetrail = [];
          let sale = [];

          // console.log(res.data)
          res.data.items.map((item, index) => {
            // console.log(item.OrderType_c +"sales")
            if (item.LeadType_c.toLowerCase() == "sales") {
              sale.push(item);
            } else if (item.LeadType_c.toLowerCase() == "trial") {
              freetrail.push(item);
            } else {
            }
          });

          setSales(sale);
          setFreetrail(freetrail);
        })
        .catch(function (error) {
          if (error.message) {
            alert(error.message);
          }
        });
    } catch (error) {
      alert(error);
    }
  }, []);

  // console.log(freetrails)
  // console.log(sales)

  return (
    <main>
      <Header headerheading={"Notification"} history={history} />

      <div className="maincontainer">
        <div className="container">
          <div className="row">
            <div className="notificationWrap">
              <div className="accordion" id="accordionExample">
                <div className="card">
                  <div className="card-head" id="headingOne">
                    <h2
                      className="mb-0 collapsed"
                      data-toggle="collapse"
                      data-target="#collapseOne"
                      aria-expanded="false"
                      aria-controls="collapseOne"
                    >
                      Free Trial Leads
                    </h2>
                  </div>

                  <div
                    id="collapseOne"
                    className="collapse"
                    aria-labelledby="headingOne"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body">
                      {freetrails.map((item, index) => {
                        return (
                          <div className="followDetailBox">
                            <a href="#" className="item">
                              <div className="detail">
                                <h2>{item.ContactName_c}</h2>
                                <p>
                                  Last Action Taken | {item.Disposition1_c}{" "}
                                  <br />
                                  {item.ProductNames_c} | Free Trial Lead
                                </p>
                              </div>

                              <div className="right">
                                <div className="dayspending red">
                                  {" "}
                                  14 <span>Days Pending</span>
                                </div>
                              </div>
                            </a>
                          </div>
                        );
                      })}

                      <div className="viewall_2">
                        {" "}
                        <a href="" className="btn1">
                          View All
                        </a>{" "}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-head" id="headingTwo">
                    <h2
                      className="mb-0 collapsed"
                      data-toggle="collapse"
                      data-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      Sale Lead
                    </h2>
                  </div>
                  <div
                    id="collapseTwo"
                    className="collapse"
                    aria-labelledby="headingTwo"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body">
                      {sales.map((item, index) => {
                        return (
                          <div className="followDetailBox">
                            <a href="#" className="item">
                              <div className="detail">
                                <h2>{item.ContactName_c}</h2>
                                <p>
                                  Last Action Taken | {item.Disposition1_c}{" "}
                                  <br />
                                  {item.ProductName_c} | Sale Lead
                                </p>
                              </div>

                              <div className="right">
                                <div className="dayspending red">
                                  {" "}
                                  14 <span>Days Pending</span>
                                </div>
                              </div>
                            </a>
                          </div>
                        );
                      })}

                      <div className="viewall_2">
                        {" "}
                        <a href="" className="btn1">
                          View All
                        </a>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Notification;
