import React from "react";
import { useHistory } from "react-router-dom";

function DashbordUserInfo(props) {
  let history = useHistory();
  console.log(props);

  function pendingSection(name) {
    console.log(name);
    console.log(name == "followups");
    console.log(name == "newleads");
    if (name == "followups") {
      console.log(name);
      props.setTab(0);
    } else if (name == "newleads") {
      console.log(name);
      props.setTab(1);
    } else {
      console.log(name);
      props.setTab(2);
    }
    history.push("/pendingleads");
  }

  return (
    <div className="tab-content">
      <div
        className="tab-pane fade show active"
        id="home"
        role="tabpanel"
        aria-labelledby="home-tab"
      >
        <div className="totalpendingactions">
          <div className="totalpending">
          <h2>Total Pending Actions</h2>
          {/* <Link to="/pendingleads/freetrail"> */}
          <a
            className="viewall"
            onClick={() => {
              props.tab === 0
                ? props.LeadType("freetrail")
                : props.LeadType("sales");
            }}
          >
            View All{" "}
          </a>
          </div>
          {/* </Link> */}
          <div className="dashboardcardnew">
            <div
              className="RewardLeft red"
              type="button"
              onClick={() => {
                pendingSection("followups");
              }}
            >
              <div className="redbox">
                <img
                  className="logo-loginpage"
                  src="/assets/images/follow-icon.png"
                  alt=""
                />
              </div>
              {/* {console.log(traillead)} */}
              {props.tab === 0
                ? props.traillead.followup !== []
                  ? props.traillead.followup.length
                  : 0
                : props.saleslead.followup !== []
                ? props.saleslead.followup.length
                : 0}
              {/* <h3>{trail[0].newleads !== undefined?trail[0].newleads:0}</h3> */}
              <p>Follow Ups</p>
            </div>
            <div
              className="RewardLeft green"
              type="button"
              onClick={() => {
                pendingSection("newleads");
              }}
            >
              <div className="greenbox">
                <img
                  className="logo-loginpage"
                  src="/assets/images/new-leads-icon.png"
                  alt=""
                />
              </div>
              {props.tab === 1
                ? props.saleslead.newleads !== []
                  ? props.saleslead.newleads.length
                  : 0
                : props.traillead.newleads !== []
                ? props.traillead.newleads.length
                : 0}
              {/* <h3>{trail == []?0:trail[0].followup} </h3> */}
              <p>New Leads</p>
            </div>
            <div
              className="RewardLeft blue-color"
              type="button"
              onClick={() => {
                pendingSection("storevisits");
              }}
            >
              <div className="bluebox">
                <img
                  className="logo-loginpage"
                  src="/assets/images/store-icon.png"
                  alt=""
                />
              </div>
              {props.tab === 1
                ? props.saleslead.newleads !== []
                  ? props.saleslead.newleads.length
                  : 0
                : props.traillead.storevisit !== []
                ? props.traillead.storevisit.length
                : 0}
              {/* <h3>{trail == []?0:trail[0].followup} </h3> */}
              <p>Store Visit</p>
            </div>
            
          </div>
          <div className="dashboardunderline"></div>
        </div>
      </div>
    </div>
  );
}

export default DashbordUserInfo;
