import React, { useState, useEffect } from "react";
import axios from "axios";
import baseurl from "../../app.json";
import { useHistory } from "react-router-dom";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Navbar from "../sidenav/Navbar";
import "./dashboard.css";
import TabsMenu from "../tabs-menu/TabsMenu";
import DashbordUserInfo from "./DashbordUserInfo";

function Dashboard(props) {
  let history = useHistory();
  // OwnerName
  const [retailerinfo, setRetailerInfo] = useState(
    localStorage.getItem("Retalierinfo")
  );
  const [nav, setNav] = useState(false);
  const [allcampaign, setAllCampaign] = useState([]);
  const [tab, setTab] = useState(0);
  // const [search, setSearch] = useState("");
  const [searchdata, setSearchData] = useState([]);

  let retailer = JSON.parse(localStorage.getItem("Retalierinfo"));
  const [traillead, setTrailLead] = useState({
    followup: [],
    newleads: [],
    storevisit: [],
  });
  const [saleslead, setSalesleads] = useState({
    followup: [],
    newleads: [],
    storevisit: [],
  });
  if (!retailer) {
    history.push("/login");
  }
  // let followup = 0
  // let newleads = 0

  useEffect(() => {
    if (tab == 0) {
      props.setTab("trial");
    } else if (tab == 1) {
      props.setTab("sales");
    } else {
    }
    setRetailerInfo(retailer);

    try {
      //   axios({
      //     method: "get",
      //     url: `https://epex.fa.em4.oraclecloud.com/crmRestApi/resources/latest/Order_c/?q=AccountID_c=${retailer.items[0].PartyNumber}`,
      //     headers: {
      //       Authorization: baseurl.Authorization,
      //     },
      //   })
      // .then((res) => {
      axios({
        method: "get",
        url: ` https://epex.fa.em4.oraclecloud.com/crmRestApi/resources/latest/Order_c/?q=AccountID_c=${retailer.items[0].PartyNumber};LeadType_c=sales;&limit=100000`,
        headers: {
          Authorization: baseurl.Authorization,
        },
      })
        .then((res) => {
          let follow = [];
          let lead = [];

          res.data.items.map((item, index) => {
            if (
              item.RetailerLeadStatus_c.toLowerCase() === null ||
              item.RetailerLeadStatus_c.toLowerCase() === "new"
            ) {
              lead.push(item);
            } else if (
              item.RetailerLeadStatus_c.toLowerCase() === "completed" ||
              item.RetailerLeadStatus_c.toLowerCase() === "canceled"
            ) {
            } else {
              follow.push(item);
            }
          });
          //  console.log(res.data)
          setSalesleads({ followup: follow, newleads: lead });
        })
        .catch(function (error) {
          if (error.message) {
            alert(error.message);
          }
        });

      axios({
        method: "get",
        url: ` https://epex.fa.em4.oraclecloud.com/crmRestApi/resources/latest/Order_c/?q=AccountID_c=${retailer.items[0].PartyNumber};LeadType_c=trial;&limit=100000`,
        headers: {
          Authorization: baseurl.Authorization,
        },
      })
        .then(async (res) => {
          var follow = [];
          var lead = [];
          var storevisit = [];
          var btodnew = [];
          var btodfollow = [];
          var btodstorevistit = [];
          var iconnectnew = []
          var iconnectfollow = [];
          var iconnectstorevistit = [];

          var iconnectnewoxy = []
          var iconnectfollowoxy = [];
          var iconnectstorevistitoxy = [];


          var SoflensMultifocalnew = []
          var SoflensMultifocalfollow = [];
          var SoflensMultifocalvisit = [];

          var PureVisionMultifocalnew = []
          var PureVisionMultifocalfollow = [];
          var PureVisionMultifocalvisit = [];


          var SoflensDailyDisposableToricnew = []
          var SoflensDailyDisposableToricfollow = [];
          var SoflensDailyDisposableToricvisit = [];

          var BiotrueONEdayToricnew = []
          var BiotrueONEdayToricfollow = [];
          var BiotrueONEdayToricvisit = [];

          var SoflensToricnew = []
          var SoflensToricfollow = [];
          var SoflensToricvisit = [];

          var UltraToricnew = []
          var UltraToricfollow = [];
          var UltraToricvisit = [];

          var BioTrueONEdaynew = []
          var BioTrueONEdayfollow = [];
          var BioTrueONEdayvisit = [];

          var iconnectDailyDisposablenew = [];
          var iconnectDailyDisposablefollow = [];
          var iconnectDailyDisposablevisit = [];

          var SoflensDailyDisposablenew = []
          var SoflensDailyDisposablefollow = [];
          var SoflensDailyDisposablevisit = [];

          var PureVision2HDToricnew = []
          var PureVision2HDToricfollow = [];
          var PureVision2HDToricvisit = [];

          var BioTrueONEdayMultifocalnew = []
          var BioTrueONEdayMultifocalfollow = [];
          var BioTrueONEdayMultifocalvisit = [];

          var pvnew = [];
          var pvfollow = [];
          var pvstorevistit = [];
          var soflensnew = [];
          var soflensfollow = [];
          var soflensstorevistit = [];
          var ultranew = [];
          var ultrafollow = [];
          var ultrastorevistit = [];

          // res.data.items.for(async (item, index) => {
            console.log({items:res.data.items});

            let sortedres = res.data.items.sort(function(a,b){
              // Turn your strings into dates, and then subtract them
              // to get a value that is either negative, positive, or zero.
              return new Date(b.CreationDate) - new Date(a.CreationDate);
            }); 

          for (var indx in sortedres) {
            var item = sortedres[indx];
            // await axios({
            //   method: "get",
            //   url: `https://epex.fa.em4.oraclecloud.com/crmRestApi/resources/11.13.18.05/contacts/${item.ContactParty_c}?onlyData=true&fields=PartyId,PartyNumber,CreationDate,LastUpdateDate,PersonDEO_VisionCorrectionType_c,PersonDEO_MobileNumber_c`,
            //   headers: {
            //     Authorization: baseurl.Authorization,
            //   },
            // })
            //   .then((res) => {
            //     if (res.data.PersonDEO_MobileNumber_c) {



            if (
              item.RetailerLeadStatus_c.toLowerCase() === null ||
              item.RetailerLeadStatus_c.toLowerCase() === "new"
            ) {
              // console.log(item);
              lead.push(item);
              if(item.ProductNames_c == "iconnect®"){
                iconnectnew.push(item)
              }else if(item.ProductNames_c == "iconnect® OxyRich"){
                iconnectnewoxy.push(item)
              }else if(item.ProductNames_c == "BioTrue® ONEday Multifocal"){
                BioTrueONEdayMultifocalnew.push(item)
              }else if(item.ProductNames_c == "PureVision® 2 Multifocal"){
              PureVisionMultifocalnew.push(item)
            }else if(item.ProductNames_c == "Soflens® Daily Disposable Toric"){
              SoflensDailyDisposableToricnew.push(item)
            }else if(item.ProductNames_c == "Soflens® Multifocal"){
              SoflensMultifocalnew.push(item)
            }else if(item.ProductNames_c == "PureVision® 2 HD"){
                pvnew.push(item)
              }else if(item.ProductNames_c == "PureVision® 2 HD Toric"){
                PureVision2HDToricnew.push(item)
              }else if(item.ProductNames_c == "Biotrue® ONEday Toric"){
                BiotrueONEdayToricnew.push(item)
              }else if(item.ProductNames_c == "Soflens® 59"){
                soflensnew.push(item)
              }else if(item.ProductNames_c == "Ultra Toric"){
                UltraToricnew.push(item)
              }else if(item.ProductNames_c == "BioTrue® ONEday"){
                BioTrueONEdaynew.push(item)
              }else if(item.ProductNames_c == "Soflens® Toric"){
                SoflensToricnew.push(item)
                }else if(item.ProductNames_c == "Soflens® Daily Disposable"){
              SoflensDailyDisposablenew.push(item)
              }else if(item.ProductNames_c == "iconnect® Daily Disposable"){
                iconnectDailyDisposablenew.push(item)
                }else if(item.ProductNames_c == "Ultra Spherical"){
                ultranew.push(item)
              }
            } else if (
              item.RetailerLeadStatus_c.toLowerCase() === "completed" ||
              item.RetailerLeadStatus_c.toLowerCase() === "canceled" ||
              item.Disposition1_c.toLowerCase() == "store visit confirmed"
            ) {
              
             
            } else {
              follow.push(item);
              
              if(item.ProductNames_c == "iconnect®"){
              iconnectfollow.push(item);
              }else if(item.ProductNames_c == "iconnect® OxyRich"){
                iconnectfollowoxy.push(item);
              }else if(item.ProductNames_c == "BioTrue® ONEday Multifocal"){
                BioTrueONEdayMultifocalfollow.push(item)
              }else if(item.ProductNames_c == "PureVision® 2 Multifocal"){
                PureVisionMultifocalfollow.push(item)
              }else if(item.ProductNames_c == "Soflens® Daily Disposable Toric"){
                SoflensDailyDisposableToricfollow.push(item)
              }else if(item.ProductNames_c == "Soflens® Multifocal"){
                SoflensMultifocalfollow.push(item)
              }else if(item.ProductNames_c == "PureVision® 2 HD"){
                pvfollow.push(item)
              }else if(item.ProductNames_c == "PureVision® 2 HD Toric"){
                PureVision2HDToricfollow.push(item)
              }else if(item.ProductNames_c == "Biotrue® ONEday Toric"){
                BiotrueONEdayToricfollow.push(item)
              }else if(item.ProductNames_c == "Soflens® Toric"){
                SoflensToricfollow.push(item)
                }else if(item.ProductNames_c == "Ultra Toric"){
                  UltraToricfollow.push(item)
                }else if(item.ProductNames_c == "Soflens® Daily Disposable"){
                  SoflensDailyDisposablefollow.push(item)
                  }else if(item.ProductNames_c == "iconnect® Daily Disposable"){
                    iconnectDailyDisposablefollow.push(item)
                    }else if(item.ProductNames_c == "Soflens® 59"){
                  soflensfollow.push(item);
              }else if(item.ProductNames_c == "BioTrue® ONEday"){
                BioTrueONEdayfollow.push(item)
              }else if(item.ProductNames_c == "Ultra Spherical"){
                    ultrafollow.push(item);
              }
            }
            // console.log(!item.Disposition1_c);
            // console.log(item.Disposition1_c);
            if (!item.Disposition1_c == false) {
              // console.log(item.Disposition1_c);
              if (
                item.Disposition1_c.toLowerCase() == "store visit confirmed"
              ) {
                // console.log(item.Disposition1_c);
                if(item.ProductNames_c == "iconnect®"){
                  if(item.Disposition1_c.toLowerCase() == "store visit confirmed"){
                    iconnectstorevistit.push(item)
                  }
                }else if(item.ProductNames_c == "iconnect® OxyRich"){
                  if(item.Disposition1_c.toLowerCase() == "store visit confirmed"){
                    iconnectstorevistitoxy.push(item)
                  }
                }else if(item.ProductNames_c == "Soflens® Multifocal"){
                  if(item.Disposition1_c.toLowerCase() == "store visit confirmed"){
                  SoflensMultifocalvisit.push(item)
                  }
                }else if(item.ProductNames_c == "BioTrue® ONEday Multifocal"){
                  if(item.Disposition1_c.toLowerCase() == "store visit confirmed"){
                  BioTrueONEdayMultifocalvisit.push(item)
                  }
                }else if(item.ProductNames_c == "PureVision® 2 Multifocal"){
                  if(item.Disposition1_c.toLowerCase() == "store visit confirmed"){
                  PureVisionMultifocalvisit.push(item)
                  }
                }else if(item.ProductNames_c == "PureVision® 2 HD Toric"){
                  if(item.Disposition1_c.toLowerCase() == "store visit confirmed"){
                  PureVision2HDToricvisit.push(item)
                  }
                }else if(item.ProductNames_c == "Soflens® Daily Disposable Toric"){
                  if(item.Disposition1_c.toLowerCase() == "store visit confirmed"){
                  SoflensDailyDisposableToricvisit.push(item)
                  }
                }else if(item.ProductNames_c == "Biotrue® ONEday Toric"){
                  if(item.Disposition1_c.toLowerCase() == "store visit confirmed"){
                  BiotrueONEdayToricvisit.push(item)
                  }
                }else if(item.ProductNames_c == "Soflens® Daily Disposable"){
                  if(item.Disposition1_c.toLowerCase() == "store visit confirmed"){
                  SoflensDailyDisposablevisit.push(item)
                  }
                  }else if(item.ProductNames_c == "PureVision® 2 HD"){
                  if(item.Disposition1_c.toLowerCase() == "store visit confirmed"){
                    pvstorevistit.push(item)
                  }
                }else if(item.ProductNames_c == "iconnect® Daily Disposable"){
                  if(item.Disposition1_c.toLowerCase() == "store visit confirmed"){
                  iconnectDailyDisposablevisit.push(item)
                  }
                  }else if(item.ProductNames_c == "Ultra Toric"){
                  if(item.Disposition1_c.toLowerCase() == "store visit confirmed"){
                  UltraToricvisit.push(item)
                  }
                }else if(item.ProductNames_c == "Soflens® Toric"){
                  if(item.Disposition1_c.toLowerCase() == "store visit confirmed"){
                  SoflensToricvisit.push(item)
                  }
                  }else if(item.ProductNames_c == "Soflens® 59"){
                  if(item.Disposition1_c.toLowerCase() == "store visit confirmed"){
                    soflensstorevistit.push(item)
                  }
                }else if(item.ProductNames_c == "BioTrue® ONEday"){
                  if(item.Disposition1_c.toLowerCase() == "store visit confirmed"){
                  BioTrueONEdayvisit.push(item)
                  }
                }else if(item.ProductNames_c == "Ultra Spherical"){
                  if(item.Disposition1_c.toLowerCase() == "store visit confirmed"){
                    ultrastorevistit.push(item)
                  }
                }
                storevisit.push(item);
              }
            }
            //   }
            // })
            // .catch(function (error) {
            //   if (error.message) {
            //     alert(error.message);
            //   }
            // });
          }
          setTrailLead({
            followup: follow,
            newleads: lead,
            storevisit: storevisit,
         
          "iconnect®":{
                        new:iconnectnew,
                        follow:iconnectfollow,
                        sv:iconnectstorevistit,
                      },
          "iconnect® OxyRich":{
                        new:iconnectnewoxy,
                        follow:iconnectfollowoxy,
                        sv:iconnectstorevistitoxy,
                      },
          "PureVision® 2 HD":{
                        new:pvnew,
                        follow:pvfollow,
                        sv:pvstorevistit,
                      },
          "Soflens® 59":{
                        new:soflensnew,
                        follow:soflensfollow,
                        sv:soflensstorevistit,
                      },
          "Ultra Spherical":{
                        new:ultranew,
                        follow:ultrafollow,
                        sv:ultrastorevistit,
                    },
          "BioTrue® ONEday Multifocal":{
                        new:BioTrueONEdayMultifocalnew,
                        follow:BioTrueONEdayMultifocalfollow,
                        sv:BioTrueONEdayMultifocalvisit,
                    },
          "Soflens® Multifocal":{
                        new:SoflensMultifocalnew,
                        follow:SoflensMultifocalfollow,
                        sv:SoflensMultifocalvisit,
                    },
          "PureVision® 2 Multifocal":{
                        new:PureVisionMultifocalnew,
                        follow:PureVisionMultifocalfollow,
                        sv:PureVisionMultifocalvisit,
                    },
                    "Soflens® Daily Disposable Toric":{
                      new:SoflensDailyDisposableToricnew,
                      follow:SoflensDailyDisposableToricfollow,
                      sv:SoflensDailyDisposableToricvisit,
                  },
                  "Biotrue® ONEday Toric":{
                    new:BioTrueONEdayMultifocalnew,
                    follow:BioTrueONEdayMultifocalfollow,
                    sv:BioTrueONEdayMultifocalvisit,
                },
                "Soflens® Toric":{
                  new:SoflensToricnew,
                  follow:SoflensToricfollow,
                  sv:SoflensToricvisit,
              },
              "PureVision® 2 HD Toric":{
                new:PureVision2HDToricnew,
                follow:PureVision2HDToricfollow,
                sv:PureVision2HDToricvisit,
            },
            "Ultra Toric":{
              new:UltraToricnew,
              follow:UltraToricfollow,
              sv:UltraToricvisit,
          },
          "Soflens® Daily Disposable":{
            new:SoflensDailyDisposablenew,
            follow:SoflensDailyDisposablefollow,
            sv:SoflensDailyDisposablevisit,
        },
        "BioTrue® ONEday":{
          new:BioTrueONEdaynew,
          follow:BioTrueONEdayfollow,
          sv:BioTrueONEdayvisit,
      },
      "iconnect® Daily Disposable":{
        new:iconnectDailyDisposablenew,
        follow:iconnectDailyDisposablefollow,
        sv:iconnectDailyDisposablevisit,
    },
          });

          console.log({
            followup: follow,
            newleads: lead,
            storevisit: storevisit,
            btodnew:btodnew,
btodfollow:btodfollow,
btodstorevistit:btodstorevistit,
          });
          // console.log(lead);
        })
        .catch(function (error) {
          if (error.message) {
            alert(error.message);
          }
        });
      // })
      // .catch(function (error) {
      //   if (error.message) {
      //     alert(error.message);
      //   }
      // });
      // axios({
      //   method: "get",
      //   url: ` https://epex.fa.em4.oraclecloud.com/crmRestApi/resources/latest/Campaigns_c/?q=CampaignStatus_c=Active&onlyData=true&fields=RecordNumber,CampaginName_c,CampaignStatus_c,EndDate_c,StartDate_c`,
      //   headers: {
      //     Authorization: baseurl.Authorization,
      //   },
      // })
      //   .then((res) => {
      //     setAllCampaign(res.data.items);
      //   })
      //   .catch(function (error) {
      //     if (error.message) {
      //       alert(error.message);
      //     }
      //   });
    } catch (error) {
      alert(error);
    }
  }, []);

  let LeadType = (data) => {
    props.setLeadType(data);
    localStorage.setItem("section-name", data);
    history.push("/pendingleads");
  };

  let SerachProduct = (searchs, tabs) => {
    // console.log(searchs);
    console.log("tabs");
    let searchdata = [];
    console.log({searchdata});
    // let data;
    // setSearch({ search: searchs, tab: "freetrail" });
    baseurl.product.forEach((item, index) => {
      for (let i = 0; i < searchs.length; i++) {
        console.log((item.product_name[i].toUpperCase()).includes(searchs[i].toUpperCase()))
        if ((item.product_name[i].toUpperCase()).includes(searchs[i].toUpperCase())) {
          searchdata.push(item)
        }
      }
    });
    searchdata = [...new Set(searchdata)];
    console.log({searchdata})
    setSearchData(searchdata);
    // searchdata.push(data);
  };






















  // if (allcampaign.length > 0) {
  //   console.log("get");
  //   console.log(allcampaign);
  // } else {
  //   console.log("else get");
  // console.log(traillead);
  // }

  return (
    <main>
      <div className="topBg"></div>
      {nav ? <Navbar setNav={setNav} /> : ""}

      <header>
        <div className="container">
          <div className="row">
            <div className="col nopad">
              <div className="leftheader">
                <a
                  onClick={() => {
                    setNav(true);
                  }}
                  className="menu"
                >
                  <img src="/assets/images/menu_icon.png" alt="" />{" "}
                </a>
              </div>
            </div>
            <div className="col nopad">
              <div className="rightheader">
                <div className="serachpanel">
                  {<img src="/assets/images/rightlogo.png" alt="" />}
                </div>
                <div className="notificationpanel">
                  {/* <Link key={"notific"} to="notification">
                    <img src="/assets/images/notification_icon.png" alt="" />
                  </Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div className="maincontainer">
        <div className="container">
          <div className="row">
            <div className="userContainer">
              <h2>
                Hi,
                {retailerinfo
                  ? retailerinfo.items
                    ? " " +
                      retailerinfo.items[0].PartyUniqueName.charAt(
                        0
                      ).toUpperCase() +
                      retailerinfo.items[0].PartyUniqueName.slice(1)
                    : ""
                  : ""}{" "}
              </h2>
              <p>
                {retailerinfo
                  ? retailerinfo.items
                    ? retailerinfo.items[0].OrganizationDEO_StoreName_c
                    : ""
                  : ""}
              </p>
            </div>

            <div className="tabContainer dashboardWrap" id="tile-1">
              {/* <!-- Nav tabs --> */}
              <TabsMenu
                tab={tab}
                setTab={setTab}
                firstmenu={"Free Trials"}
                secondmenu={"Sales"}
                // setSearch={setSearch}
              />
              {/* <!-- Tab panes --> */}
              {tab === 0 ? (
                <DashbordUserInfo
                  setTab={props.setTab}
                  traillead={traillead}
                  leadtypename={"Free Trial "}
                  LeadType={LeadType}
                  tab={tab}
                />
              ) : (
                ""
              )}
              {tab === 1 ? (
                <DashbordUserInfo
                  setTab={props.setTab}
                  saleslead={saleslead}
                  leadtypename={"Sales"}
                  LeadType={LeadType}
                  tab={tab}
                />
              ) : (
                ""
              )}
              <div className="filter">
                <h2>Filter By Products</h2>
                <div className="search-box search-header">
                  <i className="fa fa-search"></i>
                  <input
                    type="text"
                    className="border-0"
                    // value={search.search}
                    onChange={(e) => {
                      console.log(
                        e.target.value.toUpperCase(),
                        tab === 0 ? "freetrails" : "sales"
                        )
                      SerachProduct(
                        e.target.value.toUpperCase(),
                        tab === 0 ? "freetrails" : "sales"
                      );
                    }}
                    placeholder="Search Products"
                  />
                </div>
              </div>
              <div className="displayproduct">
                <h2>Displaying All Products</h2>
              </div>
              {console.log({searchdata})}
              {searchdata.length > 0
                ? searchdata.map((item, index) => {
                    return (
                      <div
                        className="card card-style1 allproduct"
                        onClick={() => {
                          localStorage.setItem("product", JSON.stringify(item));
                          props.setCampaign(item);
                          history.push(`/pageone`);
                        }}
                      >
                        <h2>{item.product_label}</h2>
                      </div>
                    );
                  })
                : baseurl.product.map((item, index) => {
                    return (
                      <div className="allproductmaindiv">
                      <div
                        key={index}
                        className="card card-style1 allproduct"
                        onClick={() => {
                          localStorage.setItem("product", JSON.stringify(item));
                          localStorage.removeItem("section-name");
                          props.setCampaign(item);
                          history.push(`/pageone`);
                        }}
                      >
                        <h2>{item.product_label}</h2>
                        <ul>
                          {console.log({traillead})}
                          {console.log({traillead:traillead[item.product_name]})}
                          <li>Follow Ups {traillead[item.product_name]&&(traillead[item.product_name]).follow?(traillead[item.product_name]).follow.length:0}</li>
                          <li>New Leads {traillead[item.product_name]&&(traillead[item.product_name]).new?(traillead[item.product_name]).new.length:0}</li>
                          <li>Store Visit {traillead[item.product_name]&&(traillead[item.product_name]).sv?(traillead[item.product_name]).sv.length:0}</li>
                        </ul>
                        <img
                  className="allproductmaindivrightarrow"
                  src="/assets/images/Icon ionic-ios-arrow-dropright-circle.png"
                  alt=""
                />
                      </div>
                      </div>
                    );
                  })}
              {/* // searchdata.length > 0 // ? searchdata // : */}
              {/* {} */}
            </div>
          </div>
        </div>
      </div>
      {/* <button onClick={()=>{localStorage.removeItem("Retalierinfo")
        history.push("/")
        }}>Logout</button> */}
    </main>
  );
}
export default Dashboard;
