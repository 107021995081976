import React, { useState } from "react";
import { useHistory } from "react-router-dom";
// import { Modal, Button } from "react-bootstrap";
import baseurl from "../../app.json";
import moment from "moment";
import Filter from "./Filter";

function ProductLeads(props) {
  let history = useHistory();
  // let a = "string";
  // let retailer = JSON.parse(localStorage.getItem("Retalierinfo"));
  let section = localStorage.getItem("section-name");
  // const [traillead, setTrailLead] = useState({
  //   followup: [],
  //   newleads: [],
  //   page: "",
  // });
  const [Render, setRender] = useState(false);
  let product;
  if (props.product) {
    product = props.product.items;
  }

  let datedifference = (lastdate) => {
    var today = new Date();
    const lastactionDate = moment(lastdate).utc().format("YYYY/MM/DD");
    const todayDate = moment(today).utc().format("YYYY/MM/DD");
    var now = moment(today).utc().format("YYYY/MM/DD HH:mm:ss");
    var then = moment(lastdate).utc().format("YYYY/MM/DD HH:mm:ss");
    var a = moment(todayDate.split("/"));
    var b = moment(lastactionDate.split("/"));
    console.log(a.diff(b, "days"));
    if (a.diff(b, "days") == 0) {
      var startTime = moment(
        moment(lastdate).format("HH:mm:ss a"),
        "HH:mm:ss a"
      );
      var endTime = moment(moment(today).format("HH:mm:ss a"), "HH:mm:ss a");
      console.log(startTime);
      console.log(endTime);
      var duration = moment.duration(endTime.diff(startTime));
      var hours = moment
        .utc(
          moment(now, "DD/MM/YYYY HH:mm:ss").diff(
            moment(then, "DD/MM/YYYY HH:mm:ss")
          )
        )
        .format("HH:mm:ss");
      console.log(hours.split(":")[0]);
      return [0, hours.split(":")[0]];
    } else {
      return [a.diff(b, "days"), 0];
    }
  };

  return (
    <>
      <div className="followuplist m-10">
        <h2 className="heading2">
          {props.page
            ? props.page == "freetrail"
              ? "Free Trial"
              : "Sales"
            : section == "freetrail"
              ? "Free Trial"
              : section == "sales"
                ? "Sales"
                : ""}{" "}
          Follow Ups{" "}
          <span className="followupnumber">
            {props.searchleads.page == "search"
              ? props.searchleads.data.length
              : product.length}
          </span>
        </h2>
        <Filter
          // setSearch={props.setSearch}
          tab={props.tab}
          setRender={setRender}
          traillead={props.traillead}
          saleslead={props.saleslead}
          setSearchLead={props.setSearchLead}
          setFilterResult={props.setFilterResult}
          product={product}
        />
        {props.searchleads.page == "search" ? (
          props.searchleads.data.length > 0 ? (
            props.searchleads.data.map((item, index) => {
              let a = datedifference(item.Disposition2_c);

              return (
                <div key={`first${index}`} className="followDetailBox">
                  <a
                    className="item"
                    onClick={() => {
                      localStorage.setItem("productinfo", JSON.stringify(item));
                      let product = baseurl.product.filter((items, index) => {
                        if (items.product_name == item.ProductNames_c) {
                          return true;
                        }
                      });
                      localStorage.setItem(
                        "product",
                        JSON.stringify(product[0])
                      );
                      // localStorage.setItem(
                      //   "product",
                      //   JSON.stringify({
                      //     product_name: item.ProductNames_c,
                      //     RecordNumber: item.RecordNumber,
                      //     lead_type: item.LeadType_c,
                      //     // product_details: "",
                      //     // date: item.CreationDate
                      //   })
                      // );
                      history.push(`/productdetails`);
                    }}
                  >
                    <div className="detail">
                      <h2 className="margin-botton-leads">
                        {item.ContactName_c}
                      </h2>
                      <p>
                        {/* <span className="margin-botton-leads"> */}
                        Last Action Taken | {item.Disposition1_c}
                        {/* </span>{" "} */}
                        <br />
                        {baseurl.product.map((items, index) => {
                          if (items.product_name == item.ProductNames_c) {
                            return items.product_label;
                          }
                        })}{" "}
                        |{" "}
                        {item.LeadType_c.toLowerCase() == "trial"
                          ? "Free Trial Lead"
                          : "Sales Lead"}
                      </p>
                    </div>

                    <div className="right">
                      <div
                        className={`dayspending ${0 <= a[0] && a[0] < 1 ? "greendark" : ""
                          }${1 <= a[0] && a[0] < 3 ? "orange-color" : ""}${3 <= a[0] ? "red" : ""
                          }`}
                      >
                        {" "}
                        {a[0] !== 0 && a[0] !== 1 ? (
                          <>
                            {isNaN(a[0]) ? '-' : a[0]} <span>Days Pending</span>
                          </>
                        ) : (
                          <>
                            {Math.abs(a[1])} <span>Hours Pending</span>
                          </>
                        )}
                      </div>
                    </div>
                  </a>
                </div>
              );
            })
          ) : (
            <p className="blank-error">No leads available in this category.</p>
          )
        ) : product.length <= 0 ? (
          <p className="blank-error">No leads available in this category.</p>
        ) : (
          product.map((item, index) => {
            let a = datedifference(item.Disposition2_c);

            return (
              <div key={`second${index}`} className="followDetailBox">
                <a
                  className="item"
                  onClick={() => {
                    localStorage.setItem("productinfo", JSON.stringify(item));
                    let product = baseurl.product.filter((items, index) => {
                      if (items.product_name == item.ProductNames_c) {
                        return true;
                      }
                    });
                    localStorage.setItem("product", JSON.stringify(product[0]));
                    // localStorage.setItem(
                    //   "product",
                    //   JSON.stringify({
                    //     product_name: item.ProductNames_c,
                    //     RecordNumber: item.RecordNumber,
                    //     lead_type: item.LeadType_c,
                    //     // product_details: "",
                    //     // date: item.CreationDate
                    //   })
                    // );
                    history.push(`/productdetails`);
                  }}
                >
                  <div className="detail">
                    <h2 className="margin-botton-leads">
                      {item.ContactName_c}
                    </h2>
                    <p>
                      {/* <span className="margin-botton-leads"> */}
                      Last Action Taken | {item.Disposition1_c}
                      {/* </span>{" "} */}
                      <br />
                      {baseurl.product.map((items, index) => {
                        if (items.product_name == item.ProductNames_c) {
                          return items.product_label;
                        }
                      })}{" "}
                      |{" "}
                      {item.LeadType_c.toLowerCase() == "trial"
                        ? "Free Trial Lead"
                        : "Sales Lead"}
                    </p>
                  </div>

                  <div className="right">
                    <div
                      className={`dayspending ${0 <= a[0] && a[0] < 1 ? "greendark" : ""
                        }${1 <= a[0] && a[0] < 3 ? "orange-color" : ""}${3 <= a[0] ? "red" : ""
                        }`}
                    >
                      {" "}
                      {a[0] !== 0 && a[0] !== 1 ? (
                        <>
                          {isNaN(a[0]) ? '-' : a[0]} <span>Days Pending</span>
                        </>
                      ) : (
                        <>
                          {Math.abs(a[1])} <span>Hours Pending</span>
                        </>
                      )}
                    </div>
                  </div>
                </a>
              </div>
            );
          })
        )}

        {/* props.searchleads.page == "search" ? (
          props.searchleads.data.length <= 0 ? */}
        {/* {product ? (
          product.length <= 0 ? (
            <p className="blank-error">No leads available in this category.</p>
          ) : (
            ""
          )
        ) : (
          ""
        )} */}
      </div>
    </>
  );
}

export default ProductLeads;
